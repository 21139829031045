/* eslint-disable @typescript-eslint/ban-ts-comment */

import { lazy, useEffect, useRef, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import isEqual from 'lodash/isEqual';

import { selectSymptomChecker } from 'store';
import { Item } from 'store/symptomChecker/symptomChecker.types';
import {
  setEvidence,
  setQuestions,
  setRegions,
  setSuggestedEvidence
} from 'store/symptomChecker/symptomCheckerSlice';

import ActionButtons from 'features/symptomChecker/ActionButtons';
import FadeSlideWrapper from 'shared/animationWrappers/FadeSlideWrapper';

import { REGIONS_LIST_OPTIONS } from 'constants/infermedica';
import { useAppDispatch, useAppSelector } from 'hooks';
import { lazyRetry } from 'utils/helpers';

import { StepProps } from '../steps.types';

import { RegionOption } from './regions.types';

const MapSvg = lazy(() => lazyRetry(() => import('assets/images/symptomChercker/map.svg?react')));

const Regions: React.FC<StepProps> = ({ moveToStep }) => {
  const dispatch = useAppDispatch();
  const ref = useRef<SVGSVGElement | null>(null);
  const [selectedRegions, setSelectedRegions] = useState<RegionOption[]>([]);
  const {
    regions,
    userInfo: { patientSelect, gender }
  } = useAppSelector(selectSymptomChecker);

  useEffect(() => {
    ref.current?.addEventListener('click', (el) => {
      // @ts-ignore
      const parentElement = el.target.parentElement;
      if (parentElement.id) {
        parentElement.classList.toggle('sc-regions__group--checked');
        // setSelectedElements((prev) =>
        //   prev.includes(el.target.id) ? prev.filter((i) => i !== el.target.id) : [...prev, el.target.id]
        // );
      }
    });
  }, []);

  const onInit = () => {
    setSelectedRegions(
      regions?.map((r) => ({
        label: REGIONS_LIST_OPTIONS.find((o) => o.value === r.id)?.label || '',
        value: r.id
      })) || []
    );
  };

  const handleNext = () => {
    const newRegions: Item[] = selectedRegions.map(({ value }) => ({
      id: value,
      value: 'present'
    }));
    if (!isEqual(regions, newRegions)) {
      dispatch(setSuggestedEvidence([]));
      dispatch(setEvidence([]));
      dispatch(setQuestions([]));
    }
    dispatch(setRegions(newRegions));
    moveToStep('next');
  };

  const handleChange = (e: unknown) => {
    setSelectedRegions(e as RegionOption[]);
  };

  const livePronoun =
    patientSelect === 'myself' ? 'you live' : gender === 'female' ? 'she lives' : 'he lives';
  const pastPronoun =
    patientSelect === 'myself' ? "you've" : gender === 'female' ? "she's" : "he's";

  useEffect(onInit, []);

  return (
    <>
      <FadeSlideWrapper>
        <div className="symptom-content-wrapper">
          <h2 className="mb-2 text-mLg font-bold text-primary md:text-xl">Select regions</h2>
          <p className="mb-8 text-mSm text-gray md:mb-6 md:text-base">
            {`Please select the region ${livePronoun} in and places ${pastPronoun} traveled to in the last 12
            months.`}
          </p>
          <MapSvg className="hidden max-h-[40vh] w-full" ref={ref} />
          <Common.MultiSelect
            dataTestId="region_dropdown"
            options={REGIONS_LIST_OPTIONS}
            placeholder="Select regions"
            value={selectedRegions}
            onChange={handleChange}
          />
        </div>
      </FadeSlideWrapper>
      <ActionButtons onClickBack={() => moveToStep('prev')} onClickNext={handleNext} />
    </>
  );
};

export default Regions;
