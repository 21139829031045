import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { selectOrchestrate } from 'store';

import { ReducedSignupStepProps, WmFlowType } from 'containers/SignUp/Content/content.types';
import RadioGroup from 'shared/RadioGroup';

import { YES_NO_OPTIONS } from 'constants/formFields';
import { useAppSelector, useQuery } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { PathName } from 'utils/enums';

import BackToMainWebsiteButton from '../parts/BackToMainWebsiteButton';
import Heading from '../parts/Heading';

const GallBladders: React.FC<ReducedSignupStepProps & { flow?: WmFlowType }> = ({
  moveToStep,
  flow = 'onboarding'
}) => {
  const buildSearch = (originalSearch: string) =>
    `${flow === 'authorized' ? 's=wm-gallbladder-question&' : ''}${originalSearch}`;
  const navigate = useNavigate();
  const query = useQuery();
  const [activeItem, setActiveItem] = useState<boolean>();
  const { send, isLoading } = useSubmitOrchestrateForm();
  const {
    user: { email }
  } = useAppSelector(selectOrchestrate);

  const handleSelect = (v: boolean, question: 'primary' | 'secondary') => {
    if (question === 'secondary') {
      if (v === true) {
        navigate({
          search: buildSearch(`hideProgress=true&subStep=fail-gallbladder-question&answer=${v}`)
        });
      } else {
        setActiveItem(false);
        send('mif_qa', [{ answer: v, question: 'is_gallbladder_removed' }], () => {
          setActiveItem(undefined);
          moveToStep('next', { searchParams: '?shouldEmulateBack=true' });
        });
      }
    } else {
      setActiveItem(Boolean(v));
      send('mif_qa', [{ answer: v, question: 'gallbladder_issues' }], () => {
        if (v === true) {
          setActiveItem(undefined);
          navigate({
            search: buildSearch(
              'subStep=have-you-had-your-gallbladder-removed&shouldEmulateBack=true'
            )
          });
        } else {
          moveToStep('next');
        }
      });
    }
  };

  const handleProspect = () => {
    if (flow === 'authorized') {
      return navigate(PathName.Dashboard);
    }
    const answer = query.get('answer');
    if (answer && !!email) {
      send(
        'mif_qa',
        [{ answer, question: 'is_gallbladder_removed' }],
        () => {
          moveToStep(-1);
        },
        'PROSPECT'
      );
    } else {
      moveToStep(-1);
    }
  };

  const isErrorState = query.get('subStep') === 'fail-gallbladder-question';
  const isSecondaryStep = query.get('subStep') === 'have-you-had-your-gallbladder-removed';

  return (
    <div className="flex flex-col gap-6">
      {isErrorState ? (
        <>
          <h2 className="wm-signup-title text-center" data-testid="header">
            We regret to inform you that you are not suitable for GLP-1 medication.
          </h2>
          <Common.Alert type="warning" colorableBackground>
            Given that your gallbladder has been removed, you are not suitable for treatment with
            GLP-1 receptor agonists. Your safety is our number one priority and these medications
            can sometimes cause or exacerbate gastrointestinal symptoms, which might be more
            pronounced or difficult to manage without a gallbladder.
          </Common.Alert>
          <BackToMainWebsiteButton loading={isLoading} onClick={handleProspect} />
        </>
      ) : isSecondaryStep ? (
        <>
          <Heading category="Plan" title="Have you had your gallbladder removed?" />
          <RadioGroup
            isLoading={isLoading}
            items={YES_NO_OPTIONS}
            selectedItem={activeItem}
            onSelect={(v) => handleSelect(v, 'secondary')}
          />
        </>
      ) : (
        <>
          <Heading category="Plan" title="Do you have any gallbladder issues?" />
          <RadioGroup
            isLoading={isLoading}
            items={YES_NO_OPTIONS}
            selectedItem={activeItem}
            onSelect={(v) => handleSelect(v, 'primary')}
          />
        </>
      )}
    </div>
  );
};

export default GallBladders;
