import { NavLink } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';
import HTMLReactParser from 'html-react-parser';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import { parseMessageToChannel } from 'widgets/Chat/Message/DefaultMessage/defaultMessage.settings';

import { ChannelProps as FrontDeskChannelProps } from 'contexts/frontDeskContext/frontDeskContext.types';
import { ChannelProps } from 'contexts/messagesContext/messagesContext.types';
import { useQuery } from 'hooks';
import { DateFormat, MessageTab, PathName } from 'utils/enums';

const ChannelCard: React.FC<{ channel: ChannelProps | FrontDeskChannelProps }> = ({ channel }) => {
  const chatId = useQuery().get('chatId') ?? '';
  const hasUnreadMessages = channel.unreadMessageCount > 0;
  const preview =
    channel.latestMessage && channel.latestMessage.length > 120
      ? channel.latestMessage.slice(0, 120) + '...'
      : channel.latestMessage;

  const isFrontDeskChannel = 'frontDeskRequestType' in channel;
  return (
    <NavLink
      data-testid="channel"
      to={`${PathName.Messages}?tab=${isFrontDeskChannel ? MessageTab.FRONT_DESK : MessageTab.CARE_TEAM}&chatId=${channel.channelId}`}
    >
      <FadeWrapper
        className={classNames('relative pl-10 pr-4', {
          'bg-gray-100': channel.channelId === chatId
        })}
      >
        {hasUnreadMessages && (
          <div
            className="absolute inset-y-0 left-4 m-auto flex size-2 items-center rounded-full bg-red"
            data-testid="unread-indicator"
          />
        )}
        <div
          className="relative z-[1] max-h-[80px] overflow-y-hidden border-b border-gray-200 py-4"
          data-testid="thread"
        >
          <div className="flex w-full items-center gap-2 truncate">
            <h4 className="flex-1 truncate font-bold text-gray-700">
              {isFrontDeskChannel ? channel.frontDeskRequestType : channel.channelTitle}
            </h4>
            <span
              className="text-mSm font-medium text-gray md:text-sm"
              data-testid="latest_message_date"
            >
              {dayjs(channel.latestMessageDate).format(DateFormat.h_mm_a)}
            </span>
            <Common.Icon className="text-gray md:hidden" name="arrow-right-small" />
          </div>
          {channel.latestMessage && (
            <div className="mt-1 truncate text-gray">
              {HTMLReactParser(parseMessageToChannel(preview))}
            </div>
          )}
        </div>
      </FadeWrapper>
    </NavLink>
  );
};

export default ChannelCard;
