import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  GetUserGeolocationReqProps,
  GetUserGeolocationResProps,
  ReverseGeocodingReqProps,
  ReverseGeocodingResProps
} from './external.types';

export const externalApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://'
  }),
  endpoints: (build) => ({
    getUserGeolocation: build.query<GetUserGeolocationResProps, GetUserGeolocationReqProps>({
      query: () => 'geolocation-db.com/json/'
    }),
    reverseGeocoding: build.query<ReverseGeocodingResProps, ReverseGeocodingReqProps>({
      query: (params) => ({
        params,
        url: `maps.googleapis.com/maps/api/geocode/json`
      })
    })
  }),
  reducerPath: 'externalApi',
  tagTypes: ['External']
});

export const { useLazyGetUserGeolocationQuery, useLazyReverseGeocodingQuery } = externalApi;
