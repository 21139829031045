import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { useGetAppointmentsQuery } from 'services/appointments/appointments';
import { useGetPrescriptionsQuery } from 'services/patientChart/patientChart';

import { selectAppointments, selectUser } from 'store';
import { AppointmentItem } from 'store/appointments/appointments.types';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import FirstAppointment from 'widgets/completeAccount/Intro/FirstAppointment';

import { useAppSelector } from 'hooks';
import useFreemium from 'hooks/useFreemium';
import useGLPPrescription from 'hooks/useGLPPrescription';
import useWidth from 'hooks/useWidth';
import { APPOINTMENTS_TABS, COMPLETE_ACCOUNT_STEPS } from 'utils/constants';
import { DateFormat, PathName, PlanCodes } from 'utils/enums';
import {
  checkCompletedOnboardingAppt,
  checkWmNotCompletedOnboarding,
  getUpcomingAppointments,
  isPastAppointment
} from 'utils/helpers';

const Appointments = () => {
  useTitle('LifeMD - Appointments');
  const { isMobile } = useWidth();
  const { appointmentsList } = useAppSelector(selectAppointments);
  const { activePlanCode, isAsyncSignup, isFirstAppointmentCompleted } = useAppSelector(selectUser);
  const navigate = useNavigate();
  const isFreemium = useFreemium();
  const [tab, setTab] = useState(APPOINTMENTS_TABS[0].label);
  const GLP_PRESCRIPTION = useGLPPrescription();
  const isAsyncPlan = isAsyncSignup && activePlanCode === PlanCodes.WeightManagementMembership;

  const { data, isLoading } = useGetAppointmentsQuery({ limit: 50 });

  useGetPrescriptionsQuery(undefined, { skip: !isAsyncPlan });

  const upcomingAppointmentsList = getUpcomingAppointments(appointmentsList);
  const pastAppointmentsList = appointmentsList.filter((val) => isPastAppointment(val));

  const handleClickContactSupport = () => navigate(PathName.Messages);
  const handleClickNewAppointment = () =>
    navigate(
      isFreemium
        ? `${PathName.CompleteAccount}/${COMPLETE_ACCOUNT_STEPS[1]}`
        : PathName.CreateAppointment
    );

  const getAppointment = (item: AppointmentItem) => (
    <Common.Appointment
      isQueueAppointment={item.category === 'queue'}
      key={item._id}
      startTime={item.appointmentTime?.startTime}
      text={`${item.appointmentType.displayName}${item.staffName ? ` • ${item.staffName}` : ''}`}
      type={
        item.missedAppointment
          ? 'missed'
          : item.isStartsSoon && !item.pastAppointment
            ? 'start'
            : 'default'
      }
      onClick={() => {
        navigate(
          item.isStartsSoon && !item.pastAppointment
            ? `${PathName.Call}?callId=${item._id}`
            : `${PathName.Appointment}/${item._id}`
        );
      }}
    />
  );

  // useEffect(handleUpdatedData, [data]);

  const completedOnboarding = checkCompletedOnboardingAppt(appointmentsList);
  const wmNotCompletedOnboarding = checkWmNotCompletedOnboarding(
    activePlanCode,
    completedOnboarding
  );

  const showCreateNewApptBtn = wmNotCompletedOnboarding
    ? upcomingAppointmentsList.length === 0
    : true;

  return (
    <FadeWrapper className="flex flex-1 flex-col rounded-2xl bg-transparent md:flex-none md:bg-white md:shadow">
      <Loader isVisible={isLoading} />
      {!(isFreemium && isMobile) && (
        <div className="border-gray-200 md:border-b md:px-8 md:pt-4">
          <Common.Tabs
            data={APPOINTMENTS_TABS}
            type={isMobile ? 'bar' : 'line'}
            onChange={(el) => setTab(el.label)}
          />
        </div>
      )}
      <div
        className={classNames(
          'flex flex-1 flex-col gap-3 py-4 md:max-w-[664px] md:flex-none md:gap-4 md:p-8',
          {
            'mx-auto w-full':
              tab === 'Upcoming' &&
              (isFreemium ||
                (isAsyncPlan && !isFirstAppointmentCompleted) ||
                wmNotCompletedOnboarding)
          }
        )}
        data-testid="appointments_area"
      >
        {tab === 'Upcoming' && data && (
          <FadeWrapper
            className={classNames(
              'flex flex-col gap-4 py-4',
              isFreemium ? 'md:gap-8' : isAsyncPlan ? 'md:gap-8' : ''
            )}
          >
            {(wmNotCompletedOnboarding || (isAsyncPlan && !isFirstAppointmentCompleted)) && (
              <>
                <Common.Illustration className="mx-auto" name="calendar" />
                <h2 className="text-center text-mXl font-bold text-primary-700 md:text-2xl">
                  {isAsyncPlan ? 'We’ve got you covered!' : 'Welcome to Weight Management!'}
                </h2>
                {upcomingAppointmentsList.length > 0 && (
                  <p className="text-center text-primary-700">
                    Thanks for being a Weight Management member! You have your first appointment on{' '}
                    <b>
                      {dayjs(upcomingAppointmentsList[0]?.appointmentTime?.startTime).format(
                        DateFormat.MMM_D_h_mma_z
                      )}
                    </b>
                  </p>
                )}
              </>
            )}
            {isAsyncPlan && !upcomingAppointmentsList.length ? (
              <>
                <NavLink
                  className="flex items-center justify-between gap-5 rounded-2xl border border-gray-200 bg-white p-4 shadow"
                  to={PathName.WeightManagement}
                >
                  <div>
                    <h3 className="font-bold">GLP-1 prescription</h3>
                    {GLP_PRESCRIPTION && (
                      <p className="mt-0.5 text-sm text-gray">
                        Medication requested: ${GLP_PRESCRIPTION.medication.name}
                      </p>
                    )}
                  </div>
                  <Common.Button className="flex-none max-md:hidden" color="green-alt">
                    View status
                  </Common.Button>
                  <button className="text-gray md:hidden">
                    <Common.Icon name="arrow-right" />
                  </button>
                </NavLink>
                <div className="mx-auto flex gap-4 max-md:mt-8">
                  <Common.Button color="white-alt" size="md" onClick={handleClickContactSupport}>
                    Contact support
                  </Common.Button>
                  {isFirstAppointmentCompleted && (
                    <Common.Button size="md" onClick={handleClickNewAppointment}>
                      Request appointment
                    </Common.Button>
                  )}
                </div>
              </>
            ) : (
              <>
                {isFreemium && <FirstAppointment className="md:mt-4" />}
                {showCreateNewApptBtn && (
                  <button
                    className="hidden w-full items-center gap-5 rounded-2xl border border-dashed px-5 py-4 text-base font-semibold text-gray-700 transition-all hover:bg-secondary-50 md:flex"
                    onClick={handleClickNewAppointment}
                  >
                    <Common.Icon
                      className="size-11 rounded-lg bg-secondary-100 p-3.5 text-secondary"
                      name="plus"
                    />
                    Create a new appointment
                  </button>
                )}
                {upcomingAppointmentsList.map(getAppointment)}
                {!upcomingAppointmentsList.length && !isFreemium && (
                  <span className="text-gray" data-testid="no_upcoming_appo_msg">
                    No upcoming appointments
                  </span>
                )}
              </>
            )}
          </FadeWrapper>
        )}
        {tab === 'History' && (
          <FadeWrapper className="flex flex-col gap-3 md:gap-4">
            {pastAppointmentsList.map(getAppointment)}
            {!pastAppointmentsList.length && (
              <span className="text-gray" data-testid="no_past_appo_msg">
                No past appointments
              </span>
            )}
          </FadeWrapper>
        )}
        {showCreateNewApptBtn && !isAsyncPlan && (
          <Common.Button
            className="mx-auto mt-auto md:hidden"
            color="blue"
            preIcon="plus"
            style="pill"
            onClick={handleClickNewAppointment}
          >
            New appointment
          </Common.Button>
        )}
      </div>
    </FadeWrapper>
  );
};

export default Appointments;
