import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import {
  useDeletePaymentMethodMutation,
  useLazyGetCreditCardInformationQuery,
  useLazyGetMyAccountQuery,
  useUpdatePaymentProfileMutation
} from 'services/myAccount/myAccount';

import PaymentMethodForm from 'features/PaymentMethodForm/PaymentMethodForm';
import { notifySuccess } from 'shared/Toast/Toast';
import { buildBodyForPaymentUpdate } from 'widgets/myAccount/BillingDetails/billingDetails.settings';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';

import { useExpiredCard } from 'hooks/useExpiredCard';
import { useRouteMatch } from 'hooks/useRouteMatch';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { PaymentMethodPurpose } from 'models/payments.types';

import { PaymentMethodActionsProps } from './paymentMethodActions.types';

const PaymentMethodActions: React.FC<PaymentMethodActionsProps> = ({
  isOpen,
  onClose,
  ppId = '',
  isPayPalConnected,
  isInsuranceUser,
  loading,
  selectedPPType,
  isDefault,
  activeTab
}) => {
  const [deletePaymentMethod, { isLoading: isLoadingDelete }] = useDeletePaymentMethodMutation();
  const [updatePaymentProfile, { isLoading: isLoadingUpdate }] = useUpdatePaymentProfileMutation();
  const [getPaymentMethodsInformation, { isLoading: isLoadingGet }] =
    useLazyGetCreditCardInformationQuery();
  const [getMyAccount] = useLazyGetMyAccountQuery();
  const isBillingDetails = useRouteMatch(PathName.BillingDetails)?.isExact;
  const isCheckout = useRouteMatch(PathName.Checkout)?.isExact;
  const [innerLoading, setInnerLoading] = useToggle(false);

  const { daysUntilCardExpiration, cardExpirationDate, isExpired } = useExpiredCard(ppId);

  const handleUpdatePaymentDetails = () => {
    Promise.all([
      getMyAccount().unwrap(),
      ...(isBillingDetails || isCheckout ? [getPaymentMethodsInformation().unwrap()] : []),
      ...(isInsuranceUser
        ? [getPaymentMethodsInformation({ isInsuranceSubscription: true }).unwrap()]
        : [])
    ]).then(() => onClose(true));
  };

  const handleDeletePaymentMethod = (displayNotification = true) => {
    if (ppId === 'new') {
      return handleUpdatePaymentDetails();
    }

    deletePaymentMethod({ id: ppId, isInsuranceSubscription: selectedPPType === 'appointments' })
      .unwrap()
      .then((data) => {
        displayNotification && notifySuccess(data.message);
        isBillingDetails && getPaymentMethodsInformation().unwrap();
        getMyAccount().unwrap();
      })
      .then(handleUpdatePaymentDetails)
      .catch(handleRequestCatch);
  };

  const handleUpdatePaymentMethod = async (
    formInfo: PaymentFormFields | string,
    types: PaymentMethodPurpose[]
  ) => {
    try {
      for await (const type of types) {
        if (types.length > 1 && types.indexOf(type) > 0) {
          // additional delay for updating multiple payment methods - BE asked to prevent sync issues
          setInnerLoading(true);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setInnerLoading(false);
        }
        await updatePaymentProfile({
          ...buildBodyForPaymentUpdate(
            formInfo,
            'POST',
            typeof formInfo === 'string' ? 'paypal_account' : 'credit_card'
          ),
          isInsuranceSubscription: type === 'appointments'
        }).unwrap();
      }
      notifySuccess('Payment method updated successfully.');
      handleDeletePaymentMethod(false);
    } catch (error) {
      handleRequestCatch(error as MessageEvent, 'Error updating payment method.');
    }
  };

  return (
    <Common.Modal isOpen={isOpen} size="base" persist>
      {daysUntilCardExpiration < 30 && (
        <Common.Alert className="mb-8" type={isExpired ? 'error' : 'warning'} colorableBackground>
          <b>{isExpired ? 'Billing past due' : 'Your credit card is about to expire'}.&nbsp;</b>
          {isExpired
            ? 'Please update your payment method to access prescription renewals, appointments, and care team messages.'
            : `Your card expires ${cardExpirationDate}. Update payment method to continue using LifeMD.`}
        </Common.Alert>
      )}
      <PaymentMethodForm
        action={ppId === 'new' ? 'add' : 'edit'}
        isLoading={isLoadingGet || isLoadingDelete || isLoadingUpdate || !!loading || innerLoading}
        isPayPalConnected={isPayPalConnected}
        isShowOptions={isInsuranceUser && isBillingDetails}
        preselectedType={isInsuranceUser ? activeTab || 'appointments' : undefined}
        onCancel={() => onClose(false)}
        onConfirm={handleUpdatePaymentMethod}
        onDelete={isDefault ? undefined : handleDeletePaymentMethod}
      />
    </Common.Modal>
  );
};

export default PaymentMethodActions;
