import { FieldValues, useController } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { AT_LEAST_ONE_NUMBER, AT_LEAST_ONE_SPEC_CHAR_REGEXP } from 'utils/regExp';

const NewPasswordInput = <TFormValues extends FieldValues>({
  name,
  control,
  placeholder = 'New password',
  label,
  size
}: TFormValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name,
    rules: {
      required: true,
      validate: {
        minLength: (value) => value.length > 7,
        specialChar: (value) =>
          AT_LEAST_ONE_SPEC_CHAR_REGEXP.test(value) || AT_LEAST_ONE_NUMBER.test(value)
      }
    }
  });
  const newPasswordErrorTypes = fieldState.error?.types;
  const newPasswordIsDirty = fieldState.isDirty;

  const isEmpty = !newPasswordErrorTypes?.minLength && newPasswordIsDirty;
  const isSpecialChar = !newPasswordErrorTypes?.specialChar && newPasswordIsDirty;

  const getClassName = (isValid: boolean) => {
    return classNames(
      'flex items-center gap-1 text-mSm md:text-sm',
      isValid ? 'text-green' : 'text-gray'
    );
  };
  const iconClassName = 'size-4';
  const errorsClassName = 'mt-4 flex flex-col gap-1 md:mt-2';

  return (
    <div className="w-full">
      <Common.Input
        dataTestId="password_field"
        {...field}
        errors={fieldState.error}
        inputClassName="text-gray-700"
        label={label}
        maxLength={100}
        placeholder={placeholder}
        size={size}
        type="password"
      />
      <div className={errorsClassName} data-testid="password_validation_msgs">
        <p className={getClassName(isEmpty)} data-testid={`green=_${isEmpty}`}>
          <Common.Icon className={iconClassName} name={isEmpty ? 'check' : 'close'} />
          At least 8 characters
        </p>
        <p className={getClassName(isSpecialChar)} data-testid={`green=_${isSpecialChar}`}>
          <Common.Icon className={iconClassName} name={isSpecialChar ? 'check' : 'close'} />
          At least 1 number or 1 special character
        </p>
      </div>
    </div>
  );
};

export default NewPasswordInput;
