import { useState } from 'react';
import { Matcher } from 'react-day-picker';
import dayjs from 'dayjs';

import { useLazyGetAvailableDatesQuery as useLazyGetGlobalAvailableDatesQuery } from 'services/auth/auth';
import { GetAvailableDatesReqProps as GlobalDatesReqProps } from 'services/auth/auth.types';
import { useLazyGetAvailableDatesQuery } from 'services/providers/providers';
import {
  GetAvailableDatesReqProps,
  GetAvailableDatesResProps
} from 'services/providers/providers.types';

import { selectUser } from 'store';

import { useAppSelector } from 'hooks';
import { findDisabledDates } from 'utils/helpers';

type Payload =
  | (Omit<GetAvailableDatesReqProps, 'startDate' | 'endDate' | 'timezone'> & { provider: 'my' })
  | (Omit<GlobalDatesReqProps, 'startDate' | 'endDate' | 'timezone'> & { provider: 'any' });

export const useGetDisabledDates = () => {
  const timezone = dayjs.tz?.guess();
  const [getAvailableDatesForProvider, { isFetching }] = useLazyGetAvailableDatesQuery();
  const [getGlobalDates, { isFetching: isFetchingGlobal }] = useLazyGetGlobalAvailableDatesQuery();
  const { accessToken } = useAppSelector(selectUser);
  const [disabledDatesList, setDisabledDatesList] = useState<Matcher[]>([]);

  const handleGetAvailableDatesThen = (
    res: GetAvailableDatesResProps,
    periodStart: string,
    periodEnd: string
  ) => {
    const mappedDates =
      findDisabledDates(
        res.data.map((d) => d.date),
        periodStart,
        periodEnd
      ).map((date) => dayjs(date).toDate()) ?? [];

    setDisabledDatesList(mappedDates);
  };

  const getDisabledDates = async (date: Date, payload: Payload): Promise<void> => {
    const startDate = dayjs(date).isBefore(dayjs())
      ? dayjs().format('YYYY-MM-DD')
      : dayjs(date).format('YYYY-MM-DD');
    const endDate = dayjs(startDate).endOf('month').format('YYYY-MM-DD');
    setDisabledDatesList([
      {
        from: dayjs(startDate).toDate(),
        to: dayjs(endDate).toDate()
      }
    ]);
    try {
      if (payload.provider === 'my') {
        const { provider: p, ...rest } = payload;
        const res = await getAvailableDatesForProvider({
          ...rest,
          ...(accessToken && { accessToken }),
          endDate,
          startDate,
          timezone
        }).unwrap();
        handleGetAvailableDatesThen(res, startDate, endDate);
      } else {
        const { provider: p, ...rest } = payload;
        const res = await getGlobalDates(
          {
            ...rest,
            ...(accessToken && { accessToken }),
            endDate,
            startDate,
            timezone
          },
          true
        ).unwrap();
        handleGetAvailableDatesThen(res, startDate, endDate);
      }
    } catch (error) {
      setDisabledDatesList([]);
    }
  };
  return {
    disabledDatesList: [{ before: new Date() }, ...disabledDatesList],
    getDisabledDates,
    loading: isFetching || isFetchingGlobal
  };
};

export default useGetDisabledDates;
