import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import {
  useAddAppointmentMutation,
  useUploadFilesMutation
} from 'services/appointments/appointments';
import { GetAppointmentsDataItemProps } from 'services/appointments/appointments.types';
import {
  useGetAppointmentCategoriesQuery,
  useGetMembershipPlansQuery,
  useLazyGetAppointmentTypesQuery
} from 'services/lookup/lookup';
import { useUpdateMyAccountMutation } from 'services/myAccount/myAccount';
import { useGetProviderQuery } from 'services/providers/providers';

import { selectProvider, selectUser } from 'store';
import { AppointmentProps } from 'store/appointments/appointments.types';
import { setUser } from 'store/user/userSlice';

import PaymentMethodActions from 'modals/PaymentMethodActions';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import Loader from 'shared/Loader';
import Stepper from 'shared/ProgressBar';
import AttachFiles from 'widgets/appointments/AttachFiles';
import CareType from 'widgets/appointments/CareType';
import Confirmation from 'widgets/appointments/Confirmation';
import MedicalCareNear from 'widgets/appointments/MedicalCareNear';
import Membership from 'widgets/appointments/Membership';
import PaymentCheckout from 'widgets/appointments/payment/PaymentCheckout';
import RedFlags from 'widgets/appointments/RedFlags';
import CategoryAndDetails from 'widgets/CategoryAndDetails';
import DateAndTime from 'widgets/DateAndTime';

import { DEFAULT_APPOINTMENT_TYPE } from 'constants/defaults';
import { WM_INSURANCE_VALID_PRICE_POINTS } from 'constants/onboarding';
import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { useExpiredCard } from 'hooks/useExpiredCard';
import { PathName } from 'utils/enums';
import { findAppointmentTypeByData, handleRequestCatch } from 'utils/helpers';

import { buildBodyForApptSchedule, isButtonDisabled } from './createAppointment.settings';
import { MembershipData, StepsProps, StepsTypes } from './createAppointment.types';

const CreateAppointment = () => {
  useTitle('LifeMD - Appointments');
  const [getAppointmentTypes] = useLazyGetAppointmentTypesQuery();
  const srcFromUrl = useQuery().get('src');
  const date = useQuery().get('date') || '';
  const src = srcFromUrl === 'checker' || srcFromUrl === 'appMobile' ? srcFromUrl : 'default';
  const initialStep = Number(useQuery().get('step')) > 0 ? 2 : 0;
  const { isExpired, isOpenEditPayment, cardInfo, toggleIsOpenEditPayment } = useExpiredCard();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { doctorId, accessToken, isUnlimitedPlan, activePlanId, activePricePoint } =
    useAppSelector(selectUser);
  const { displayName } = useAppSelector(selectProvider);
  const [randomProviderName, setRandomProviderName] = useState('');
  const [stepsList, setStepsList] = useState<StepsTypes>([
    'RED_FLAGS',
    'MEDICAL_CARE_NEAR',
    // 'CARE_TYPE',
    'CATEGORY',
    'FILES',
    'DATE_TIME',
    'CONFIRMATION'
  ]);

  const isInsurancePatient =
    !!activePricePoint && WM_INSURANCE_VALID_PRICE_POINTS.includes(activePricePoint);

  const shouldProceedWithoutPayment = isUnlimitedPlan && !isInsurancePatient;
  const [data, setData] = useState<Required<AppointmentProps>>({
    _id: '',
    appointmentDescription: '',
    appointmentTypeId: '',
    callType: 'video',
    category: '',
    displayName: '',
    doctorId,
    endTime: '',
    files: [],
    isWeightManagementAppointment: false,
    startTime: '',
    uploadRequired: false
  });
  const [membershipData, setMembershipData] = useState<MembershipData>({
    freeAppointmentInfo: {
      bookedFreeAppointmentDate: undefined,
      freeAppointmentsAmount: 0,
      isFree: false,
      periodEnd: '',
      periodStart: ''
    },
    planID: '',
    pp: null,
    type: ''
  });
  const [step, setStep] = useState(initialStep);
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useGetProviderQuery(
    {
      providerId: doctorId
    },
    { skip: (!!displayName && src !== 'appMobile') || !doctorId }
  );
  const [updateMyAccount, { isLoading: isLoadingUpdateUser }] = useUpdateMyAccountMutation();
  const [addAppointment, { isLoading: isLoadingAddAppointment }] = useAddAppointmentMutation();
  const [uploadFiles] = useUploadFilesMutation();
  const { data: plans, isFetching: isFetchingPlans } = useGetMembershipPlansQuery();
  const { data: careTypes, isFetching: isFetchingCareTypes } =
    useGetAppointmentCategoriesQuery(accessToken);

  const isShowStepper =
    src !== 'checker' && !['MEDICAL_CARE_NEAR', 'RED_FLAGS'].includes(stepsList[step]);
  const isShowBackButton =
    src === 'checker' ? stepsList[step] !== 'DATE_TIME' : ['MEMBERSHIP'].includes(stepsList[step]);
  const isShowNextButton = ![
    'RED_FLAGS',
    'CARE_TYPE',
    'CATEGORY',
    'MEDICAL_CARE_NEAR',
    'CONFIRMATION',
    'DATE_TIME',
    'CHECKOUT'
  ].includes(stepsList[step]);
  const plan = plans?.data.find((p) => p._id === membershipData.planID);
  const existingPlan = plans?.data.find((p) => p._id === activePlanId);

  const updateDate = (
    type: 'date' | 'time',
    value: string,
    valueEnd: string,
    combinedDoctorId?: string,
    combinedDisplayName?: string
  ) => {
    if (type === 'time') {
      setData(
        !!combinedDoctorId
          ? { ...data, doctorId: combinedDoctorId, endTime: valueEnd, startTime: value }
          : { ...data, doctorId, endTime: valueEnd, startTime: value }
      );
      setRandomProviderName(!!combinedDoctorId && !!combinedDisplayName ? combinedDisplayName : '');
    }
  };

  const goToNextStep = () => {
    setLoading(false);
    setStep(Math.min(step + 1, stepsList.length - 1));
  };

  const handleUploadFilesThen = () => {
    goToNextStep();
    setLoading(false);
  };

  const handleUploadFilesCatch = () => {
    toast.warn(
      'Appointment is scheduled successfully but there is an error with the uploading of the files'
    );
    goToNextStep();
  };

  const handleUploadFilesFinally = () => {
    if (membershipData.type === 'unlimited') {
      setIsPopupOpen(true);
    }
  };

  const handleScheduleNewAppointmentThen = (response: GetAppointmentsDataItemProps) => {
    setData({ ...data, _id: response._id });
    if (files.length) {
      const formData = new FormData();
      files.forEach((file: File) => {
        formData.append('appointmentImages', file);
      });
      uploadFiles({ appointmentId: response._id, body: formData })
        .unwrap()
        .then(handleUploadFilesThen)
        .catch(handleUploadFilesCatch)
        .finally(handleUploadFilesFinally);
    } else {
      if (src === 'checker') {
        setStep(stepsList.length - 1);
      } else {
        goToNextStep();
      }
      if (membershipData.type === 'unlimited') {
        setIsPopupOpen(true);
      }
    }
  };

  const handleScheduleNewAppointmentCatch = (e: MessageEvent) => {
    handleRequestCatch(e, 'Can`t book appointment, please try again');
    if (e.data?.message === 'Slot not Available') {
      setStep(step - 1);
    }
  };

  const handleScheduleNewAppointmentFinally = () => {
    if (membershipData.type === 'unlimited') {
      dispatch(setUser({ accessToken, doctorId, isUnlimitedPlan: true }));
    }
    setLoading(false);
  };

  const scheduleNewAppointment = () => {
    if (isLoadingAddAppointment) {
      return;
    }
    try {
      setLoading(true);
      addAppointment(buildBodyForApptSchedule(data))
        .unwrap()
        .then((response) => handleScheduleNewAppointmentThen(response.data))
        .catch(handleScheduleNewAppointmentCatch)
        .finally(handleScheduleNewAppointmentFinally);
    } catch (e) {
      setLoading(false);
      toast.warn((e as Error).message);
    }
  };

  const prevStep = () => {
    if (stepsList[step - 1] === 'FILES' && !data.uploadRequired) {
      setStep(step - 2);
    } else {
      setStep(step - 1);
    }
  };

  const nextStep = () => {
    const handleNotUnlimitedPatients = () => {
      if (isInsurancePatient) {
        setMembershipData((prev) => ({ ...prev, planID: activePlanId, type: 'one-time' }));
        goToNextStep();
      } else {
        goToNextStep();
      }
    };
    switch (stepsList[step]) {
      case 'DATE_TIME':
        return shouldProceedWithoutPayment
          ? scheduleNewAppointment()
          : handleNotUnlimitedPatients();
      default:
        goToNextStep();
    }
  };

  const setMData = (newData: Partial<MembershipData>) => {
    setMembershipData({ ...membershipData, ...newData });
  };

  const selectMembership = () => {
    if (isLoadingUpdateUser || !membershipData.type) {
      return;
    }

    if (membershipData.type === 'one-time' || isInsurancePatient) {
      return scheduleNewAppointment();
    }

    updateMyAccount({
      planId: plan?._id,
      ...(!!membershipData.pp && { planPricePointId: membershipData.pp.planPricePointId })
    })
      .unwrap()
      .then(scheduleNewAppointment)
      .catch(handleRequestCatch);
  };

  const stepContent = () => {
    const steps: StepsProps = {
      CARE_TYPE: (
        <CareType
          careTypes={careTypes?.data || []}
          key="2"
          loading={isFetchingCareTypes}
          setApptType={(category: string) => setData({ ...data, category })}
          onSelect={nextStep}
        />
      ),
      CATEGORY: (
        <SlideAnimateWrapper className="mx-auto max-w-[420px]">
          <CategoryAndDetails
            appointmentDescription={data.appointmentDescription}
            appointmentTypeId={data.appointmentTypeId}
            category={data.category}
            icon="large-calendar"
            key="CATEGORY"
            title="What is this appointment for?"
            onClickNext={(res) => {
              setData({ ...data, ...res });
              res?.uploadRequired ? goToNextStep() : setStep(step + 2);
            }}
          />
        </SlideAnimateWrapper>
      ),
      CHECKOUT:
        !!plan && !!existingPlan ? (
          <PaymentCheckout
            category={data?.displayName || ''}
            existingPlan={existingPlan}
            freeAppointmentInfo={membershipData.freeAppointmentInfo}
            key="7"
            loading={isLoadingAddAppointment || isLoadingUpdateUser}
            providerName={randomProviderName || displayName}
            selectedPlan={plan}
            selectedPricePoint={membershipData.pp}
            time={data.startTime}
            onProceed={selectMembership}
          />
        ) : (
          <span>No plan selected</span>
        ),
      CONFIRMATION: (
        <Confirmation
          appointment={{
            apptName: data?.displayName,
            description: data.appointmentDescription,
            endTime: data.endTime,
            id: data._id,
            startTime: data.startTime,
            title: data?.displayName || 'Your appointment is scheduled'
          }}
          closePopup={() => setIsPopupOpen(false)}
          isPopupOpen={isPopupOpen}
          key="8"
          providerId={data.doctorId}
          providerName={randomProviderName || displayName}
          src={src}
        />
      ),
      DATE_TIME: data.appointmentTypeId ? (
        <DateAndTime
          accessToken={accessToken}
          appointmentTypeId={data.appointmentTypeId}
          doctorId={doctorId}
          initialDate={date}
          key="5"
          loading={isLoadingAddAppointment || isLoadingUpdateUser}
          providerType={!!date ? 'any' : undefined}
          updateStartDate={updateDate}
          onSelect={nextStep}
        />
      ) : (
        <></>
      ),
      FILES: <AttachFiles files={files} key="4" setFiles={setFiles} />,
      MEDICAL_CARE_NEAR: <MedicalCareNear key="1" />,
      MEMBERSHIP: (
        <Membership
          key="6"
          loading={isFetchingPlans}
          selectedPlanId={membershipData.planID}
          startTime={data.startTime}
          onSelect={setMData}
        />
      ),
      RED_FLAGS: (
        <RedFlags
          key="0"
          onSelect={() => setStep(step + 2)}
          onSkip={() => navigate(PathName.Dashboard)}
        />
      )
    };

    return steps[stepsList[step]];
  };

  const handleCheckStepsAmount = () => {
    if (src === 'default' || src === 'appMobile') {
      const stepsToToggle: StepsTypes = isInsurancePatient
        ? ['CHECKOUT']
        : ['MEMBERSHIP', 'CHECKOUT'];
      if (!shouldProceedWithoutPayment && !stepsList.includes('MEMBERSHIP')) {
        const arr = [...stepsList];
        arr.splice(-1, 0, ...stepsToToggle);
        setStepsList(arr);
      } else {
        setStepsList(stepsList.filter((e) => !stepsToToggle.includes(e)));
      }
    } else if (src === 'checker') {
      setStepsList(['DATE_TIME', 'MEMBERSHIP', 'CHECKOUT', 'CONFIRMATION']);
    }
  };

  const handleSteps = () => {
    navigate({ search: `${date ? `date=${date}&` : ''}step=${step}` });
    window.scrollTo({ top: 0 });
    // @TODO: find another solution, based on unlimited membership
    if (step > stepsList.length - 1) {
      setStep(stepsList.length - 1);
    }
  };

  const handleUpdatedSrc = () => {
    if (src === 'checker') {
      // TODO: try to refactor this code.
      getAppointmentTypes({})
        .unwrap()
        .then((res) => {
          const defaultItem = findAppointmentTypeByData(res.data, DEFAULT_APPOINTMENT_TYPE);
          setData({ ...data, category: '', appointmentTypeId: defaultItem?._id || '' });
        });
    }
  };

  useEffect(() => {
    setData({ ...data, appointmentTypeId: '' });
  }, [data.category]);

  useEffect(() => {
    setData({ ...data, doctorId });
  }, [doctorId]);

  useEffect(handleCheckStepsAmount, [shouldProceedWithoutPayment, src]);

  useEffect(handleUpdatedSrc, [src]);

  useEffect(handleSteps, [step, stepsList]);

  useEffect(() => {
    isExpired && toggleIsOpenEditPayment(true);
  }, [isExpired]);

  return (
    <FadeWrapper
      className={classNames('flex min-h-full flex-1 flex-col md:flex-none', {
        'blur-sm': isExpired
      })}
    >
      <PaymentMethodActions
        isOpen={isOpenEditPayment}
        isPayPalConnected={cardInfo?.paymentMethod === 'paypal_account'}
        ppId={cardInfo?.chargifyPaymentProfileId}
        onClose={(res) => {
          toggleIsOpenEditPayment();
          !res && navigate(PathName.Dashboard);
        }}
      />
      <div className="flex h-full flex-1 flex-col rounded-2xl md:bg-white md:shadow">
        <Loader isVisible={isLoadingUpdateUser} />
        {isShowStepper && (
          <div className="mx-auto w-full py-4 md:p-8 md:pb-4">
            <Stepper color="primary" step={step} totalSteps={stepsList.length - 1} />
          </div>
        )}
        <div
          className={classNames('flex flex-grow md:p-8', {
            'md:flex-grow-0': step === stepsList.indexOf('MEMBERSHIP'),
            'overflow-visible': step === 4
          })}
        >
          <div className="mx-auto w-full max-w-screen-sm">
            <AnimatePresence initial={false} mode="wait">
              {stepContent()}
            </AnimatePresence>
          </div>
        </div>
        {(isShowBackButton || isShowNextButton) && (
          <div className="mt-4 flex w-full flex-col items-center justify-center gap-4 self-center md:m-0 md:flex-row md:pb-12">
            {isShowBackButton && (
              <Common.Button
                className="hidden md:block"
                color="white-alt"
                dataTestId="back_btn"
                disabled={loading}
                fullWidthOnMobile
                onClick={prevStep}
              >
                Back
              </Common.Button>
            )}
            {isShowNextButton && (
              <Common.Button
                color="blue"
                dataTestId="next_btn"
                disabled={
                  isButtonDisabled(loading, stepsList[step], data) ||
                  (stepsList[step] === 'MEMBERSHIP' && !membershipData.pp)
                }
                postIcon="arrow-right"
                fullWidthOnMobile
                onClick={nextStep}
              >
                Next
              </Common.Button>
            )}
          </div>
        )}
      </div>
    </FadeWrapper>
  );
};

export default CreateAppointment;
