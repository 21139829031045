import { Common } from '@thecvlb/design-system';
import { AnimatePresence } from 'framer-motion';

import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import Loader from 'shared/Loader';
import AppointmentTypeCard from 'widgets/appointments/CareType/AppointmentTypeCard';

import { CareTypeProps } from './careType.types';

const CareType: React.FC<CareTypeProps> = ({ setApptType, onSelect, careTypes, loading }) => {
  const loadAllCategories = () => {
    setApptType('');
    onSelect();
  };

  return (
    <AnimatePresence>
      <Loader isVisible={loading} />
      <SlideAnimateWrapper key="care-type">
        <Common.Illustration className="mx-auto hidden w-36 md:block" name="large-calendar" />
        <h1 className="large-title my-4 text-center md:my-8" data-testid="appointment_steps_title">
          What type of appointment is this?
        </h1>
        <div className="mx-auto mt-4 flex max-w-fit flex-col items-center justify-center gap-4 md:my-0 md:flex-row md:gap-6">
          {careTypes
            .filter((careType) => careType.name !== 'Telehealth visit')
            .map((plan) => (
              <AppointmentTypeCard
                key={plan.name}
                {...plan}
                setApptType={setApptType}
                onSelect={onSelect}
              />
            ))}
        </div>
        <p
          className="mb-2 mt-8 text-center text-mLg font-bold text-primary md:mt-12 md:text-lg"
          data-testid="not_sure_block"
        >
          Not sure what type of appointment to select?
          <span
            className="mx-1 block cursor-pointer text-secondary md:inline"
            data-testid="click_here_btn"
            aria-hidden
            onClick={loadAllCategories}
          >
            Click here.
          </span>
        </p>
      </SlideAnimateWrapper>
    </AnimatePresence>
  );
};

export default CareType;
