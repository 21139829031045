import Lottie from 'lottie-react';

import { FlowTypes } from 'utils/enums';

import illustration from 'assets/animations/wm-onboarding/loading-bar-3s.json';

const Processing = ({ flow }: { flow?: FlowTypes | 'authorized' }) => {
  return (
    <>
      <h2 className="wm-signup-title mt-8 max-w-[75%] text-center">Preparing your results</h2>
      <Lottie animationData={illustration} />
      <p className="text-primary-700">
        <b>Fact:</b>{' '}
        {flow === FlowTypes.TripleTherapy
          ? 'Metformin, Bupropion, and Topiramate are three science-backed medications that when combined in carefully selected doses, can help you reach your weight-loss goals.'
          : 'GLP-1s are the safest and most effective medications for sustained weight loss.'}
      </p>
    </>
  );
};

export default Processing;
