import { useWindowSize } from 'react-use';
import { Common } from '@thecvlb/design-system';

import useWidth from 'hooks/useWidth';
import { ASPECT_RATIO } from 'utils/constants';

import { AppointmentPreviewProps } from './appointmentPreview.types';

const AppointmentPreview: React.FC<AppointmentPreviewProps> = ({
  isOpen,
  onClose,
  referenceUrl = ''
}) => {
  const { isMobile } = useWidth();
  const { width } = useWindowSize();
  const videoWidth = (isMobile ? width : 800) - 48;

  return (
    <Common.Modal close={onClose} isOpen={isOpen} size="xl" zIndex={30}>
      {referenceUrl ? (
        <iframe
          allow="autoplay; fullscreen; picture-in-picture"
          className="m-auto"
          src={`${referenceUrl}&loop=1`}
          style={{
            height: videoWidth / ASPECT_RATIO,
            width: videoWidth
          }}
          title="video"
          allowFullScreen
        />
      ) : (
        <span className="px-12">Oops, video is missing, we`re already work on it</span>
      )}
    </Common.Modal>
  );
};

export default AppointmentPreview;
