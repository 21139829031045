/* eslint-disable @typescript-eslint/ban-ts-comment */
import { URL_REGEX } from 'utils/regExp';

// @ts-ignore
const parseNode = (node) => {
  if (node.nodeType === Node.TEXT_NODE) {
    const content = node.textContent;
    const replacedContent = content.replace(
      URL_REGEX,
      '<a href="$1" target="_blank" class="text-mBase text-primary md:text-base">$1</a>'
    );
    const tempNode = document.createElement('span');
    tempNode.innerHTML = replacedContent;
    while (tempNode.firstChild) {
      node.parentNode.insertBefore(tempNode.firstChild, node);
    }
    node.parentNode.removeChild(node);
  } else {
    const classMap = {
      OL: ['list-decimal', 'whitespace-normal', 'pl-5'],
      UL: ['list-disc', 'whitespace-normal', 'pl-5'],
      CODE: ['whitespace-pre-wrap']
    };
    // @ts-ignore
    if (classMap[node.nodeName]) {
      // @ts-ignore
      node.classList.add(...classMap[node.nodeName]);
    }
    node.childNodes.forEach(parseNode);
  }
};

// @ts-ignore
const addClassesToLinks = (node) => {
  if (node.nodeName === 'A') {
    node.classList.add('text-mBase', 'text-primary', 'md:text-base');
  } else if (node.childNodes && node.childNodes.length > 0) {
    node.childNodes.forEach(addClassesToLinks);
  }
};

export const parseMessage = (message = '') => {
  const parser = new DOMParser();
  const emailRegex = /<([^<>@\s]+@[^<>@\s]+\.[^<>@\s]+)>/g;

  const doc = parser.parseFromString(
    message
      .replace(emailRegex, (match) => match.replace(/</g, '&lt;'))
      .replace(/&lt;/g, '&amp;lt;')
      .replace(/\\n/g, '<br />'),
    'text/html'
  );
  doc.body.childNodes.forEach(parseNode);
  doc.body.childNodes.forEach(addClassesToLinks);

  return doc.body.innerHTML;
};

export const parseMessageToChannel = (message = '') => {
  return message.replace(/<[^>]*>?/gm, '');
};
