import { useState } from 'react';
import { Common } from '@thecvlb/design-system';

import RadioGroup from 'shared/RadioGroup';

import { FlowTypes } from 'utils/enums';

import BackToMainWebsiteButton from '../../parts/BackToMainWebsiteButton';
import Heading from '../../parts/Heading';

const ITEMS = [
  { label: 'Yes, Type I', value: 'yes_type_i' },
  { label: 'Yes, Type II', value: 'yes_type_i_i' },
  { label: 'No', value: 'no' }
];
const Diabetes: React.FC<{
  isErrorState?: boolean;
  loading: boolean;
  onHandleProspect: () => void;
  onSelect: (v: string, cb?: () => void) => void;
  selectedFlow: 'authorized' | FlowTypes;
}> = ({ onSelect, selectedFlow, loading, onHandleProspect, isErrorState }) => {
  const isTripleTherapy = selectedFlow === FlowTypes.TripleTherapy;

  const [activeItem, setActiveItem] = useState('');

  const handleSelect = (v: string) => {
    setActiveItem(v);
    onSelect(v, () => setActiveItem(''));
  };

  return (
    <div className="flex h-full flex-col gap-6">
      {isErrorState ? (
        <>
          <h2 className="wm-signup-title text-center" data-testid="header">
            {isTripleTherapy
              ? 'Unfortunately, you do not pre-qualify for the Triple Therapy Program.'
              : 'We regret to inform you that you are not suitable for GLP-1 medication.'}
          </h2>
          <Common.Alert type="warning" colorableBackground>
            {isTripleTherapy
              ? `Patients with type 1 diabetes do not pre-qualify for the Triple Therapy Program.
              However, LifeMD is committed to supporting your broader wellness goals.
              Visit the LifeMD website to learn more about how we can help you get the medical care you deserve.`
              : `Patients with type-1 diabetes are not suitable for treatment with GLP-1 medication.
            However, our weight loss program incorporates the use of other FDA-approved medications
            for patients unsuitable for GLP-1 treatment. These medications include metformin and
            contrave (naltrexone/bupropion).`}
          </Common.Alert>
          <BackToMainWebsiteButton loading={loading} onClick={onHandleProspect} />
        </>
      ) : (
        <>
          <Heading category="Eligibility" title="Have you been diagnosed with diabetes?" />
          <RadioGroup
            isLoading={loading}
            items={ITEMS}
            selectedItem={activeItem}
            onSelect={handleSelect}
          />
        </>
      )}
    </div>
  );
};

export default Diabetes;
