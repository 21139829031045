import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import {
  useAddHealthMetricsMutation,
  useDeleteHealthMetricMutation,
  useUpdateHealthMetricsMutation
} from 'services/healthMetrics/healthMetrics';

import MeasurementDateInput from 'shared/form/MeasurementDateInput';
import NumberInput from 'shared/form/NumberInput';

import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import BasicConfirmation from '../BasicConfirmation';

import { BloodPressureEditFormValues, BloodPressureEditProps } from './bloodPressureEdit.types';

const BloodPressureEdit: React.FC<BloodPressureEditProps> = ({ isOpen, onClose, element }) => {
  const [addHealthMetrics, { isLoading: isLoadingAdd }] = useAddHealthMetricsMutation();
  const [updateHealthMetrics, { isLoading: isLoadingUpdate }] = useUpdateHealthMetricsMutation();
  const [deleteHealthMetric, { isLoading: isLoadingDelete }] = useDeleteHealthMetricMutation();
  const isLoading = isLoadingAdd || isLoadingUpdate;
  const { isMobile } = useWidth();

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const { handleSubmit, control, formState, reset, getValues } =
    useForm<BloodPressureEditFormValues>({
      criteriaMode: 'all',
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      shouldFocusError: false
    });

  const handleDelete = (cb: () => void) => {
    if (!element) return;

    deleteHealthMetric({
      collectedDate: dayjs(element.collectedDate).format(DateFormat.YYYY_MM_DD),
      metricType: 'bloodPressure'
    })
      .unwrap()
      .then(cb)
      .catch(handleRequestCatch);
  };

  const onSubmit = (data: BloodPressureEditFormValues) => {
    const body = {
      collectedDate: dayjs(data.date).format(DateFormat.YYYY_MM_DD),
      metrics: {
        bloodPressure: {
          DIA: +data.DIA,
          SYS: +data.SYS,
          pulse: +data.pulse
        }
      }
    };

    const performAction = (action: typeof addHealthMetrics | typeof updateHealthMetrics) => {
      action(body)
        .unwrap()
        .then(() => onClose(true))
        .catch(handleRequestCatch);
    };

    if (!element) {
      performAction(addHealthMetrics);
      return;
    }

    const isSameDay = dayjs(element.collectedDate).diff(dayjs(body.collectedDate), 'days') === 0;
    if (isSameDay) {
      performAction(updateHealthMetrics);
    } else {
      handleDelete(() => performAction(updateHealthMetrics));
    }
  };

  const handleClick = () => {
    if (
      dayjs(element?.collectedDate).format(DateFormat.YYYY_MM_DD) ===
      dayjs(getValues().date).format(DateFormat.YYYY_MM_DD)
    ) {
      handleSubmit(onSubmit)();
    } else {
      setShowConfirmPopup(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        DIA: element?.['metrics.bloodPressure.DIA'] || '',
        SYS: element?.['metrics.bloodPressure.SYS'] || '',
        date: dayjs(element?.collectedDate).format(DateFormat.MM_DD_YYYY),
        pulse: element?.['metrics.bloodPressure.pulse']?.replace(' bpm', '') || ''
      });
    } else {
      setShowConfirmPopup(false);
    }
  }, [isOpen]);

  return (
    <Common.Modal
      autoScroll={isMobile}
      close={() => {
        setShowConfirmPopup(false);
        onClose();
      }}
      description={`${!element ? 'Add' : 'Edit'} ${isMobile ? 'blood pressure' : 'measurement'}`}
      isOpen={isOpen}
      size="base"
      title="Blood Pressure"
      zIndex={30}
    >
      <BasicConfirmation
        confirmButtonText="Confirm"
        isLoading={isLoading}
        isOpen={showConfirmPopup}
        subHeaderText="You are potentially overwriting a previously recorded value by changing the date of another weight measurement"
        onClose={() => setShowConfirmPopup(false)}
        onConfirm={() => handleSubmit(onSubmit)()}
      />
      <div className="flex flex-col gap-6 p-0.5 md:gap-4 md:pr-4">
        <div className="flex justify-between gap-6 md:gap-4">
          <div
            className={classNames(
              'flex flex-col gap-2 md:flex-row',
              formState.errors.SYS ? 'md:items-baseline' : 'md:items-center'
            )}
          >
            <label className="hidden text-mBase font-semibold text-gray-700 md:block md:min-w-[120px] md:text-base">
              Blood pressure
            </label>
            <NumberInput
              className="md:w-[136px]"
              control={control}
              dataTestId="sys_input"
              label={isMobile ? 'Systolic (SYS)' : undefined}
              labelDirection={isMobile ? 'col' : 'row'}
              name="SYS"
              postText={isMobile ? 'mmHg' : 'SYS'}
              requiredErrorMsg="SYS is required"
            />
          </div>
          <div
            className={classNames(
              'md:flex md:items-center md:gap-2',
              formState.errors.DIA ? 'md:items-baseline' : 'md:items-center'
            )}
          >
            <NumberInput
              className="md:w-[136px]"
              control={control}
              dataTestId="dia_input"
              label={isMobile ? 'Diastolic (DIA)' : undefined}
              name="DIA"
              postText={isMobile ? 'mmHg' : 'DIA'}
              requiredErrorMsg="DIA is required"
            />
          </div>
        </div>
        <NumberInput
          containerClassName={isMobile ? '' : 'md:w-[288px]'}
          control={control}
          dataTestId="pulse_input"
          label="Pulse"
          labelDirection={isMobile ? 'col' : 'row'}
          name="pulse"
          postText={isMobile ? 'bpm' : '/min'}
          requiredErrorMsg="Pulse is required"
        />
        <MeasurementDateInput
          control={control}
          dataTestId="blood_pressure_date"
          labelDirection={isMobile ? 'col' : 'row'}
          showTodayCheckbox={!element}
        />
        <div
          className={classNames(
            'flex flex-col gap-3 md:flex-row-reverse',
            isMobile ? 'mt-4' : 'mt-2'
          )}
        >
          <Common.Button
            color="blue"
            dataTestId="add_measurement_btn"
            isLoading={isLoading}
            size={isMobile ? 'lg' : 'sm'}
            fullWidthOnMobile
            onClick={handleClick}
          >
            {!element || isMobile ? 'Add' : 'Edit'} measurement
          </Common.Button>
          <Common.Button
            color={isMobile ? undefined : 'white-alt'}
            dataTestId="cancel_btn"
            size={isMobile ? 'lg' : 'sm'}
            type="button"
            fullWidthOnMobile
            onClick={() => onClose(false)}
          >
            Cancel
          </Common.Button>
          {element && !isMobile && (
            <Common.Button
              className="mr-auto"
              color="red-alt"
              dataTestId="delete_btn"
              isLoading={isLoadingDelete}
              preIcon="trash"
              size="sm"
              type="button"
              onClick={() => handleDelete(() => onClose(true))}
            >
              Delete
            </Common.Button>
          )}
        </div>
      </div>
    </Common.Modal>
  );
};

export default BloodPressureEdit;
