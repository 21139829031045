import { useParams } from 'react-router-dom';

import { ReducedSignupStepProps } from 'containers/SignUp/Content/content.types';
import WeightLossGoalPicker from 'widgets/wmOnboarding/pickers/WeightLossGoalPicker';

import { DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE } from 'constants/defaults';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';

const WeightLossGoal: React.FC<ReducedSignupStepProps> = ({ moveToStep }) => {
  const { send, isLoading } = useSubmitOrchestrateForm();

  const { flow } = useParams();

  const handleSelect = (v: string, cb?: () => void) => {
    if (v) {
      send(
        'combined',
        [
          { key: 'mif_qa', value: [{ answer: v, question: 'weight_loss_goal' }] },
          {
            key: 'marketing',
            value: {
              'flow-source': 'patient-web',
              onboarding_url: window.location.href,
              prospect_flow: flow,
              prospect_initial_appt_type: DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE,
              prospect_type: 'Weight Management'
            }
          }
        ],
        () => {
          cb?.();
          moveToStep('next');
        },
        '',
        () => cb?.()
      );
    }
  };

  return <WeightLossGoalPicker loading={isLoading} onSelect={handleSelect} />;
};

export default WeightLossGoal;
