import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { OptionProps } from '@thecvlb/design-system/lib/src/common/Select';
import dayjs from 'dayjs';

import { useGetAppointmentTypesQuery } from 'services/lookup/lookup';
import { useGetPrescriptionsQuery } from 'services/patientChart/patientChart';
import { useCreateRenewalMutation } from 'services/wm/wm';

import { selectPatientChart, selectUser } from 'store';

import AskToUpgradeToTheWM from 'modals/AskToUpgradeToTheWM';
import RenewalPending from 'modals/RenewalPending';

import {
  DEFAULT_APPOINTMENT_TYPE,
  DEFAULT_HRT_APPOINTMENT_TYPE,
  DEFAULT_OPTAVIA_APPOINTMENT_TYPE,
  DEFAULT_WEIGHT_MANAGEMENT_TYPE
} from 'constants/defaults';
import { LINK_TO_FAQ } from 'constants/externalLinks';
import { useAppSelector, useQuery } from 'hooks';
import { TRIGGER_KEYWORDS } from 'utils/constants';
import { PathName, PlanCodes } from 'utils/enums';
import { findAppointmentTypeByData, handleRequestCatch } from 'utils/helpers';

import { AppointmentTypeItem } from 'models/appointment.types';

import { CategoryAndDetailsProps, DataType } from './categoryAndDetails.types';

const CategoryAndDetails: React.FC<CategoryAndDetailsProps> = ({
  appointmentTypeId,
  appointmentDescription,
  category,
  title,
  icon = 'lifeMD-care',
  hideCategory = false,
  hideLabels = false,
  detailsPlaceholder,
  onClickNext,
  src
}) => {
  const [isUpgradePopupOpen, toggleUpgradePopup] = useToggle(false);
  const [isPendingRenewalOpen, togglePendingRenewal] = useToggle(false);
  const [upgradePopupWasShown, toggleUpgradePopupWasShown] = useToggle(false);
  const [agreeNextStep, toggleAgreeNextStep] = useToggle(false);
  const [data, setData] = useState<DataType>({
    appointmentDescription,
    appointmentTypeId
  });
  const [step, showStep] = useState<'main' | 'wm-one' | 'wm-two' | ''>('');
  const navigate = useNavigate();
  const predefinedCategory = useQuery().get('c');

  const { activePlanCode, companyPartners, accessToken, hadOrHasWeightManagement } =
    useAppSelector(selectUser);
  const { prescriptions } = useAppSelector(selectPatientChart);

  const isWMPatient = activePlanCode === PlanCodes.WeightManagementMembership;
  const companyPartnerNames = companyPartners?.connectedPartners?.map((p) => p.partnerName);
  const isOptaviaPatient =
    companyPartnerNames?.some((p) => p.toLowerCase() === 'optavia') ||
    String(companyPartners?.onboardingPartner?.partnerName).toLowerCase().includes('optavia');
  const isHRTPatient = String(companyPartners?.onboardingPartner?.partnerName)
    .toLowerCase()
    .includes('hrt');

  const partner = isHRTPatient ? 'HRT' : isOptaviaPatient ? 'Optavia' : null;

  const { data: appointmentTypes } = useGetAppointmentTypesQuery(
    {
      ...(!!category && { category }),
      ...(!!partner && { partner })
    },
    {
      skip: category === null
    }
  );

  const [createRenewal, { isLoading: createRenewalIsLoading }] = useCreateRenewalMutation();

  useGetPrescriptionsQuery(undefined, { skip: !isWMPatient });

  const selectedAppointmentType = () =>
    appointmentTypes?.data.find((appointment) => appointment._id === data.appointmentTypeId);

  const checkboxLabels = ['Prescription Refills', 'Aches & Pains (Head, ear, back, Mouth etc.)'];
  const displayCheckbox = checkboxLabels.includes(selectedAppointmentType()?.displayName || '');
  const placeholder =
    detailsPlaceholder ||
    (selectedAppointmentType()?.displayName === 'Prescription Refills'
      ? 'What prescription are you looking to refill?'
      : 'What brings you in today?');

  const filterAppointmentTypes = (categories: AppointmentTypeItem[] = []): OptionProps[] => {
    // exclude HRT
    if (!isHRTPatient) {
      categories = categories.filter((el) => el.displayName !== DEFAULT_HRT_APPOINTMENT_TYPE);
    }
    // exclude WM
    if (!isWMPatient) {
      categories = categories.filter(
        (el) =>
          el.displayName !== 'Weight Management' && el.displayName !== 'Weight management follow-up'
      );
    }
    // exclude Optavia
    if (!isOptaviaPatient) {
      categories = categories.filter((el) => !el.displayName.toLowerCase().includes('optavia'));
    }
    return (
      categories.map((el) => ({
        label: el.displayName,
        value: el._id
      })) || []
    );
  };

  const options = filterAppointmentTypes(appointmentTypes?.data);

  const isDisabled = !data.appointmentTypeId || (displayCheckbox && !agreeNextStep);

  const handleOnClick = () => {
    if (!upgradePopupWasShown && !!accessToken && !isWMPatient) {
      const containsKeyword = (text: string) => {
        const regex = new RegExp(TRIGGER_KEYWORDS.join('|'), 'i');
        return regex.test(text);
      };
      if (containsKeyword(data.appointmentDescription)) {
        toggleUpgradePopup(true);
        return toggleUpgradePopupWasShown(true);
      }
    }
    const type = selectedAppointmentType();
    onClickNext({ ...data, displayName: type?.displayName, uploadRequired: type?.uploadRequired });
  };

  const handleChange = (fieldName: keyof DataType, value: string) => {
    setData({ ...data, [fieldName]: value });
  };

  const isTypeAppointment = (appointmentOption: AppointmentTypeItem) => {
    const type = isOptaviaPatient
      ? DEFAULT_OPTAVIA_APPOINTMENT_TYPE
      : isWMPatient
        ? DEFAULT_WEIGHT_MANAGEMENT_TYPE
        : DEFAULT_APPOINTMENT_TYPE;
    return appointmentOption.displayName.toLowerCase().trim() === type.trim().toLowerCase();
  };

  const handleUpdatedAppointmentTypes = () => {
    const types = appointmentTypes?.data;
    if (!types?.length) return;
    const selectedItem = selectedAppointmentType();
    if (selectedItem) {
      return handleChange('appointmentTypeId', selectedItem._id);
    }
    const predefinedItem = findAppointmentTypeByData(types, predefinedCategory);
    if (predefinedItem) {
      return handleChange('appointmentTypeId', predefinedItem._id);
    }
    const defaultItem = types.find(isTypeAppointment);
    if (defaultItem) {
      return handleChange('appointmentTypeId', defaultItem._id);
    }
  };

  const link = (
    <a className="text-base underline" href={LINK_TO_FAQ} target="_blank">
      FAQ
    </a>
  );

  const latestPrescription = [...prescriptions].sort((a, b) =>
    dayjs(b.createdDate).diff(a.createdDate)
  )?.[0];

  const handleSelectWMAnswer = (v: 'other' | 'wm') => {
    if (v === 'other') {
      const defaultType = options.find(
        (cat) => cat.label.toLowerCase().trim() === DEFAULT_APPOINTMENT_TYPE.trim().toLowerCase()
      );
      if (defaultType) {
        setData({ ...data, appointmentTypeId: String(defaultType.value) });
      }
      showStep('main');
    } else {
      if (isWMPatient) {
        const activePrescriptions = prescriptions.filter(
          (p) => p.hasOwnProperty('renewalSurvey') || p.hasOwnProperty('isRenewalRequestAllowed')
        );
        if (
          activePrescriptions.length === 0 ||
          (!latestPrescription?.isRenewalRequestAllowed && !latestPrescription.isRenewalCreated)
        ) {
          showStep('main');
        } else {
          showStep('wm-two');
        }
      } else {
        toggleUpgradePopup(true);
        toggleUpgradePopupWasShown(true);
      }
    }
  };

  const handleClickOnGLP1 = () => {
    if (!latestPrescription) {
      return showStep('main');
    }

    if (latestPrescription.renewalSurvey?.completedAt) {
      togglePendingRenewal(true);
    } else if (
      !latestPrescription?.isRenewalCreated &&
      latestPrescription.isRenewalRequestAllowed
    ) {
      createRenewal({ orderId: latestPrescription.orderId ?? '' })
        .unwrap()
        .then((res) => {
          navigate({
            pathname: PathName.PrescriptionStatus,
            search: `orderId=${res.data.orderId}&type=${res.data.type}&src=appointment`
          });
        })
        .catch(handleRequestCatch);
    } else {
      navigate(PathName.PrescriptionStatus + '?type=renewal&src=appointment');
    }
  };

  const content = {
    main: (
      <>
        {!hideCategory && (
          <div className="w-full">
            <Common.Select
              dataTestId="category_dropdown"
              label={hideLabels ? undefined : 'Category'}
              options={options}
              placeholder="Select..."
              value={options.find((e) => e.value === data.appointmentTypeId) as OptionProps}
              onChange={(e: OptionProps) => handleChange('appointmentTypeId', e.value as string)}
            />
          </div>
        )}
        <Common.TextArea
          className="w-full"
          dataTestId="details_textArea"
          label={hideLabels ? undefined : 'Details'}
          placeholder={placeholder}
          rows={3}
          value={data.appointmentDescription}
          onChange={(e) => handleChange('appointmentDescription', e.target.value)}
        />
        {displayCheckbox ? (
          <Common.Checkbox checked={agreeNextStep} onChange={toggleAgreeNextStep}>
            <span data-testid="alert_banner">
              I understand that LifeMD does not provide prescriptions or refills for controlled
              substances such as benzodiazepines, narcotics or amphetamines. Read more in our&nbsp;
              {link}.
            </span>
          </Common.Checkbox>
        ) : (
          <Common.Alert type="info" colorableBackground>
            {isWMPatient ? (
              <>
                <span className="mb-2 block">
                  Looking to renew your prescription? You can do this automatically from your
                  <b> Prescriptions</b> page.
                </span>
                <Common.Button
                  color="white-alt"
                  size="sm"
                  onClick={() => navigate(PathName.Prescriptions + '?tab=Prescriptions')}
                >
                  View Prescriptions
                </Common.Button>
              </>
            ) : (
              <span className="text-base">
                LifeMD does not provide prescriptions or refills for controlled substances such as
                benzodiazepines, narcotics or amphetamines. Read more in our&nbsp;
                {link}.
              </span>
            )}
          </Common.Alert>
        )}
        <Common.Button
          className="mt-auto"
          color="blue"
          dataTestId="continue_btn"
          disabled={isDisabled}
          fullWidthOnMobile
          onClick={handleOnClick}
        >
          Continue
        </Common.Button>
      </>
    ),
    'wm-one': (
      <div className="flex w-full flex-col gap-4">
        <h3 className="text-center">Please select an option:</h3>
        <Common.RichRadioButton
          checked={false}
          color="blue"
          label={
            <div className="flex items-center gap-2">
              <Common.Icon className="size-9 text-primary" name="scale-outline" />
              <div className="flex flex-col gap-0.5">
                <h3 className="text-mBase font-semibold">Weight management</h3>
                <span className="text-mSm text-gray">
                  GLP-1 prescription renewals or other weight management-related appointment.
                </span>
              </div>
            </div>
          }
          size="sm"
          value="wm"
          onChange={() => handleSelectWMAnswer('wm')}
        />
        <Common.RichRadioButton
          checked={false}
          color="blue"
          label={
            <div className="flex items-center gap-2">
              <Common.Icon className="size-9 text-primary" name="stethoscope" />
              <div className="flex flex-col gap-0.5">
                <h3 className="text-mBase font-semibold">Other appointment type</h3>
                <span className="text-mSm text-gray">
                  Urgent care or other appointments not related to weight management.
                </span>
              </div>
            </div>
          }
          size="sm"
          value="other"
          onChange={() => handleSelectWMAnswer('other')}
        />
      </div>
    ),
    'wm-two': (
      <div className="flex w-full flex-col gap-4">
        <h3 className="text-center">
          What weight management issue would you like to discuss with your provider?
        </h3>
        <Common.RichRadioButton
          checked={false}
          color="blue"
          disabled={createRenewalIsLoading}
          label="GLP-1 prescription renewals"
          value="glp-1"
          onChange={handleClickOnGLP1}
        />
        <Common.RichRadioButton
          checked={false}
          color="blue"
          disabled={createRenewalIsLoading}
          label="Another weight-related topic..."
          value="another"
          onChange={() => showStep('main')}
        />
      </div>
    )
  };

  const handleContinueWithNoWM = () => {
    if (hadOrHasWeightManagement && step !== 'main') {
      showStep('main');
    } else {
      handleOnClick();
    }
  };

  const showExtendedFlow =
    src !== 'signup' && !isOptaviaPatient && (isWMPatient || hadOrHasWeightManagement);

  useEffect(handleUpdatedAppointmentTypes, [appointmentTypes]);

  useEffect(() => {
    showStep(showExtendedFlow ? 'wm-one' : 'main');
  }, []);

  return (
    <>
      {!!accessToken && (
        <AskToUpgradeToTheWM
          alertContent={
            <>
              <b>Please upgrade your plan</b> to book a weight management appointment.
            </>
          }
          isOpen={isUpgradePopupOpen}
          upgradeFlow="upgrade-plan"
          onClose={toggleUpgradePopup}
          onContinueWithNoUpgrade={handleContinueWithNoWM}
          onReopen={() => toggleUpgradePopup(true)}
        />
      )}

      <RenewalPending isOpen={isPendingRenewalOpen} onClose={() => togglePendingRenewal(false)} />
      <div className="flex h-full grow flex-col items-center gap-4 md:gap-6">
        <Common.Illustration name={icon} />
        <h1
          className="text-center text-mXl font-bold text-primary-700 md:text-2xl"
          data-testid="select_category_step_title"
        >
          {title}
        </h1>
        {step && content[step]}
      </div>
    </>
  );
};

export default CategoryAndDetails;
