import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useDebounce, useNetworkState, usePermission, useToggle } from 'react-use';
import {
  connect,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalAudioTrackPublication,
  LocalParticipant,
  LocalVideoTrack,
  LocalVideoTrackPublication,
  RemoteParticipant,
  Room as TwilioRoom
} from 'twilio-video';
import { Room } from 'twilio-video/tsdef/Room';
import { CancelablePromise } from 'twilio-video/tsdef/types';

import { useQuery } from 'hooks';
import { PathName } from 'utils/enums';

import { VideoCallContextProps } from './videoCallContext.types';

const defaultValue: VideoCallContextProps = {
  audioEnabled: true,
  cameraEnabled: true,
  connectionLost: false,
  handleConnect: () => ({}) as CancelablePromise<Room>,
  handleSetAudioTrack: () => {},
  isDenied: false,
  isLobby: true,
  isOpenSettings: false,
  localNetworkQualityLevel: 5,
  localParticipant: null,
  participantDisconnected: false,
  remoteParticipant: null,
  room: null,
  setRoom: () => {},
  toggleAudioEnabled: () => {},
  toggleIsOpenSettings: () => {},
  toggleVideoEnabled: () => {},
  videoEnabled: true
};

export const VideoCallContext = createContext<VideoCallContextProps>(defaultValue);

export const VideoCallProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const isReschedule = !!useQuery().get('reschedule');
  const [room, setRoom] = useState<TwilioRoom | null>(defaultValue.room);
  const [localParticipant, setLocalParticipant] = useState<LocalParticipant | null>(
    defaultValue.localParticipant
  );
  const [remoteParticipant, setRemoteParticipant] = useState<RemoteParticipant | null>(
    defaultValue.remoteParticipant
  );
  const [localNetworkQualityLevel, setLocalNetworkQualityLevel] = useState<number>(
    defaultValue.localNetworkQualityLevel
  );
  const [participantDisconnected, setParticipantDisconnected] = useState<boolean>(
    defaultValue.participantDisconnected
  );
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | undefined>();
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [isLobby, toggleIsLobby] = useToggle(defaultValue.isLobby);
  const [isOpenSettings, toggleIsOpenSettings] = useToggle(defaultValue.isOpenSettings);
  const [audioEnabled, toggleAudioEnabled] = useToggle(defaultValue.audioEnabled);
  const [videoEnabled, toggleVideoEnabled] = useToggle(defaultValue.videoEnabled);
  const [cameraEnabled, toggleCameraEnabled] = useToggle(defaultValue.cameraEnabled);
  const [connectionLost, toggleConnectionLost] = useToggle(defaultValue.connectionLost);
  const { online } = useNetworkState();

  const state = !!navigator.permissions ? usePermission({ name: 'camera' }) : '';
  const isDenied = state === 'denied';

  const handleConnect = (token: string, video: boolean) => {
    if (!video) {
      toggleVideoEnabled(video);
    }
    return connect(token, {
      audio: true,
      networkQuality: { local: 3 },
      video
    });
  };

  const handleParticipantDisconnected = () => {
    setParticipantDisconnected(true);
    setRemoteParticipant(null);
  };

  /** Set local and remote participant */
  const handleUpdatedRoom = () => {
    if (room) {
      /** Listen to the "beforeunload" event on window to leave the Room when the tab/browser is being closed. */
      window.addEventListener('beforeunload', () => room.disconnect());

      /** iOS Safari does not emit the "beforeunload" event on window. Use "pagehide" instead. */
      window.addEventListener('pagehide', () => room.disconnect());

      room.on('participantConnected', setRemoteParticipant);
      room.on('participantDisconnected', handleParticipantDisconnected);

      room.participants.forEach(setRemoteParticipant);
      setLocalParticipant(room.localParticipant);
      toggleIsLobby();
    }
    return () => {
      room?.removeAllListeners();
      room?.disconnect();
    };
  };

  const handleUpdatedOnline = () => {
    setLocalNetworkQualityLevel(online ? defaultValue.localNetworkQualityLevel : 0);
  };

  const handleUpdatedRemoteParticipant = () => {
    return () => {
      remoteParticipant?.removeAllListeners();
    };
  };

  const filterTracks = (
    tracks: Map<string, LocalAudioTrackPublication> | Map<string, LocalVideoTrackPublication>
  ) => {
    return [...tracks.values()]
      .map((publication) => publication.track)
      .filter((track) => track !== null)[0];
  };
  const stopTrack = (track?: LocalAudioTrack | LocalVideoTrack) => {
    track?.disable();
    if (track instanceof LocalVideoTrack) {
      track?.stop();
    }
  };
  const handleSetVideoTrack = (videoTracks: LocalVideoTrack) => {
    setVideoTrack((prev) => {
      stopTrack(prev);
      if (!videoEnabled) {
        stopTrack(videoTracks);
      }
      return videoTracks;
    });
  };

  const handleSetAudioTrack = (audioTracks: LocalAudioTrack) => {
    setAudioTrack((prev) => {
      stopTrack(prev);
      if (!audioEnabled) {
        stopTrack(audioTracks);
      }
      return audioTracks;
    });
  };

  /** Set video and audio tracks */
  const handleUpdatedLocalParticipant = () => {
    if (localParticipant) {
      localParticipant.on('networkQualityLevelChanged', setLocalNetworkQualityLevel);
      handleSetVideoTrack(filterTracks(localParticipant.videoTracks) as LocalVideoTrack);
      handleSetAudioTrack(filterTracks(localParticipant.audioTracks) as LocalAudioTrack);
    }
    return () => {
      localParticipant?.removeAllListeners();
    };
  };

  const startTrack = (track?: LocalAudioTrack | LocalVideoTrack) => {
    // eslint-disable-next-line no-console
    track?.restart().catch((err) => console.log(err));
    track?.enable();
  };

  const handleUpdatedVideoTracks = () => {
    if (!cameraEnabled) {
      return;
    }
    videoEnabled ? startTrack(videoTrack) : stopTrack(videoTrack);
    return () => {
      stopTrack(videoTrack);
    };
  };

  const handleUpdatedAudioTracks = () => {
    audioEnabled ? startTrack(audioTrack) : stopTrack(audioTrack);
    return () => {
      stopTrack(audioTrack);
    };
  };

  const handleVideoCreateTrack = () => {
    if (!videoEnabled || isDenied || !cameraEnabled) {
      return;
    }
    if (!videoTrack) {
      createLocalVideoTrack()
        .then((track) => {
          setVideoTrack(track);
          if (window.location.pathname !== PathName.Call) {
            track.stop();
          }
          toggleVideoEnabled(true);
          toggleCameraEnabled(true);
        })
        .catch(() => {
          toggleVideoEnabled(false);
          toggleCameraEnabled(false);
        });
    }

    return () => {
      videoTrack?.stop();
    };
  };

  const handleUpdatedLocalNetworkQualityLevel = () => {
    if (localNetworkQualityLevel < 1) {
      const timerFunc = setTimeout(toggleConnectionLost, 20000);
      return () => {
        clearTimeout(timerFunc);
        toggleConnectionLost(false);
      };
    }
  };

  useEffect(handleVideoCreateTrack, [isDenied, videoTrack, videoEnabled, cameraEnabled]);

  useDebounce(handleUpdatedVideoTracks, 500, [videoEnabled, videoTrack, cameraEnabled]);

  useDebounce(handleUpdatedAudioTracks, 500, [audioEnabled, audioTrack]);

  useEffect(handleUpdatedRemoteParticipant, [remoteParticipant]);

  useEffect(handleUpdatedLocalParticipant, [localParticipant]);

  useEffect(handleUpdatedRoom, [room]);

  useEffect(handleUpdatedOnline, [online, localParticipant]);

  useEffect(() => {
    if (isReschedule) {
      stopTrack(videoTrack);
      stopTrack(audioTrack);
    }
  }, [isReschedule]);

  useEffect(handleUpdatedLocalNetworkQualityLevel, [localNetworkQualityLevel]);

  return (
    <VideoCallContext.Provider
      value={{
        audioEnabled,
        audioTrack,
        cameraEnabled,
        connectionLost,
        handleConnect,
        handleSetAudioTrack,
        isDenied,
        isLobby,
        isOpenSettings,
        localNetworkQualityLevel,
        localParticipant,
        participantDisconnected,
        remoteParticipant,
        room,
        setRoom,
        toggleAudioEnabled,
        toggleIsOpenSettings,
        toggleVideoEnabled,
        videoEnabled,
        videoTrack
      }}
    >
      {children}
    </VideoCallContext.Provider>
  );
};
