import { useRef, useState } from 'react';
import classNames from 'classnames';
import { motion, useAnimationFrame } from 'framer-motion';

import ReviewItem from 'features/ReviewItem';
import { ReviewItemProps } from 'features/ReviewItem/reviewItem.types';
import TrustPilotCard from 'widgets/signUp/TrustPilotCard';

import useWidth from 'hooks/useWidth';
import { divideOnTwoParts } from 'utils/helpers';

interface ReviewsListProps {
  className?: string;
  reviewsList: ReviewItemProps[];
}

const getTranslateX = (ref: null | HTMLDivElement) => {
  if (!ref) {
    return 0;
  }
  const style = window.getComputedStyle(ref);
  const matrix = new WebKitCSSMatrix(style.transform);
  return matrix.m41;
};

const ReviewsList: React.FC<ReviewsListProps> = ({
  reviewsList,
  className = 'py-16 max-md:mb-8'
}) => {
  const { isMobile } = useWidth();
  const { firstHalf, secondHalf } = divideOnTwoParts(reviewsList);
  const [firstPart, setFirstPart] = useState([
    ...firstHalf,
    ...secondHalf,
    ...firstHalf,
    ...secondHalf
  ]);
  const [secondPart, setSecondPart] = useState<ReviewItemProps[]>([
    ...secondHalf,
    ...firstHalf,
    ...secondHalf,
    ...firstHalf
  ]);

  const refUpperLine = useRef<null | HTMLDivElement>(null);
  const refBottomLine = useRef<null | HTMLDivElement>(null);

  // block review width
  const blockWidth = isMobile ? 220 : 400;
  useAnimationFrame(() => {
    if (refUpperLine.current) {
      const diff = getTranslateX(refUpperLine.current);
      refUpperLine.current.style.transform = `translateX(${diff - 0.1}px)`;
      if ((firstPart.length - 5) * blockWidth < Math.abs(diff)) {
        setFirstPart([...firstPart, ...firstPart]);
      }
    }
    if (refBottomLine.current && !isMobile) {
      const diff = getTranslateX(refBottomLine.current);
      refBottomLine.current.style.transform = `translateX(${diff - 0.15}px)`;
      if ((secondPart.length - 5) * blockWidth < Math.abs(diff)) {
        setSecondPart([...secondPart, ...secondPart]);
      }
    }
  });

  return (
    <div className={classNames('reviews-wrapper', className)}>
      <h1 className="mb-10 text-center text-2xl font-bold text-secondary-800 max-md:mx-auto max-md:max-w-[260px]">
        What LifeMD patients are saying
      </h1>
      <div className="flex w-full flex-col gap-4 overflow-hidden">
        <motion.div
          className="flex cursor-pointer flex-nowrap items-center gap-4"
          drag="x"
          dragConstraints={{ right: 50 }}
          dragTransition={{ power: 0.1, timeConstant: 1000 }}
          ref={refUpperLine}
        >
          {firstPart.map((r, i) => (
            <ReviewItem key={`${r.text} ${i}`} {...r} />
          ))}
        </motion.div>
        {!isMobile && (
          <motion.div
            className="flex cursor-pointer flex-nowrap items-center gap-4"
            drag="x"
            dragConstraints={{ right: 50 }}
            dragTransition={{ power: 0.1, timeConstant: 1000 }}
            ref={refBottomLine}
          >
            {secondPart.map((r, i) => (
              <ReviewItem key={`${r.text} ${i}`} {...r} />
            ))}
          </motion.div>
        )}
      </div>
      <div className="mt-10 flex items-center justify-center gap-4">
        <TrustPilotCard />
      </div>
    </div>
  );
};

export default ReviewsList;
