import { useToggle } from 'react-use';
import dayjs from 'dayjs';

import { selectUser } from 'store';

import { useAppSelector } from './index';

export const useExpiredCard = (paymentProfileId?: string | null) => {
  const user = useAppSelector(selectUser);
  const [isOpenEditPayment, toggleIsOpenEditPayment] = useToggle(false);

  const cardInfo = user.paymentProfiles?.find((method) =>
    paymentProfileId ? method.chargifyPaymentProfileId == paymentProfileId : method.isDefault
  );
  const cardExpirationDate = cardInfo?.expirationMonth + '/' + cardInfo?.expirationYear;
  const daysUntilCardExpiration = dayjs(cardExpirationDate, 'M/YYYY')
    .endOf('month')
    .diff(dayjs(), 'days');

  return {
    cardInfo,
    isExpired: daysUntilCardExpiration <= 0,
    cardExpirationDate,
    daysUntilCardExpiration,
    isOpenEditPayment,
    toggleIsOpenEditPayment
  };
};
