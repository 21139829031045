import { useEffect } from 'react';

import { useLazyGetLatestHealthMetricsQuery } from 'services/healthMetrics/healthMetrics';

import { selectUser } from 'store';

import BloodPressure from 'widgets/myChart/HealthMetrics/BloodPressure';
import BodyMassIndex from 'widgets/myChart/HealthMetrics/BodyMassIndex';
import Cholesterol from 'widgets/myChart/HealthMetrics/Cholesterol';
import Height from 'widgets/myChart/HealthMetrics/Height';
import Waist from 'widgets/myChart/HealthMetrics/Waist';
import Weight from 'widgets/myChart/HealthMetrics/Weight';

import { useAppSelector } from 'hooks';

const HealthMetrics: React.FC = () => {
  const { isWMDevices } = useAppSelector(selectUser);
  const [getLatestHealthMetrics] = useLazyGetLatestHealthMetricsQuery();

  useEffect(() => {
    getLatestHealthMetrics();
  }, []);

  const headerText = (title: string, subheader?: string) => {
    return (
      <div>
        <h2 className="text-mBase font-bold">{title}</h2>
        {subheader && <p className="mt-1 text-mSm text-gray">{subheader}</p>}
      </div>
    );
  };

  return (
    <>
      <h2 className="hidden text-2xl font-bold md:block">Health metrics</h2>
      <div className="flex flex-col gap-4 md:mt-2">
        {isWMDevices && headerText('Digital scale', 'Data not editable in app.')}
        <Weight onUpdate={getLatestHealthMetrics} />
      </div>
      <div className="mt-4 flex flex-col gap-4 md:mt-2">
        {isWMDevices && headerText('Blood pressure monitor', 'Data not editable in app.')}
        <BloodPressure onUpdate={getLatestHealthMetrics} />
      </div>
      <div className="mt-4 flex flex-col gap-4 md:mt-2">
        {headerText('Profile metrics')}
        <Height onUpdate={getLatestHealthMetrics} />
        <BodyMassIndex />
        <Cholesterol onUpdate={getLatestHealthMetrics} />
        <Waist onUpdate={getLatestHealthMetrics} />
      </div>
    </>
  );
};

export default HealthMetrics;
