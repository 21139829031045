// import { Common } from '@thecvlb/design-system';

import AdvantageItem from 'features/AdvantageItem';

import { TRIPLE_THERAPY_PLAN_NAME } from 'constants/defaults';
import useWeightManagement from 'hooks/useWeightManagement';

import { PlanDetailsProps } from './planDetails.types';

const PlanDetails: React.FC<PlanDetailsProps> = ({ currentPlan, activePP }) => {
  const { isTTPatient } = useWeightManagement();
  const priceInfo = () =>
    activePP.paymentInterval.qty === 1 && activePP.paymentInterval.units === 'months'
      ? `${activePP.totalCost}/mo`
      : `${activePP.totalCost}/every ${activePP.paymentInterval.qty} mo`;

  return (
    <div className="rounded-xl border border-gray-200 bg-white p-4 md:bg-gray-50">
      <div className="flex items-center justify-between">
        <h2 className="font-bold" data-testid="current_plan">
          {isTTPatient ? TRIPLE_THERAPY_PLAN_NAME : currentPlan.planName}
        </h2>
        <h2 className="font-bold" data-testid="plan_price">
          ${priceInfo()}
        </h2>
      </div>
      <div className="my-4 h-px w-full bg-gray-200" />
      <div className="mb-4 flex flex-col gap-2">
        {(activePP.additionalInfo ?? currentPlan.additionalInfo)
          .filter((v) => v.value)
          .map((advantage) => (
            <AdvantageItem
              advantage={advantage}
              className="text-base"
              key={advantage.question}
              showCustomIcon
            />
          ))}
      </div>
      {/* <Common.Button color="blue" dataTestId="change_plan" fullWidthOnMobile onClick={onChangePlan}>
        Change plan
      </Common.Button> */}
    </div>
  );
};

export default PlanDetails;
