import { Common } from '@thecvlb/design-system';

const BackToMainWebsiteButton: React.FC<{ loading?: boolean; onClick: () => void }> = ({
  onClick,
  loading = false
}) => {
  return (
    <Common.Button
      className="max-md:mt-auto md:mx-auto"
      color="white-alt"
      disabled={loading}
      fullWidthOnMobile
      onClick={onClick}
    >
      Back to LifeMD website
    </Common.Button>
  );
};

export default BackToMainWebsiteButton;
