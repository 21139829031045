import { ValidCouponCodes } from 'store/signup/signup.types';

import { PricePoint } from 'models/plans.types';

import { PaymentCardDataProps } from './checkout.types';

export const getDataForPayment = (
  pp: PricePoint,
  couponCode?: ValidCouponCodes | ''
): PaymentCardDataProps => {
  const returnData: PaymentCardDataProps = {
    discountAmount: '',
    discountPercentage: '',
    ppPrice: pp.totalCost,
    ppSubheader: '',
    ppTitle: ''
  };
  switch (pp.paymentInterval.qty) {
    case 1:
      returnData.ppTitle = '1 Month GLP-1 Program';
      returnData.ppSubheader = 'Billed monthly';
      break;
    case 3:
      returnData.ppTitle = '3 Month GLP-1 Program';
      returnData.ppSubheader = `Save $${(Number(pp.totalCost) * parseFloat('25%')) / 100}`;
      break;
    case 6:
      returnData.ppTitle = '6 Month GLP-1 Program';
      returnData.ppSubheader = `Save $${(Number(pp.totalCost) * parseFloat('50%')) / 100}`;
      break;
    default:
      break;
  }
  switch (couponCode) {
    case 'LFMD30OFF':
      returnData.ppTitle = 'Triple Therapy Program';
      returnData.discountAmount = '30.00';
      break;
    case 'GLP250OFF':
      returnData.discountPercentage = '25%';
      break;
    case 'GLP150OFF':
      returnData.discountPercentage = '50%';
      break;
    default:
      break;
  }

  switch (returnData.discountPercentage) {
    case '25%':
      returnData.discountAmount = `${(Number(pp.totalCost) * 0.25).toFixed(2)}`;
      break;
    case '50%':
      returnData.discountAmount = `${(Number(pp.totalCost) * 0.5).toFixed(2)}`;
      break;

    default:
      break;
  }
  return returnData;
};
