import dayjs from 'dayjs';

import { PRESCRIPTION_INSTRUCTIONS_URL } from 'constants/externalLinks';
import { DateFormat } from 'utils/enums';

import { ElationPrescriptionItem } from 'models/prescriptions.types';

export const handleClickInstructions = () => {
  window.open(PRESCRIPTION_INSTRUCTIONS_URL, '_blank');
};

export const handleDatePhrase = (prescriptionData: ElationPrescriptionItem, format: DateFormat) => {
  const orderedDate =
    prescriptionData?.orderedDate ||
    prescriptionData?.createdDate ||
    prescriptionData?.created_date ||
    false;
  if (orderedDate) {
    return `Ordered ${dayjs(orderedDate).format(format)}`;
  }
  return 'Ordered';
};
