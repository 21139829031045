import { createApi } from '@reduxjs/toolkit/query/react';

import { ResponseProps } from 'services/services.types';

import { baseQueryWithReauth } from 'utils/services';

import { DocumentItem } from 'models/document.types';

import {
  GenerateDynamicLinkQueueReqProps,
  GenerateDynamicLinkResProps,
  GetSignableDocumentsReqProps,
  GetSignableDocumentsResProps,
  GetTokenResProps,
  SignDocumentReqProps,
  SignDocumentResProps,
  VerifyIdentityCrosscheckResProps,
  VerifyIdentityReqProps
} from './general.types';

// This is for any not related requests
export const generalApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    generateDynamicLinkQueue: build.mutation<
      GenerateDynamicLinkResProps,
      GenerateDynamicLinkQueueReqProps
    >({
      query: ({ accessToken, ...body }) => ({
        body,
        headers: {
          Authorization: accessToken
        },
        method: 'POST',
        url: '/dynamic-links'
      })
    }),
    getTokenForCancel: build.mutation<GetTokenResProps, void>({
      query: () => ({
        method: 'POST',
        url: '/typeform/cancel-subscription'
      })
    }),
    verifyIdentity: build.mutation<
      ResponseProps<NonNullable<DocumentItem['identity']>>,
      VerifyIdentityReqProps
    >({
      query: ({ accessToken, ...body }) => ({
        headers: {
          ...(accessToken && { Authorization: accessToken })
        },
        body,
        method: 'POST',
        url: '/verify-identity'
      })
    }),
    verifyIdentityViaCrossCheck: build.mutation<VerifyIdentityCrosscheckResProps, void>({
      query: () => ({
        method: 'POST',
        url: '/verify-identity/crosscheck'
      })
    }),
    getSignableDocuments: build.query<GetSignableDocumentsResProps, GetSignableDocumentsReqProps>({
      query: ({ accessToken }) => ({
        headers: {
          ...(accessToken && { Authorization: accessToken })
        },
        method: 'GET',
        url: '/signable-documents'
      })
    }),
    signDocument: build.mutation<SignDocumentResProps, SignDocumentReqProps>({
      query: ({ accessToken, documentId }) => ({
        headers: {
          ...(accessToken && { Authorization: accessToken })
        },
        method: 'POST',
        url: `/sign-document/${documentId}`
      })
    })
  }),
  reducerPath: 'generalApi',
  tagTypes: ['General']
});

export const {
  useGenerateDynamicLinkQueueMutation,
  useGetTokenForCancelMutation,
  useGetSignableDocumentsQuery,
  useVerifyIdentityMutation,
  useVerifyIdentityViaCrossCheckMutation,
  useSignDocumentMutation
} = generalApi;
