import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import ConfirmDeleteInsurance from 'modals/ConfirmDeleteInsurance';

import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';

import { CurrentInsuranceProps } from './currentInsurance.types';

const CurrentInsurance: React.FC<CurrentInsuranceProps> = ({
  data: { updatedAt, fullName, memberId, rxBIN, rxGroupId, rxPCN, _id, rank },
  onEdit,
  onDelete,
  isAbleToEdit
}) => {
  const [isOpen, toggle] = useToggle(false);
  const [isLoading, setIsLoading] = useToggle(false);
  const { isMobile } = useWidth();

  const handleDelete = () => {
    setIsLoading(true);
    onDelete(_id).finally(() => {
      toggle(false);
      setIsLoading(false);
    });
  };

  return (
    <>
      <ConfirmDeleteInsurance
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={toggle}
        onConfirm={handleDelete}
      />
      <div className="flex w-full flex-col gap-4 border-primary-100 bg-primary-50 p-8">
        <div className="flex gap-2 max-md:flex-col md:justify-between">
          <h2 className="text-mLg font-bold md:text-lg" data-testid="header">
            {rank === 2 ? 'Secondary ' : ''} Insurance
          </h2>
          <div className="flex items-center gap-1 bg-primary-100 p-1 text-primary-600 md:gap-0.5">
            <Common.Icon name="repeat" />
            <span className="text-sm md:text-xs">
              Last updated: {dayjs(updatedAt).format(DateFormat.MMM_DD_YYYY)}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-sm" data-testid="full_name">
            Name: {fullName}
          </div>
          <div className="h-px w-full bg-black/10" />
          <div className="text-mSm md:text-sm">Id: {memberId}</div>
          <div className="h-px w-full bg-black/10" />
          <div className="flex items-center justify-between text-mSm md:text-sm">
            <span>RX BIN: {rxBIN}</span>
            <span>RX Group: {rxGroupId}</span>
            <span>PCN: {rxPCN}</span>
          </div>
          <div className="h-px w-full bg-black/10" />
        </div>
        {isAbleToEdit && (
          <div className="flex gap-2">
            <Common.Button color="blue" size={isMobile ? 'md' : 'sm'} onClick={onEdit}>
              Edit
            </Common.Button>
            <Common.Button color="white-alt" size={isMobile ? 'md' : 'sm'} onClick={toggle}>
              Delete
            </Common.Button>
          </div>
        )}
      </div>
    </>
  );
};

export default CurrentInsurance;
