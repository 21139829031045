import { ReducedSignupStepProps } from 'containers/SignUp/Content/content.types';
import HealthStatePicker from 'widgets/wmOnboarding/pickers/HealthStatePicker';

import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';

const HealthState: React.FC<ReducedSignupStepProps> = ({ moveToStep }) => {
  const { send, isLoading } = useSubmitOrchestrateForm();

  const handleSelect = (answer: string, cb?: () => void) => {
    send('mif_qa', [{ answer, question: 'best_describes_inputs' }], () => {
      moveToStep('next');
      cb?.();
    });
  };

  return <HealthStatePicker loading={isLoading} onSelect={handleSelect} />;
};

export default HealthState;
