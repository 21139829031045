import React, { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import { OptionProps } from '@thecvlb/design-system/lib/src/common/Select';

import { selectLookup, selectUser } from 'store';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';

import { useAppSelector, useFrontDesk, useQuery } from 'hooks';
import { MessageTab, PathName } from 'utils/enums';

import { CreateNewRequestFormValues } from './createNewRequest.types';

const CreateNewRequest = () => {
  const { userId } = useAppSelector(selectUser);
  const query = useQuery();
  const { frontDeskTypes } = useAppSelector(selectLookup);
  const { createChannel, frontDeskChannelCreated, channels } = useFrontDesk();
  const { control, handleSubmit, formState, reset, setValue } =
    useForm<CreateNewRequestFormValues>();
  const navigate = useNavigate();

  const options = frontDeskTypes
    .filter(
      (frontDeskType) =>
        !channels.filter((channel) => channel.frontDeskRequestTypeId === frontDeskType._id).length
    )
    .map((value) => ({
      label: value.requestType,
      value: value._id
    }));

  const { field, fieldState } = useController({
    control,
    defaultValue: query.get('dc') ?? '',
    name: 'frontDeskRequestTypeId',
    rules: {
      required: true
    }
  });

  const { field: MessageField, fieldState: MessageFieldState } = useController({
    control,
    defaultValue: query.get('dm') ?? '',
    name: 'message',
    rules: {
      required: true
    }
  });

  const onFormSubmit = (createChannelData: { frontDeskRequestTypeId: string; message: string }) => {
    createChannel({
      frontDeskRequestTypeId: createChannelData.frontDeskRequestTypeId,
      message: createChannelData.message,
      senderId: userId
    });
    reset();
    setValue('message', '');
  };

  useEffect(() => {
    frontDeskChannelCreated &&
      navigate(
        `${PathName.Messages}?tab=${MessageTab.FRONT_DESK}&chatId=${frontDeskChannelCreated.channelId}`
      );
  }, [frontDeskChannelCreated]);

  return (
    <FadeWrapper className="flex-1 overflow-auto">
      <form
        className="m-4 flex flex-col gap-4 rounded-xl bg-gray-100 p-4 md:mx-auto md:my-8 md:max-w-[387px] md:p-10"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Common.Select
          dataTestId="select_request_category"
          {...field}
          errors={fieldState.error}
          helper={fieldState.error?.message}
          label="New front desk request"
          options={options}
          placeholder="Select category..."
          value={
            (options.find(
              (state) => state.label === field.value || state.value === field.value
            ) as OptionProps) ?? field.value
          }
          onChange={(e) => field.onChange(e.value)}
        />
        <Common.TextArea
          dataTestId="new_request_textarea"
          {...MessageField}
          errors={MessageFieldState.error}
          helper={MessageFieldState.error?.message}
          placeholder="Enter your message..."
          rows={6}
        />
        <Common.Button
          className="mx-auto w-full justify-center"
          color="blue"
          dataTestId="create_new_request_submit"
          disabled={!formState.isValid}
        >
          Create new request
        </Common.Button>
        <Common.Alert
          className="whitespace-normal max-md:text-mSm"
          type="error"
          colorableBackground
          onClose={() => {}}
        >
          We are currently receiving a high volume of messages and will do our best to respond to
          you within 48 hours. For a more immediate response, please call us at (800) 852-1575. If
          you require urgent medical assistance, please call 911 or proceed to the nearest emergency
          room.
        </Common.Alert>
      </form>
    </FadeWrapper>
  );
};

export default CreateNewRequest;
