import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import {
  useAddHealthMetricsMutation,
  useUpdateHealthMetricsMutation
} from 'services/healthMetrics/healthMetrics';
import { SetHealthMetricsReqProps } from 'services/healthMetrics/healthMetrics.types';

import { DateFormat } from 'utils/enums';

const useSubmitMifParts = () => {
  const [addData, { isLoading: isAdding }] = useAddHealthMetricsMutation();
  const [updateData, { isLoading: isEditing }] = useUpdateHealthMetricsMutation();

  const sendMifPart = async (
    data: SetHealthMetricsReqProps & { accessToken: string },
    preselectedType?: 'ADD' | 'EDIT'
  ) => {
    let sentItems = Cookies.get('mifSentItems');
    const keyToUpdate = Object.keys(data.metrics)[0];
    const METHOD = preselectedType || (sentItems?.includes(keyToUpdate) ? 'EDIT' : 'ADD');
    const sendData = METHOD === 'ADD' ? addData : updateData;
    try {
      const res = await sendData({
        ...data,
        collectedDate: dayjs().format(DateFormat.YYYY_MM_DD)
      }).unwrap();
      if (!sentItems?.includes(keyToUpdate)) {
        sentItems = sentItems ? sentItems + keyToUpdate : keyToUpdate;
        Cookies.set('mifSentItems', sentItems, { expires: 7 });
      }
      return res;
    } catch (error) {
      const castedError = error as { data: { message: string } };
      if ('data' in castedError && castedError.data?.message.includes('already exists')) {
        const res = await updateData({
          ...data,
          collectedDate: dayjs().format(DateFormat.YYYY_MM_DD)
        }).unwrap();
        if (!sentItems?.includes(keyToUpdate)) {
          sentItems = sentItems ? sentItems + keyToUpdate : keyToUpdate;
          Cookies.set('mifSentItems', sentItems, { expires: 7 });
        }
        return res;
      } else {
        throw error;
      }
    }
  };

  return { isLoading: isAdding || isEditing, sendMifPart };
};

export default useSubmitMifParts;
