import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { MailingAddress } from 'models/user.types';

import { SuggestedAddressProps } from './suggestedAddress.types';

const SuggestedAddress: React.FC<SuggestedAddressProps> = ({
  isOpen,
  onClose,
  suggestedAddress,
  currentAddress,
  onConfirm,
  loading
}) => {
  const [selectedAddress, setSelectedAddress] = useState<'current' | 'suggested'>('current');

  const subheadingClassName = 'text-mSm mb-0.5 md:text-sm text-gray';

  const checkDifference = (field: keyof MailingAddress) => {
    if (!suggestedAddress) {
      return '';
    }
    const trimmedCurrentAddress = currentAddress[field].toLowerCase().replace(/ /g, '');
    const trimmedSuggestedAddress = suggestedAddress[field].toLowerCase().replace(/ /g, '');
    if (trimmedCurrentAddress !== trimmedSuggestedAddress) {
      return 'text-green';
    }
    return '';
  };

  const handleSelect = () => {
    if (selectedAddress === 'current') {
      onConfirm(currentAddress);
    } else {
      suggestedAddress && onConfirm(suggestedAddress);
    }
  };

  useEffect(() => {
    if (!!suggestedAddress) {
      setSelectedAddress('suggested');
    }
  }, []);

  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={false} size="base" persist>
      <div className="flex flex-col gap-6 p-4 md:p-6" data-testid="suggested_address_modal">
        <div className="text-gray-700">
          <h2 className="mb-2 text-xl font-bold">Unable to verify this address</h2>
          <p className="text-base">
            Using the address you entered could cause delays in receiving orders. Please select an
            option below.
          </p>
        </div>
        <div className="grid gap-3 px-px md:grid-cols-2 md:gap-4">
          <button
            className={classNames('p-0.5', { 'col-span-2': !suggestedAddress })}
            data-testid="entered_address"
            onClick={() => setSelectedAddress('current')}
          >
            <Common.RichRadioButton
              checked={selectedAddress === 'current'}
              className="h-full"
              color={!!suggestedAddress ? 'blue' : 'orange'}
              label={
                <div className="text-left">
                  <h4 className={subheadingClassName}>You entered</h4>
                  <span>{currentAddress.address}, </span>
                  <span>{currentAddress.city}, </span>
                  <span>{currentAddress.state}, </span>
                  <span>{currentAddress.zipCode}</span>
                </div>
              }
              onChange={(e) => e.preventDefault()}
            />
          </button>
          {!!suggestedAddress && (
            <button
              className="p-0.5"
              data-testid="suggested_address"
              onClick={() => setSelectedAddress('suggested')}
            >
              <Common.RichRadioButton
                checked={selectedAddress === 'suggested'}
                className="h-full"
                label={
                  <div className="text-left">
                    <h4 className={subheadingClassName}>Suggested address</h4>
                    <span className={checkDifference('address')}>{suggestedAddress.address}, </span>
                    <span className={checkDifference('city')}>{suggestedAddress.city}, </span>
                    <span className={checkDifference('state')}>{suggestedAddress.state}, </span>
                    <span className={checkDifference('zipCode')}>{suggestedAddress.zipCode}</span>
                  </div>
                }
                onChange={(e) => e.preventDefault()}
              />
            </button>
          )}
        </div>
        <div className="flex flex-col gap-3 md:flex-row-reverse md:gap-2">
          <Common.Button
            color="blue"
            dataTestId="choose_selected_btn"
            disabled={loading}
            fullWidthOnMobile
            onClick={handleSelect}
          >
            {!!suggestedAddress ? 'Choose selected' : 'Continue with this address'}
          </Common.Button>
          <Common.Button
            color="white-alt"
            dataTestId="edit_address_btn"
            disabled={loading}
            fullWidthOnMobile
            onClick={onClose}
          >
            Edit Address
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default SuggestedAddress;
