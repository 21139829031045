import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { selectOrchestrate } from 'store';

import { ReducedSignupStepProps, WmFlowType } from 'containers/SignUp/Content/content.types';
import CheckboxGroup from 'shared/CheckboxGroup';

import { useAppSelector, useQuery } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';

import BackToMainWebsiteButton from '../parts/BackToMainWebsiteButton';
import Heading from '../parts/Heading';
const ITEMS = [
  { label: 'IBS', value: 'ibs' },
  { label: 'Crohn’s disease', value: 'crohns_disease' },
  { label: 'Ulcerative colitis', value: 'ulcerative_colitis' },
  { label: 'Gastroparesis', value: 'gastroparesis' },
  { label: 'Suicidal ideation or attempt history', value: 'suicidal_ideation_or_attempt_history' },
  { label: 'None of the above', value: 'none' }
];

const MedicalConditions: React.FC<ReducedSignupStepProps & { flow?: WmFlowType }> = ({
  moveToStep,
  flow = 'onboarding'
}) => {
  const query = useQuery();
  const { mif_qa: mifQA } = useAppSelector(selectOrchestrate);
  const { send, isLoading } = useSubmitOrchestrateForm();
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const buildSearch = (originalSearch: string) =>
    `${flow === 'authorized' ? 's=wm-medical-conditions&' : ''}${originalSearch}`;

  const handleSubmit = () => {
    if (!selectedItems.includes('suicidal_ideation_or_attempt_history')) {
      send(
        'mif_qa',
        [
          { answer: selectedItems as unknown as string, question: 'autoimmune_or_other_conditions' }
        ],
        () => {
          moveToStep('next');
        }
      );
    } else {
      return navigate({ search: buildSearch(`hideProgress=true&subStep=medical-conditions`) });
    }
  };

  const isErrorState = query.get('subStep') === 'medical-conditions';

  const handleSelect = (v: number | string) => {
    let newArray: string[] = [];
    const stringValue = String(v);
    if (v === 'none') {
      if (!selectedItems.includes('none')) {
        newArray = [v];
      }
    } else {
      newArray = selectedItems.includes(stringValue)
        ? selectedItems.filter((i) => i !== stringValue && i !== 'none')
        : [...selectedItems, stringValue].filter((i) => i !== 'none');
    }

    setSelectedItems(newArray);
  };

  const handleProspect = () => {
    send(
      'mif_qa',
      [
        {
          answer: selectedItems as unknown as string,
          question: 'autoimmune_or_other_conditions'
        }
      ],
      () => {
        moveToStep(-1);
      },
      'PROSPECT',
      () => {
        moveToStep(-1);
      }
    );
  };

  useEffect(() => {
    const conditions = mifQA.find((v) => v.question === 'autoimmune_or_other_conditions');
    if (conditions) {
      setSelectedItems(conditions.answer as unknown as string[]);
    }
  }, []);

  return (
    <div className="flex h-full flex-col gap-6">
      {isErrorState ? (
        <>
          <h2 className="wm-signup-title text-center" data-testid="header">
            We regret to inform you that you are not suitable for GLP-1 medication.
          </h2>
          <Common.Alert type="warning" colorableBackground>
            Based upon your answers, you are not a suitable candidate for GLP-1 medication.
          </Common.Alert>
          <BackToMainWebsiteButton loading={isLoading} onClick={handleProspect} />
        </>
      ) : (
        <>
          <Heading
            category="Plan"
            subtitle="Have you had any of the following medical conditions?"
            title="Medical conditions"
          />
          <CheckboxGroup items={ITEMS} selectedItems={selectedItems} onSelect={handleSelect} />
          <Common.Button
            className="max-md:mt-auto md:mx-auto"
            color="blue"
            disabled={!selectedItems.length || isLoading}
            isLoading={isLoading}
            fullWidthOnMobile
            onClick={handleSubmit}
          >
            Continue
          </Common.Button>
        </>
      )}
    </div>
  );
};

export default MedicalConditions;
