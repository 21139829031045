import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import { AnimatePresence } from 'framer-motion';

import { selectOrchestrate } from 'store';

import { ReducedSignupStepProps, WmFlowType } from 'containers/SignUp/Content/content.types';
import NewWeightGraph from 'features/NewWeightGraph';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';

import { useAppSelector, useQuery } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';

import LearnMoreAboutBMILink from '../parts/LearnMoreAboutBMILink';
import PossibleWeightLoss from '../parts/PossibleWeightLoss';
import { getNewTargetWeight } from '../wm.settings';

const NewTargetWeight: React.FC<ReducedSignupStepProps & { flow?: WmFlowType }> = ({
  moveToStep,
  flow = 'onboarding'
}) => {
  const buildSearch = (originalSearch: string) =>
    `${flow === 'authorized' ? 's=wm-new-target-weight&' : ''}${originalSearch}`;

  const { send, isLoading } = useSubmitOrchestrateForm();
  const navigate = useNavigate();
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const { mif_qa: mifQA } = useAppSelector(selectOrchestrate);
  const query = useQuery();
  const step = query.get('step') ?? 'form';
  const handleSubmit = () => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const height = mifQA.find((v) => v.question === 'height')?.answer ?? 0;
    const weight = mifQA.find((v) => v.question === 'weight')?.answer ?? 0;
    const bmi = mifQA.find((q) => q.question === 'bmi_value')?.answer ?? 0;

    send(
      'mif',
      {
        bmi: {
          date: currentDate,
          value: Number(bmi)
        },
        height: {
          date: currentDate,
          value: Number(height)
        },
        weight: {
          date: currentDate,
          value: Number(weight)
        }
      },
      () => {
        navigate(
          { search: buildSearch('hideProgress=true&hideHeader=true&step=progress') },
          { replace: true }
        );
        timer.current = setTimeout(() => {
          navigate({ search: buildSearch('') }, { replace: true });
          moveToStep('next');
        }, 2700);
      }
    );
  };
  const currentWeight = Number(mifQA.find((q) => q.question === 'weight')?.answer) ?? null;
  const currentBMI = mifQA.find((q) => q.question === 'bmi_value')?.answer ?? null;
  const newTargetWeight = getNewTargetWeight(currentWeight);
  useEffect(() => {
    step === 'progress' && navigate({ search: buildSearch('') }, { replace: true });

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <AnimatePresence mode="wait">
      {step === 'form' ? (
        <FadeWrapper className="flex h-full flex-col place-items-center gap-3 md:gap-6" key="form">
          <div>
            <span className="wm-signup-subtitle mb-1 block">Plan</span>
            <h2 className="wm-signup-title" data-testid="header">
              With GLP-1 medication, you can lose up to 15% of your body weight in 6-12 months.
            </h2>
          </div>
          <NewWeightGraph weightPoints={[currentWeight, newTargetWeight]} />

          <div className="flex w-full flex-col place-items-center gap-3 rounded-lg bg-yellow-100 p-4">
            <div className="text-center text-orange">
              <p className="mb-2 font-semibold">Your new target weight:</p>
              <p className="text-m2xl font-bold md:text-2xl" data-testid="new_target_weight">
                {newTargetWeight} Pounds
              </p>
            </div>
            <p className="font-bold">Current BMI: {currentBMI}</p>
            <LearnMoreAboutBMILink />
          </div>
          <Common.Button
            className="max-md:mt-auto"
            color="blue"
            disabled={isLoading}
            isLoading={isLoading}
            fullWidthOnMobile
            onClick={handleSubmit}
          >
            Continue
          </Common.Button>
        </FadeWrapper>
      ) : (
        <FadeWrapper
          className="mt:mt-10 flex flex-col place-items-center p-6 max-md:mt-[25%]"
          key="progress"
        >
          <PossibleWeightLoss possibleWL={Math.round(currentWeight - Number(newTargetWeight))} />
        </FadeWrapper>
      )}
    </AnimatePresence>
  );
};

export default NewTargetWeight;
