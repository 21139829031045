import { useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import {
  useDeleteDocumentMutation,
  useUploadDocumentsMutation
} from 'services/documents/documents';
import { useLazyGetMyAccountQuery } from 'services/myAccount/myAccount';

import { selectUser } from 'store';

import DropContainer from 'features/DropContainer';
import { notifyError } from 'shared/Toast/Toast';

import { useAppSelector } from 'hooks';

import { BaseModalProps } from '../modal.types';

const requirements = [
  'The photo should be taken from the knees up',
  'You are the only person in the photo and your body is not obscured by anything in the photo',
  'You are wearing form-fitting clothing so the photo accurately portrays your body composition',
  'The photo is not blurry or dark',
  'Photo is taken within the last 5 days'
];

const UploadBodyPhoto: React.FC<BaseModalProps> = ({ isOpen, onClose }) => {
  const { bodyImage } = useAppSelector(selectUser);
  const [isLoading, setIsLoading] = useToggle(false);
  const [uploadingStatus, setUploadingStatus] = useState<'success' | 'error' | null>(null);
  const [uploadDocuments, { data }] = useUploadDocumentsMutation();
  const [deleteDocument] = useDeleteDocumentMutation();
  const [getMyAccount] = useLazyGetMyAccountQuery();

  const appendFileToFormData = (formData: FormData, file: File) => {
    formData.append('patientDocumentFile', file);
    formData.append('category', 'body-image');
    return formData;
  };

  const handleClick = async (files: FileList | null) => {
    if (!files) return;

    setIsLoading(true);
    setUploadingStatus(null);

    try {
      const documentId = data?.data._id || bodyImage?.documents[0]?._id;

      if (documentId) {
        await deleteDocument({ documentId });
      }

      await Promise.all(
        [...files].map((file) =>
          uploadDocuments({
            body: appendFileToFormData(new FormData(), file)
          }).unwrap()
        )
      );

      await getMyAccount().unwrap();

      onClose();
    } catch (error) {
      notifyError((error as Error).message || 'Error, please try again');
      setUploadingStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Common.Modal close={onClose} isOpen={isOpen} size="lg">
      <div className="mx-auto flex max-w-[336px] flex-col items-center gap-4">
        <Common.Icon
          className="size-20 rounded-full bg-primary-50 p-4 text-primary-400"
          name="accessibility"
        />
        <h2 className="text-2xl font-bold">Add body photo</h2>
        <div>
          <p className="text-center font-bold">Photo requirements</p>
          <ul className="mt-2 flex flex-col gap-2 text-sm">
            {requirements.map((requirement) => (
              <li className="flex items-center gap-1" key={requirement}>
                <Common.Icon className="size-4 flex-none" name="check" />
                {requirement}
              </li>
            ))}
          </ul>
        </div>
        <DropContainer
          className={`flex h-[140px] w-full cursor-pointer flex-col items-center justify-center gap-0.5 rounded-2xl border border-dashed p-6 ${uploadingStatus === 'error' ? 'border-none bg-red' : isLoading ? 'bg-gray-200' : 'border-gray'}`}
          multiple={false}
          onChange={handleClick}
        >
          <Common.Icon
            className={`text-gray ${isLoading ? 'animate-spin' : uploadingStatus === 'error' ? 'text-white' : ''}`}
            name={uploadingStatus === 'error' ? 'error-outline' : isLoading ? 'loader' : 'image'}
          />
          <p className={`text-sm ${uploadingStatus === 'error' ? 'text-white' : 'text-gray'}`}>
            {uploadingStatus === 'error' ? (
              'Upload failed'
            ) : isLoading ? (
              'Uploading...'
            ) : (
              <>
                Drag and drop file here or <u>Choose file</u>
              </>
            )}
          </p>
          {uploadingStatus === 'error' && (
            <Common.Button className="mt-1.5 text-white" color="white-alt" size="sm">
              Retry
            </Common.Button>
          )}
        </DropContainer>
      </div>
    </Common.Modal>
  );
};

export default UploadBodyPhoto;
