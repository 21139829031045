const Heading: React.FC<{
  category: string;
  subtitle?: string | JSX.Element;
  title: string | JSX.Element;
}> = ({ title, subtitle, category }) => {
  return (
    <div className="flex flex-col gap-2 text-primary-700 md:gap-1">
      <span className="wm-signup-subtitle block">{category}</span>
      <h2 className="wm-signup-title" data-testid="header">
        {title}
      </h2>
      {!!subtitle && typeof subtitle === 'string' ? <h3>{subtitle}</h3> : subtitle}
    </div>
  );
};

export default Heading;
