import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';

import { PathName } from 'utils/enums';

import { parseMessage } from './defaultMessage.settings';
import { DefaultMessageProps } from './defaultMessage.types';

const DefaultMessage: React.FC<DefaultMessageProps> = ({ self, message, combined }) => {
  const navigate = useNavigate();
  return (
    <>
      {(message.message || !!message.buttons.length) && (
        <div
          className={classNames(
            'w-fit rounded-xl px-5 py-3',
            self ? 'ml-6 bg-secondary-100' : 'mr-6 bg-gray-100',
            !combined && (self ? 'rounded-br-none' : 'rounded-bl-none')
          )}
        >
          <div className="break-word whitespace-pre-line text-gray-700">
            {HTMLReactParser(parseMessage(message?.message))}
          </div>
          {message.buttons.map(({ label }, index) => (
            <Common.Button
              className="mt-4"
              color="blue"
              key={index.toString()}
              preIcon="lifemd-reversed"
              style="pill"
              onClick={() => navigate(PathName.CreateAppointment)}
            >
              {label}
            </Common.Button>
          ))}
        </div>
      )}
    </>
  );
};

export default DefaultMessage;
