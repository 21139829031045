export const calculateBMI = (data: {
  heightFt: number | string;
  heightIn: number | string;
  weight: number | string;
}): string => {
  return (
    (Number(data.weight) / (Number(data.heightFt) * 12 + Number(data.heightIn)) ** 2) *
    703
  ).toFixed(2);
};

export const getNewTargetWeight = (currentWeight: number | string | null | boolean) =>
  currentWeight ? (Number(currentWeight) * 0.8).toFixed(0) : null;
