import { DayPicker, DayPickerProps } from 'react-day-picker';
import { Common } from '@thecvlb/design-system/lib/src';

import Loader from 'shared/Loader';

const Calendar: React.FC<{ loading?: boolean; selected?: Date } & Partial<DayPickerProps>> = ({
  onDayClick,
  selected,
  disabled,
  onMonthChange,
  startMonth,
  loading = false
}) => (
  <div className="relative mx-auto w-fit md:m-0" data-testid="date_picker">
    <div className="absolute -top-6 left-1/2">
      <Loader isVisible={loading} relative />
    </div>
    <DayPicker
      components={{
        Chevron: (props) => (
          <Common.Icon
            className="size-5 md:size-4"
            name={props.orientation === 'left' ? 'arrow-left' : `arrow-right`}
          />
        )
      }}
      defaultMonth={selected}
      disabled={disabled}
      disableNavigation={loading}
      mode="single"
      startMonth={startMonth}
      onDayClick={onDayClick}
      onMonthChange={onMonthChange}
    />
  </div>
);

export default Calendar;
