import { FlowTypes, PathName, PlanTypes, SignUpSteps } from 'utils/enums';

export const MIF_STEPS: SignUpSteps[] = [
  SignUpSteps.Medications,
  SignUpSteps.Allergies,
  SignUpSteps.HealthConditions,
  SignUpSteps.ChronicDiseases,
  SignUpSteps.WomensHealth,
  SignUpSteps.SocialHistory,
  SignUpSteps.MifSummary
];

export const B1_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.Intro,
  // SignUpSteps.AppointmentCategory,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  SignUpSteps.Confirmation
];

export const SPECIFIC_DRUG_PROVIDER_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  SignUpSteps.Confirmation
];

export const B1I_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.Intro,
  // SignUpSteps.AppointmentCategory,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  SignUpSteps.Confirmation
];

export const B1B_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.PlanSelect,
  SignUpSteps.Intro,
  // SignUpSteps.AppointmentCategory,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  SignUpSteps.Confirmation
];

export const B1X_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.Intro,
  // SignUpSteps.AppointmentCategory,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  // SignUpSteps.PreSymptomChecker,
  // SignUpSteps.SymptomsSelector,
  // SignUpSteps.SCRegions,
  // SignUpSteps.Interview,
  SignUpSteps.SCResults
];

export const B1N_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.AppointmentDetails,
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.PlanSelect,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  // SignUpSteps.PreSymptomChecker,
  // SignUpSteps.SymptomsSelector,
  // SignUpSteps.SCRegions,
  // SignUpSteps.Interview,
  SignUpSteps.SCResults
];

export const B1XC_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.Intro,
  // SignUpSteps.AppointmentCategory,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy
  // SignUpSteps.PreSymptomChecker,
  // SignUpSteps.SymptomsSelector,
  // SignUpSteps.SCRegions,
  // SignUpSteps.Interview,
  // SignUpSteps.SCResults
];

export const B2_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.ChooseProvider,
  SignUpSteps.PlanSelect,
  SignUpSteps.Payment,
  SignUpSteps.CreateNewUser,
  SignUpSteps.TwoOptionsFinish
];

export const B2C_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.ChooseProvider,
  SignUpSteps.PlanSelect,
  SignUpSteps.Payment,
  SignUpSteps.CreateNewUser,
  SignUpSteps.TwoOptionsFinish
];

export const B3_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.PlanSelect,
  SignUpSteps.ChooseProvider,
  SignUpSteps.Payment,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Pharmacy,
  SignUpSteps.Confirmation
];

export const B3C_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.PlanSelect,
  SignUpSteps.ChooseProvider,
  SignUpSteps.Payment,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Pharmacy,
  SignUpSteps.Confirmation
];

const Q1_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.Intro,
  SignUpSteps.InitialInfo,
  SignUpSteps.ChooseProvider,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  // SignUpSteps.PreSymptomChecker,
  // SignUpSteps.SymptomsSelector,
  // SignUpSteps.SCRegions,
  // SignUpSteps.Interview,
  // SignUpSteps.SCResults,
  SignUpSteps.SwitchToApp
];

const Q2_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.Intro,
  SignUpSteps.InitialInfo,
  SignUpSteps.ChooseProvider,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.CreateNewUser,
  SignUpSteps.PlanSelect,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  // SignUpSteps.PreSymptomChecker,
  // SignUpSteps.SymptomsSelector,
  // SignUpSteps.SCRegions,
  // SignUpSteps.Interview,
  SignUpSteps.SCResults,
  SignUpSteps.SwitchToApp
];

const I1_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.InitialInfo,
  SignUpSteps.ChooseProvider,
  SignUpSteps.AppointmentDetails,
  SignUpSteps.CreateNewUser,
  SignUpSteps.PaymentOptions,
  SignUpSteps.PlanSelect,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Pharmacy,
  // SignUpSteps.PreSymptomChecker,
  // SignUpSteps.SymptomsSelector,
  // SignUpSteps.SCRegions,
  // SignUpSteps.Interview,
  SignUpSteps.SCResults,
  SignUpSteps.SwitchToApp
];

export const HW_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Summary,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  // SignUpSteps.PreSymptomChecker,
  // SignUpSteps.SymptomsSelector,
  // SignUpSteps.SCRegions,
  // SignUpSteps.Interview,
  SignUpSteps.SCResults
];

export const HRT_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.ChooseProvider,
  SignUpSteps.CreateNewUser,
  SignUpSteps.Payment,
  SignUpSteps.PreConfirmation,
  ...MIF_STEPS,
  SignUpSteps.Confirmation
];

export const WEIGHT_MANAGEMENT_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingCompare,
  SignUpSteps.MarketingRatedNumberOne,
  SignUpSteps.PlanSelect,
  SignUpSteps.MarketingMoneyBackGuarantee,
  SignUpSteps.NoticeForCalifornia,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

export const WEIGHT_MANAGEMENT_EXTENDED_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.WMWeightLoseWithoutUncertainty,
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingCompare,
  SignUpSteps.MarketingRatedNumberOne,
  // TODO: uncomment in sprint 41
  // SignUpSteps.PreInsurance,
  // SignUpSteps.Insurance,
  // SignUpSteps.VerifyIdentity,
  SignUpSteps.PlanSelect,
  SignUpSteps.MarketingMoneyBackGuarantee,
  SignUpSteps.NoticeForCalifornia,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

export const WEIGHT_MANAGEMENT_ASYNC_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMKnowledgeAboutGLP1,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.WMCongrats,
  SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingLowerPrice,
  SignUpSteps.MarketingWorkingOnApproval,
  SignUpSteps.MarketingCompare,
  SignUpSteps.MarketingRatedNumberOne,
  SignUpSteps.ShouldUseInsurance,
  SignUpSteps.PlanSelect,
  SignUpSteps.NoticeForCalifornia,
  SignUpSteps.MarketingMoneyBackGuarantee,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

export const WEIGHT_MANAGEMENT_MEDICATIONS_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMKnowledgeAboutGLP1,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.WMCongrats,
  SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingLowerPrice,
  SignUpSteps.MarketingWorkingOnApproval,
  SignUpSteps.MarketingCompare,
  SignUpSteps.MarketingRatedNumberOne,
  SignUpSteps.ShouldUseInsurance,
  SignUpSteps.PlanSelect,
  SignUpSteps.NoticeForCalifornia,
  SignUpSteps.MarketingMoneyBackGuarantee,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

export const WEIGHT_MANAGEMENT_INSURANCE_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.WMWeightLoseWithoutUncertainty,
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMNewTargetWeight,
  SignUpSteps.InsuranceType,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  SignUpSteps.WMPancreatitisQuestion,
  SignUpSteps.WMThyroidNodulesQuestion,
  SignUpSteps.WMGallbladderQuestion,
  SignUpSteps.WMMedicalConditions,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  // SignUpSteps.YourWeightLossProgram,
  SignUpSteps.PreferredMedication,
  SignUpSteps.MarketingLowerPrice,
  SignUpSteps.MarketingWorkingOnApproval,
  SignUpSteps.MarketingCompare,
  // SignUpSteps.MarketingRatedNumberOne,
  // SignUpSteps.MarketingMoneyBackGuarantee,
  SignUpSteps.NoticeForCalifornia,
  SignUpSteps.MarketingRegularMeetings,
  SignUpSteps.WithOrWithoutInsurance,
  SignUpSteps.Insurance,
  SignUpSteps.CheckPrescriptionCoverage,
  SignUpSteps.PlanSelect,
  SignUpSteps.VerifyIdentity,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

export const TRIPLE_THERAPY_FLOW_STEPS: SignUpSteps[] = [
  SignUpSteps.WMWeightLoseAim,
  SignUpSteps.WMAge,
  SignUpSteps.WMUserHealthState,
  SignUpSteps.WMWhyTT,
  SignUpSteps.WMDiabetesQuestion,
  SignUpSteps.WMYouAreInGoodHands,
  SignUpSteps.WMHeightAndWeight,
  SignUpSteps.WMCongrats,
  SignUpSteps.WMBasicInfo,
  SignUpSteps.WMAddress,
  SignUpSteps.WMDOB,
  SignUpSteps.WMSexAtBirth,
  // SignUpSteps.WMPregnantTryingToBecomePregnant,
  SignUpSteps.WMWhatNext,
  // SignUpSteps.WMBloodPressure,
  SignUpSteps.WMKidneyStones,
  SignUpSteps.WMPriorSeizures,
  SignUpSteps.WMGlaucoma,
  SignUpSteps.WMMAOInhibitors,
  SignUpSteps.WMLiverOrKidneyImpairment,
  SignUpSteps.WMLacticAcidosis,
  SignUpSteps.WMBupropion,
  SignUpSteps.WMSideEffectsWithMetforminBupropion,
  SignUpSteps.WMKetogenicDiet,
  SignUpSteps.WMTTResults,
  SignUpSteps.WMLabWork,
  SignUpSteps.WMPhoneNumber,
  SignUpSteps.WMSetPassword,
  SignUpSteps.PlanSelect,
  SignUpSteps.WMScheduleAppointment,
  SignUpSteps.AppointmentConfirmation,
  SignUpSteps.WMCheckout,
  SignUpSteps.WMCompleteOnboarding
];

export const FLOW_TYPES: { [key in FlowTypes]: SignUpSteps[] } = {
  [FlowTypes.BasicFlow]: B1_FLOW_STEPS,
  [FlowTypes.BasicFlowInfermedica]: B1I_FLOW_STEPS,
  [FlowTypes.BasicFlowText]: B1B_FLOW_STEPS,
  [FlowTypes.BasicExtendedFlow]: B1X_FLOW_STEPS,
  [FlowTypes.BasicExtendedNewPlansFlow]: B1N_FLOW_STEPS,
  [FlowTypes.BasicExtendedDynamicFlow]: B1XC_FLOW_STEPS,
  [FlowTypes.ShortFlow]: B2_FLOW_STEPS,
  [FlowTypes.ShortFlowWithOptionalPopup]: B2C_FLOW_STEPS,
  [FlowTypes.MixedFlow]: B3_FLOW_STEPS,
  [FlowTypes.MixedFlowWithRequiredPopup]: B3C_FLOW_STEPS,
  [FlowTypes.LovazaFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.LovazaBFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.AcipHexFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.AcipHexBFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.AvodartFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.AvodartBFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.HumatinFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.HumatinBFlow]: SPECIFIC_DRUG_PROVIDER_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowAsync]: WEIGHT_MANAGEMENT_ASYNC_FLOW_STEPS,
  [FlowTypes.WeightManagementFlow]: WEIGHT_MANAGEMENT_FLOW_STEPS,
  [FlowTypes.WeightManagementSpecific]: WEIGHT_MANAGEMENT_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowExtended]: WEIGHT_MANAGEMENT_EXTENDED_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowOptavia]: WEIGHT_MANAGEMENT_EXTENDED_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowInsurance]: WEIGHT_MANAGEMENT_INSURANCE_FLOW_STEPS,
  [FlowTypes.WeightManagementFlowInsuranceMedications]: WEIGHT_MANAGEMENT_MEDICATIONS_FLOW_STEPS,
  [FlowTypes.QueueFlow]: Q1_FLOW_STEPS,
  [FlowTypes.QueueFlowWithNewPlans]: Q2_FLOW_STEPS,
  [FlowTypes.QueueFlowWithNewPlansAlt]: Q2_FLOW_STEPS,
  [FlowTypes.InsuranceFlow]: I1_FLOW_STEPS,
  [FlowTypes.InsuranceFlowAlt]: I1_FLOW_STEPS,
  [FlowTypes.HealthWarehouseFlow]: HW_FLOW_STEPS,
  [FlowTypes.HRTClubFlow]: HRT_FLOW_STEPS,
  [FlowTypes.TripleTherapy]: TRIPLE_THERAPY_FLOW_STEPS
};

export const SIGNUP_PATH = `${PathName.SignUp}/${PlanTypes.TotalCare}/${FlowTypes.QueueFlowWithNewPlansAlt}`;

export const SPECIFIC_DRUGS_FLOWS = [
  FlowTypes.LovazaFlow,
  FlowTypes.AcipHexFlow,
  FlowTypes.AvodartFlow,
  FlowTypes.HumatinFlow
];

export const SPECIFIC_DRUGS_ALT_FLOWS = [
  FlowTypes.LovazaBFlow,
  FlowTypes.AcipHexBFlow,
  FlowTypes.AvodartBFlow,
  FlowTypes.HumatinBFlow
];

export const SPECIFIC_APPOINTMENT_TYPES: { [key in FlowTypes]?: string } = {
  [FlowTypes.LovazaFlow]: 'Woodward - Lovaza',
  [FlowTypes.AcipHexFlow]: 'Woodward - AcipHex',
  [FlowTypes.AvodartFlow]: 'Woodward - Avodart',
  [FlowTypes.HumatinFlow]: 'Woodward - Humatin',
  [FlowTypes.LovazaBFlow]: 'Woodward - Lovaza',
  [FlowTypes.AcipHexBFlow]: 'Woodward - AcipHex',
  [FlowTypes.AvodartBFlow]: 'Woodward - Avodart',
  [FlowTypes.HumatinBFlow]: 'Woodward - Humatin'
};

export const WM_FLOWS = [
  FlowTypes.WeightManagementFlow,
  FlowTypes.WeightManagementFlowExtended,
  FlowTypes.WeightManagementFlowOptavia,
  FlowTypes.WeightManagementFlowInsurance,
  FlowTypes.WeightManagementFlowAsync,
  FlowTypes.WeightManagementFlowInsuranceMedications,
  FlowTypes.WeightManagementSpecific,
  FlowTypes.TripleTherapy
];

export const SPECIFIC_APPOINTMENT_CODES: { [key in FlowTypes]?: string } = {
  [FlowTypes.LovazaFlow]: 'WW-LOV-USA-0011',
  [FlowTypes.AcipHexFlow]: 'WW-ACI-USA-0032',
  [FlowTypes.AvodartFlow]: 'WW-AVO-USA-0006',
  [FlowTypes.HumatinFlow]: 'WT-HUM-USA-0005'
};

export const WM_VALID_ONBOARDING_PRICE_POINTS = [
  'wm-one-month-129',
  'wm-three-months-129',
  'wm-six-months-129'
  // 'wm-twelve-months-129'
];

export const WM_ONBOARDING_PRICE_POINTS_SPECIFIC_FLOW = ['wm-one-month-vip-29'];

// @TODO: remove 'one-month-tt' after migration on the BE
export const TRIPLE_THERAPY_PRICE_POINTS = ['wm-tt-one-month', 'one-month-tt'];

// to show one or few pp during onboarding
export const WM_INSURANCE_VALID_ONBOARDING_PRICE_POINTS = ['wm-insurance-one-month-0'];

// to define if authorized user is insurance user
export const WM_INSURANCE_VALID_PRICE_POINTS = [
  'wm-insurance-one-month',
  'wm-insurance-one-month-0'
];

export const SEMAGLUTIDE_PRICE_POINT = 'bundle-semaglutide-one-month';

export const BACK_TO_WM_PRICE_POINTS = ['wm-three-months-129', 'wm-six-months-129'];

export const STATES_WHERE_ASYNC_FLOW_IS_ENABLE = [
  'Arkansas',
  'Florida',
  'Georgia',
  'Illinois',
  'Michigan',
  'New York',
  'Ohio',
  'Pennsylvania',
  'Texas',
  'Virginia'
];

export const STATES_WHERE_ASH_KIT_IS_DISABLED = [
  'California',
  'New York',
  'New Jersey',
  'Rhode Island',
  'Tennessee'
];

export const STATES_WHERE_INSURANCE_IS_ENABLE = ['Illinois', 'Texas', 'Virginia'];
