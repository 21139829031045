import { lazy, ReactElement } from 'react';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import { selectUser } from 'store';

import CompleteAddress from 'pages/SignUp/regularFlows/CompleteAddress';
import Allergies from 'pages/SignUp/regularFlows/mifSteps/Allergies';
import ChronicDiseases from 'pages/SignUp/regularFlows/mifSteps/ChronicDiseases';
import HealthConditions from 'pages/SignUp/regularFlows/mifSteps/HealthConditions';
import Medications from 'pages/SignUp/regularFlows/mifSteps/Medications';
import MifSummary from 'pages/SignUp/regularFlows/mifSteps/MifSummary';
import Pharmacy from 'pages/SignUp/regularFlows/mifSteps/Pharmacy';
import SocialHistory from 'pages/SignUp/regularFlows/mifSteps/SocialHistory';

import { MoveStepFunction } from 'containers/SignUp/signUp.types';
import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import Intro from 'widgets/identityVerification/Intro';
import Insurance from 'widgets/Insurance/Insurance';
import PreInsurance from 'widgets/PreInsurance';
import SymptomsSelector from 'widgets/symptomChecker/steps/SymptomsSelector';

import { useAppSelector } from 'hooks';
import useWeightManagement from 'hooks/useWeightManagement';
import { FlowTypes, SexAtBirth, SignUpSteps } from 'utils/enums';
import { lazyRetry } from 'utils/helpers';

import { PossibleStepsType } from '../postOnboarding.types';

import { Props } from './content.types';

const PatientInitialQuestions = lazy(() =>
  lazyRetry(() => import('widgets/symptomChecker/steps/PatientRiskFactors'))
);
const Regions = lazy(() => lazyRetry(() => import('widgets/symptomChecker/steps/Regions')));
const Interview = lazy(() => lazyRetry(() => import('widgets/symptomChecker/steps/Interview')));
const PatientInfo = lazy(() => lazyRetry(() => import('widgets/symptomChecker/steps/PatientInfo')));
const PreConfirmationPage = lazy(() =>
  lazyRetry(() => import('pages/SignUp/regularFlows/PreConfirmation'))
);
const PreSymptomChecker = lazy(() =>
  lazyRetry(() => import('pages/SignUp/regularFlows/PreSymptomChecker'))
);
const YouAreAllSet = lazy(() => lazyRetry(() => import('pages/SignUp/regularFlows/YouAreAllSet')));
const WomensHealth = lazy(() =>
  lazyRetry(() => import('pages/SignUp/regularFlows/mifSteps/WomensHealth'))
);
const UploadBodyPhoto = lazy(() =>
  lazyRetry(() => import('pages/SignUp/regularFlows/UploadBodyPhoto'))
);

const renderWithAnimation = (
  component: JSX.Element,
  key: string,
  params?: {
    className?: string;
    isBackAnimation?: boolean;
  }
) => (
  <SlideAnimateWrapper
    className={classNames('mx-auto w-full max-md:h-full', params?.className ?? '')}
    isBackAnimation={!!params?.isBackAnimation}
    key={key}
    src="signup"
  >
    {component}
  </SlideAnimateWrapper>
);

const Content: React.FC<Props> = ({
  loading = false,
  step,
  steps,
  moveToStep: originalMoveToStepFunc,
  onCompleteAddress,
  onSelectInsurance,
  isBackAnimation
}) => {
  // -2 is because we don't count the pharmacy and summary steps in question counter even if technically it's mif steps
  const { sexAtBirth } = useAppSelector(selectUser);
  const getMifStepsLength = () => {
    let length = 5;
    if (sexAtBirth === SexAtBirth.Female) {
      length += 1;
    }
    if (steps.includes('upload-body-photo')) {
      length += 1;
    }
    return length;
  };
  const mifStepsLength = getMifStepsLength();
  const { isTTPatient } = useWeightManagement();

  const moveToStep: MoveStepFunction = (type: 'next' | 'prev' | number, options) => {
    if (type === 'next' || type === 'prev') {
      originalMoveToStepFunc(type, options?.searchParams);
    } else {
      originalMoveToStepFunc(steps[type], options?.searchParams);
    }
  };

  const content: { [key in PossibleStepsType[number]]: ReactElement } = {
    address: renderWithAnimation(
      <CompleteAddress
        callback={onCompleteAddress}
        loading={loading}
        moveToStep={moveToStep}
        shouldRefetchAccountData={false}
      />,
      'address',
      { className: 'mx-auto h-full max-w-screen-sm', isBackAnimation }
    ),
    allergies: renderWithAnimation(
      <Allergies mifStepsLength={mifStepsLength} moveToStep={moveToStep} />,
      'allergies',
      { isBackAnimation }
    ),
    'chronic-diseases': renderWithAnimation(
      <ChronicDiseases mifStepsLength={mifStepsLength} moveToStep={moveToStep} />,
      'chronic-diseases',
      { isBackAnimation }
    ),
    'health-conditions': renderWithAnimation(
      <HealthConditions mifStepsLength={mifStepsLength} moveToStep={moveToStep} />,
      'health-conditions',
      { isBackAnimation }
    ),
    insurance: renderWithAnimation(
      <>
        <h1 className="mb-4 text-m2xl font-bold text-primary-700 md:mb-6 md:text-center md:text-2xl">
          Insurance information
        </h1>
        <Insurance
          key={SignUpSteps.Insurance}
          loading={loading}
          src="intake-form"
          onContinue={() => moveToStep('next')}
        />
      </>,
      'insurance',
      { className: 'mx-auto h-full max-w-screen-sm', isBackAnimation }
    ),
    'upload-body-photo': renderWithAnimation(
      <UploadBodyPhoto mifStepsLength={mifStepsLength} moveToStep={moveToStep} />,
      'upload-body-photo',
      {
        isBackAnimation
      }
    ),
    interview: renderWithAnimation(<Interview moveToStep={moveToStep} />, 'interview'),
    medications: renderWithAnimation(
      <Medications mifStepsLength={mifStepsLength} moveToStep={moveToStep} />,
      'medications',
      { isBackAnimation }
    ),
    'mif-summary': renderWithAnimation(
      <MifSummary moveToStep={moveToStep} steps={steps as SignUpSteps[]} />,
      'mif-summary',
      { isBackAnimation }
    ),
    pharmacy: renderWithAnimation(<Pharmacy moveToStep={moveToStep} />, 'pharmacy'),
    'pre-confirmation': renderWithAnimation(
      <PreConfirmationPage
        key={SignUpSteps.PreConfirmation}
        moveToStep={moveToStep}
        selectedFlow={FlowTypes.BasicFlow}
      />,
      'pre-confirmation',
      { className: 'mx-auto max-w-[500px]', isBackAnimation }
    ),
    'pre-insurance': renderWithAnimation(
      <>
        <h1 className="wm-signup-title md:text-center">Insurance coverage</h1>
        <PreInsurance
          loading={loading}
          ppType={isTTPatient ? 'tt' : 'glp-1'}
          onSelect={onSelectInsurance}
        />
      </>,
      'pre-insurance',
      {
        className: 'mx-auto flex size-full max-w-screen-sm flex-col gap-4 md:gap-6',
        isBackAnimation
      }
    ),
    'pre-symptom-checker': renderWithAnimation(
      <PreSymptomChecker flowStepsLength={steps.length} moveToStep={moveToStep} />,
      'pre-symptom-checker',
      { isBackAnimation }
    ),
    'sc-initial-info': renderWithAnimation(
      <PatientInitialQuestions moveToStep={moveToStep} source="guest" />,
      'sc-initial-info',
      { isBackAnimation }
    ),
    'sc-patient-info': renderWithAnimation(
      <PatientInfo moveToStep={moveToStep} />,
      'sc-patient-info',
      { isBackAnimation }
    ),
    'sc-regions': renderWithAnimation(<Regions moveToStep={moveToStep} />, 'sc-regions'),
    'sc-results': renderWithAnimation(<YouAreAllSet />, 'sc-results'),
    'sc-symptoms-selector': renderWithAnimation(
      <SymptomsSelector moveToStep={moveToStep} source="guest" />,
      'sc-symptoms-selector',
      { isBackAnimation }
    ),
    'social-history': renderWithAnimation(
      <SocialHistory
        doesIncludeBodyPhotoStep={steps.includes('upload-body-photo')}
        mifStepsLength={mifStepsLength}
        moveToStep={moveToStep}
      />,
      'social-history',
      { isBackAnimation }
    ),
    'verify-identity': renderWithAnimation(
      <Intro
        loading={loading}
        onContinue={() => originalMoveToStepFunc({ answer: true, step: 'verify-identity' })}
        onSkip={() => originalMoveToStepFunc({ answer: false, step: 'verify-identity' })}
      />,
      'verify-identity',
      { isBackAnimation }
    ),
    'women-health': renderWithAnimation(
      <WomensHealth mifStepsLength={mifStepsLength} moveToStep={moveToStep} />,
      'women-health',
      { isBackAnimation }
    )
  };

  const containerWidthClassName = (): string => {
    switch (step) {
      case 'medications':
      case 'allergies':
      case 'women-health':
      case 'chronic-diseases':
      case 'health-conditions':
      case 'social-history':
      case 'pre-symptom-checker':
        return 'max-w-[500px]';
      case 'pharmacy':
        return 'max-w-[904px]';
      case 'sc-patient-info':
      case 'sc-symptoms-selector':
        return 'max-w-[1024px]';
      default:
        return 'max-w-[640px]';
    }
  };
  const contentClassName = classNames(
    'flex flex-col flex-grow w-full mx-auto max-md:h-full px-0.5',
    containerWidthClassName()
  );

  return (
    <div className={contentClassName}>
      <AnimatePresence mode="wait">{content[step]}</AnimatePresence>
    </div>
  );
};

export default Content;
