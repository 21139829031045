import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { selectLookup, selectUser } from 'store';

import AdvantageItem from 'features/AdvantageItem';
import { notifyError } from 'shared/Toast/Toast';

import { useAppSelector, useQuery } from 'hooks';
import { PathName } from 'utils/enums';

import { MembershipPlan } from 'models/plans.types';

import { UpgradePlanCardProps } from './upgradePlan.types';

const findPlanByPricePointID = (id: string, plans?: MembershipPlan[]) =>
  plans?.find((plan) => plan.pricePoints?.some((pp) => pp.planPricePointId === id));

const UpgradePlanCard = ({ pp, onClick, advantages, isDisabled }: UpgradePlanCardProps) => {
  const isHighlighted = pp.paymentInterval.qty === 6;
  const discountText = pp.paymentInterval.qty === 3 ? '25% OFF' : '50% OFF';
  const accentColorClassName = 'text-mXl font-bold text-primary-700 md:text-xl';

  const calculatePrices = () => {
    switch (pp.paymentInterval.qty) {
      case 3:
        return {
          couponCode: 'GLP250OFF',
          subheaderText: `Save $${(Number(pp.totalCost) * parseFloat('25%')) / 100} Today`,
          totalValue: `${(Number(pp.totalCost) * parseFloat('75%')) / 100}`
        };
      case 6:
        return {
          couponCode: 'GLP150OFF',
          subheaderText: `Save $${(Number(pp.totalCost) * parseFloat('50%')) / 100} Today`,
          totalValue: `${(Number(pp.totalCost) * parseFloat('50%')) / 100}`
        };
      default:
        return {
          subheaderText: ``,
          totalValue: pp.totalCost
        };
    }
  };

  return (
    <div
      className={classNames(
        'flex flex-col gap-6 rounded-lg px-3 py-6',
        isHighlighted ? 'bg-gray-100' : 'border border-gray-200'
      )}
    >
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <span className={accentColorClassName}>{pp.paymentInterval.qty}-Month GLP-1</span>
          <Common.ColorTag color="secondary" size="sm" text={discountText} />
        </div>
        <div className="flex flex-col gap-1">
          <span className={accentColorClassName}>${calculatePrices().totalValue}</span>
          <span className="text-sm text-primary">{calculatePrices().subheaderText}</span>
        </div>
        <Common.Button
          className="md:hidden"
          color="blue"
          disabled={isDisabled}
          fullWidthOnMobile
          onClick={() => onClick(pp.planPricePointId, calculatePrices().couponCode)}
        >
          {isDisabled ? 'Active' : 'Select'}
        </Common.Button>
      </div>
      {!!advantages?.length && (
        <div className="flex flex-col gap-2">
          {advantages.map((item) => (
            <AdvantageItem
              advantage={item}
              className="text-mSm font-semibold md:text-sm"
              key={item.question}
            />
          ))}
        </div>
      )}
      <Common.Button
        className="mt-auto !w-full self-stretch max-md:hidden"
        color="blue"
        disabled={isDisabled}
        fullWidthOnMobile
        onClick={() => onClick(pp.planPricePointId, calculatePrices().couponCode)}
      >
        {isDisabled ? 'Active' : 'Select'}
      </Common.Button>
    </div>
  );
};

const UpgradePlan = () => {
  const { search } = useLocation();

  const navigate = useNavigate();
  const { membershipPlans } = useAppSelector(selectLookup);
  const { activePricePoint } = useAppSelector(selectUser);
  const query = useQuery();
  const ppOptionIDs = query.get('ppOptionIDs');
  const redirect = query.get('redirect');

  const PPsList = membershipPlans
    .map((plan) => plan.pricePoints)
    .flat()
    .filter(Boolean);

  const ppToShow = PPsList.filter((pp) => ppOptionIDs?.split(',').includes(pp.planPricePointId));

  const handleClick = (id: string, couponCode = '') => {
    const plan = findPlanByPricePointID(id, membershipPlans);
    navigate({
      pathname: PathName.Checkout,
      search: search + `&newPPID=${id}&newPlanID=${plan?._id}&couponCode=${couponCode}`
    });
  };

  const handleSkip = () => {
    navigate(redirect ?? PathName.Dashboard);
  };

  useEffect(() => {
    if (!ppOptionIDs) {
      notifyError('Error, please try again later');
      navigate(redirect ?? PathName.Dashboard);
    }
  }, []);

  return (
    <div className="mx-auto size-full max-w-[960px] md:mt-4 md:rounded-xl md:bg-white md:p-8 md:shadow">
      <div className="mx-auto flex w-full max-w-[500px] flex-col items-center md:gap-4">
        <div className="flex flex-col gap-2 text-primary-700 md:gap-4">
          <h1 className="text-mXl font-bold md:text-center md:text-2xl">
            Want to upgrade your plan?
          </h1>
          <p>
            Enhance your health journey with exclusive access to our Weight Management Program by
            upgrading your plan now!
          </p>
          <Common.Button
            className="md:hidden"
            color="blue"
            style="text-only"
            fullWidthOnMobile
            onClick={handleSkip}
          >
            No thanks, skip
          </Common.Button>
        </div>
        <div className="flex gap-2 max-md:flex-col md:justify-center">
          {ppToShow?.map((pp) => {
            const plan = findPlanByPricePointID(pp.planPricePointId, membershipPlans);
            return (
              <UpgradePlanCard
                advantages={plan?.additionalInfo}
                isDisabled={pp.planPricePointId === activePricePoint}
                key={pp.planPricePointId}
                pp={pp}
                onClick={handleClick}
              />
            );
          })}
        </div>
        <Common.Button
          className="self-center max-md:hidden md:!w-full"
          color="blue"
          style="text-only"
          fullWidthOnMobile
          onClick={handleSkip}
        >
          No thanks, skip
        </Common.Button>
      </div>
    </div>
  );
};

export default UpgradePlan;
