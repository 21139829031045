import { Common } from '@thecvlb/design-system';

import { Props } from './checkboxGroup.types';

const CheckboxGroup: React.FC<Props<string | number>> = ({
  isLoading,
  items,
  selectedItems,
  onSelect
}) => {
  return (
    <div className="flex flex-col gap-2">
      {items.map((item) => (
        <Common.RichCheckbox
          checked={selectedItems.includes(item.value)}
          disabled={isLoading}
          key={typeof item.label === 'string' ? item.label : String(item.value)}
          label={item.label}
          name={String(item.value)}
          value={item.value}
          onChange={() => onSelect(item.value)}
        />
      ))}
    </div>
  );
};

export default CheckboxGroup;
