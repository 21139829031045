import { useState } from 'react';
import { Common } from '@thecvlb/design-system';

import AppointmentPreview from 'modals/AppointmentPreview';
import TransitionWithDelayWrapper from 'shared/animationWrappers/TransitionWithDelayWrapper';

import { AppointmentTypeCardProps } from './appointmentTypeCard.types';

const AppointmentTypeCard: React.FC<AppointmentTypeCardProps> = ({
  items,
  name,
  description,
  setApptType,
  onSelect,
  referenceUrl = ''
}) => {
  const [isOpenAppointmentPreview, setOpenAppointmentPreview] = useState(false);
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setApptType(name);
    onSelect();
  };

  const clickOnQuestion = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpenAppointmentPreview(true);
  };

  const separateDescription = (title: string): Array<string> => {
    switch (title) {
      case 'Manage your ongoing conditions.':
        return ['Manage your', 'ongoing conditions.'];
      case 'For your everyday needs.':
        return ['For your', 'everyday needs.'];
      case 'Immediate attention for an urgent issue.':
        return ['Immediate attention', 'for an urgent issue.'];
      default:
        return [title];
    }
  };

  const linebreakDescription = (title: string) => {
    const names = separateDescription(title);
    return (
      <>
        {names[0]}
        <br />
        {names[1]}
      </>
    );
  };

  return (
    <>
      <AppointmentPreview
        isOpen={isOpenAppointmentPreview}
        referenceUrl={referenceUrl}
        onClose={() => setOpenAppointmentPreview(false)}
      />
      <div className="w-full" data-testid="wrapper-card" aria-hidden onClick={handleClick}>
        <div className="flex h-[332px] min-w-[340px] cursor-pointer flex-col rounded-xl border border-gray-200 bg-white px-4 py-6 transition-all hover:scale-[1.04] hover:shadow-lg md:h-[285px] md:min-w-[240px]">
          <div className="flex items-center justify-center gap-2">
            <h2 className="text-center text-mLg font-bold text-primary md:text-xl">{name}</h2>
            <button data-testid="question-icon" onClick={clickOnQuestion}>
              <Common.Icon className="text-gray-400" name="question" />
            </button>
          </div>
          <h4 className="my-4 text-center">{linebreakDescription(description)}</h4>
          <div className="mx-auto flex max-w-max flex-col gap-1">
            {items.map((advantage, i) => (
              <TransitionWithDelayWrapper
                className="flex items-center gap-2 text-mBase text-gray md:text-sm"
                custom={i}
                key={advantage}
              >
                <Common.Icon className="size-4 text-secondary" name="check" />
                {advantage}
              </TransitionWithDelayWrapper>
            ))}
          </div>
          <Common.Button className="mx-auto mt-auto" color="blue" fullWidthOnMobile>
            Select
          </Common.Button>
        </div>
      </div>
    </>
  );
};

export default AppointmentTypeCard;
