import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import { ColorTagProps, IconProps } from '@thecvlb/design-system/lib/src/common';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { useGetBillingHistoryQuery } from 'services/myAccount/myAccount';

import Pagination from 'features/Pagination';
import SliderPanel from 'features/SlidePanel';
import { PopupInfoProps } from 'features/SlidePanel/slidePanel.types';
import Table from 'features/Table';
import Loader from 'shared/Loader';

import { useDisableScroll } from 'hooks/useDisableScroll';
import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';

const getStatusContent = (
  status: string
): {
  color: ColorTagProps['color'];
  icon: IconProps['name'];
} => {
  switch (status.toLowerCase()) {
    case 'paid':
      return {
        color: 'green',
        icon: 'check'
      };
    case 'full refund':
    case 'partial refund':
      return {
        color: 'yellow',
        icon: 'check'
      };
    case 'not paid':
      return {
        color: 'red',
        icon: 'close'
      };
    case 'credit':
      return {
        color: 'blue',
        icon: 'check'
      };
    default:
      return {
        color: 'green',
        icon: 'check'
      };
  }
};

const BillingHistory = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    limit: '50',
    pageNo: '0'
  });
  const { handleDisableScroll } = useDisableScroll(searchParams);
  const { limit, pageNo } = Object.fromEntries([...searchParams]);

  const { data, isLoading, isFetching } = useGetBillingHistoryQuery({ limit, pageNo });
  const { isMobile } = useWidth();
  const [tableData, setTableData] = useState<{ [key: string]: string | number }[]>([]);
  const [popupInfo, setPopupInfo] = useState<PopupInfoProps & { isShow: boolean }>({
    content: null,
    isShow: false,
    title: ''
  });

  const changePage = useCallback(
    (data: { selected: number }) => {
      handleDisableScroll();
      searchParams.set('pageNo', data.selected.toString());
      const newQueryParams = searchParams.toString();
      setSearchParams(newQueryParams);
    },
    [searchParams]
  );

  const togglePopup = () => {
    setPopupInfo({ ...popupInfo, isShow: !popupInfo.isShow });
  };

  const getModalContent = (item: { [key: string]: string | number }) => {
    return (
      <div className="py-6 md:py-8">
        <div className="flex flex-col gap-4 rounded-xl border border-gray-200 bg-gray-50 p-4">
          <p className="flex justify-between md:text-lg md:font-bold">
            <span>{item.invoice.toString().split('-')[0]}</span>
            <span className={classNames({ 'text-gray line-through': item.refundAmount })}>
              ${+item.amount + +item.discountAmount}
            </span>
          </p>
          {!!item.refundAmount ? (
            <>
              {!!item.discountAmount && (
                <p className="flex justify-between font-bold text-gray md:text-lg">
                  <span>Discount</span>
                  <span className="line-through">-${item.discountAmount}</span>
                </p>
              )}
              {!!item.credit && (
                <p className="flex justify-between text-gray">
                  <span>LifeMD credit</span>
                  <span className={classNames({ 'text-gray line-through': item.refundAmount })}>
                    -${item.credit}
                  </span>
                </p>
              )}
              <div className="flex flex-col gap-1 border-t border-gray-200 pt-4">
                <p className="flex justify-between font-bold md:text-lg">
                  <span>Total paid</span>
                  <span className={classNames({ 'text-gray line-through': item.refundAmount })}>
                    ${item.paidAmount}
                  </span>
                </p>
              </div>
              <div className="flex flex-col gap-1 border-t border-gray-200 pt-4">
                <p className="flex justify-between font-bold md:text-lg">
                  <span>Total refunded</span>
                  <span className="text-green">+${item.refundAmount}</span>
                </p>
                <p className="text-gray">
                  Refunded to {item.cardBrand} – {item.maskedCardNumber || item.email}
                </p>
              </div>
            </>
          ) : (
            <>
              {item.discountTitle && (
                <p className="flex justify-between text-orange md:text-lg md:font-bold">
                  <span>{item.discountTitle}</span>
                  <span>-${item.discountAmount}</span>
                </p>
              )}
              <div>
                <p className="flex justify-between font-bold md:text-lg">
                  <span>Total due</span>
                  <span>
                    {!!item.credit && (
                      <span className="mr-2 font-medium text-gray line-through">
                        ${item.amount}
                      </span>
                    )}
                    ${Math.floor((+item.amount - +item.credit) * 100) / 100}
                  </span>
                </p>
                {!!item.credit && (
                  <p className="flex justify-between text-gray">
                    <span>LifeMD credit</span>
                    <span>-${Math.floor(+item.credit * 100) / 100}</span>
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-1 border-t border-gray-200 pt-4">
                <p className="flex justify-between font-bold md:text-lg">
                  <span>Amount paid</span>
                  <span>${item.paidAmount}</span>
                </p>
                {item.cardBrand && (
                  <p className="capitalize text-gray">
                    Payment from {item.cardBrand} – {item.maskedCardNumber || item.email}
                  </p>
                )}
              </div>
            </>
          )}
        </div>
        <a href={item.invoiceUrl.toString()} target="_blank">
          <Common.Button className="mt-6 md:mt-8" color="blue" preIcon="download" fullWidthOnMobile>
            Download
          </Common.Button>
        </a>
      </div>
    );
  };
  const getModalTitle = (item: { [key: string]: string | number }) => (
    <div className="flex flex-col gap-2">
      <h3 className="text-gray">Invoice #{item.number}</h3>
      {'status' in item && typeof item.status === 'string' && (
        <Common.ColorTag color={getStatusContent(item.status).color} text={item.status} />
      )}
      <h4 className="text-mLg font-bold md:text-xl">{item.invoice}</h4>
      <h4 className="md:font-bold">{item.date}</h4>
    </div>
  );

  const openPanel = (item: { [key: string]: string | number }) => {
    setPopupInfo({
      content: getModalContent(item),
      isShow: true,
      title: getModalTitle(item)
    });
  };

  useEffect(() => {
    data?.data &&
      setTableData(
        data.data.map((el) => ({
          amount: +el.amount,
          cardBrand:
            el.payment?.paymentMethod.cardBrand ||
            (el.payment?.paymentMethod.email ? 'PayPal' : ''),
          credit: +(el.credit?.appliedAmount || '0'),
          date: dayjs(el.createdAt).format(DateFormat.MMMM_DD_YYYY),
          discountAmount: +(el.discount?.discountAmount || '0'),
          discountTitle: el.discount?.title || '',
          email: el.payment?.paymentMethod.email || '',
          invoice: el.title,
          invoiceUrl: el.invoiceUrl,
          maskedCardNumber: el.payment?.paymentMethod.maskedCardNumber || '',
          number: el.number,
          paidAmount: +el.paidAmount,
          refundAmount: +el.refundAmount,
          status: el.status.charAt(0).toUpperCase() + el.status.slice(1)
        }))
      );
  }, [data]);

  return (
    <>
      <Loader isVisible={isLoading || isFetching} />
      {data?.data && (
        <div>
          <h2 className="mb-4 font-bold md:text-xl md:text-primary-700">Billing history</h2>
          <div className="overflow-hidden rounded-xl bg-white shadow">
            {isMobile ? (
              <Common.Modal close={togglePopup} isOpen={popupInfo.isShow} size="sm">
                {popupInfo.title}
                {popupInfo.content}
              </Common.Modal>
            ) : (
              <SliderPanel
                isShowModal={popupInfo.isShow}
                title={popupInfo.title}
                toggleModal={togglePopup}
              >
                {popupInfo.content}
              </SliderPanel>
            )}
            <Table
              boldCols={['amount']}
              customCol={(el) => {
                if (el.name === 'amount') {
                  return <>${el.content}</>;
                }
                if (el.name === 'invoice') {
                  return <span className="underline">{el.content}</span>;
                }
                if (el.name === 'status') {
                  return (
                    <Common.ColorTag
                      color={getStatusContent(String(el.content)).color}
                      icon={getStatusContent(String(el.content)).icon}
                      text={el.content.toString()}
                    />
                  );
                }
              }}
              data={tableData}
              handleSort={({ sortField, sortOrder }) => {
                handleDisableScroll();
                searchParams.set('sortField', sortField === 'date' ? 'createdAt' : sortField);
                searchParams.set('sortOrder', sortOrder);
                const newQueryParams = searchParams.toString();
                setSearchParams(newQueryParams);
              }}
              headers={[
                {
                  id: 'invoice',
                  size: isMobile ? '75%' : '50%'
                },
                ...(!isMobile
                  ? [
                      {
                        id: 'date',
                        size: '20%'
                      },
                      {
                        id: 'status',
                        size: '20%'
                      }
                    ]
                  : []),
                {
                  id: 'amount',
                  size: '10%'
                }
              ]}
              hideActions
              onClickRow={openPanel}
            />
            {data && (
              <Pagination
                params={{
                  limit: +limit,
                  pageNo: +pageNo,
                  totalCount: data?.info.totalCount || 0
                }}
                onChange={changePage}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BillingHistory;
