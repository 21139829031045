import posthog from 'posthog-js';

import { SignUpProps } from 'store/signup/signup.types';

import { IS_LIVE } from './constants';
import { FlowTypes, SignUpSteps } from './enums';

export const handlePostHogTracking = (
  stepName: SignUpSteps,
  user: SignUpProps['user'],
  appointmentType: string,
  pricePoint = ''
) => {
  const { planCode: membershipPlan, doctorLicenseState: stateOfResidence } = user;
  // posthog.people.set({ appointmentType, membershipPlan, pricePoint, stateOfResidence });
  IS_LIVE &&
    posthog.capture(`[${stepName}]`, {
      appointmentType,
      membershipPlan,
      pricePoint,
      stateOfResidence
    });
};

export const handlePostHogEvent = (message: string, payload?: Record<string, string>) => {
  IS_LIVE && posthog.capture(message, payload);
};

export const handlePostHogPurchase = (
  flow: FlowTypes,
  order: Record<string, unknown> | null = {}
) => {
  IS_LIVE &&
    posthog.capture('purchase', {
      flow,
      orderCouponCode: order?.couponCode || '',
      orderTotal: order?.orderTotal || '',
      orderTs: order?.orderTs || '',
      planName: order?.productName || '',
      productId: order?.productId || ''
    });
};
