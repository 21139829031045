import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { useUpdatePaymentProfileMutation } from 'services/myAccount/myAccount';

import { notifySuccess } from 'shared/Toast/Toast';
import { buildBodyForPaymentUpdate } from 'widgets/myAccount/BillingDetails/billingDetails.settings';
import PaymentFormNew from 'widgets/PaymentFormNew';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';

import useWidth from 'hooks/useWidth';
import { handleRequestCatch } from 'utils/helpers';

import { FailedPaymentProps } from './failedPayment.types';

const FailedPayment: React.FC<FailedPaymentProps> = ({
  isOpenByDefault = false,
  onResubmit,
  onClose,
  hasCardAlready
}) => {
  const { formState, control, getValues } = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onChange'
  });
  const { isMobile } = useWidth();
  const [isOpen, toggleIsOpen] = useToggle(isOpenByDefault);
  const [updatePaymentProfile, { isLoading }] = useUpdatePaymentProfileMutation();

  const handleUpgradeAccountClick = () => {
    const formFields = getValues() as PaymentFormFields;
    const method = hasCardAlready ? 'PUT' : 'POST';
    updatePaymentProfile(buildBodyForPaymentUpdate(formFields, method))
      .unwrap()
      .then((res) => {
        notifySuccess(res.message);
        onResubmit?.();
        toggleIsOpen(false);
      })
      .catch((e) => handleRequestCatch(e, 'Something went wrong, please try again later'));
  };

  const handleClose = () => {
    onClose?.();
    toggleIsOpen(false);
  };

  useEffect(() => {
    toggleIsOpen(isOpenByDefault);
  }, [isOpenByDefault]);

  return (
    <>
      <Common.Modal close={onClose} isOpen={isOpen} padding={!isMobile} size="base" zIndex={50}>
        <div className={classNames('flex flex-col gap-4', isMobile ? 'px-4 py-8' : 'p-0.5')}>
          <Common.Alert type="error" colorableBackground>
            <p>Payment failed. Please enter new payment information to continue.</p>
          </Common.Alert>
          <PaymentFormNew control={control} getValues={getValues} />
          <div className="mt-2 flex flex-col-reverse justify-end gap-3 md:flex-row md:gap-2">
            <Common.Button color="white-alt" fullWidthOnMobile onClick={handleClose}>
              Cancel
            </Common.Button>
            <Common.Button
              color="blue"
              dataTestId="continue-btn"
              disabled={!formState.isValid || isLoading}
              isLoading={isLoading}
              fullWidthOnMobile
              onClick={handleUpgradeAccountClick}
            >
              Upgrade account
            </Common.Button>
          </div>
        </div>
      </Common.Modal>
    </>
  );
};

export default FailedPayment;
