import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import {
  useAddHealthMetricsMutation,
  useDeleteHealthMetricMutation,
  useUpdateHealthMetricsMutation
} from 'services/healthMetrics/healthMetrics';

import MeasurementDateInput from 'shared/form/MeasurementDateInput';
import NumberInput from 'shared/form/NumberInput';
import Loader from 'shared/Loader';

import { useQuery } from 'hooks';
import useWidth from 'hooks/useWidth';
import { DateFormat, DeepLinkAction } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';
import { LBS_REGEX } from 'utils/regExp';

import BasicConfirmation from '../BasicConfirmation';

import { FormDataProps, WeightEditProps } from './weightEdit.types';

const WeightEdit: React.FC<WeightEditProps> = ({ isOpen, onClose, element, currentWeight }) => {
  const unitsMatch = element?.['metrics.weight'].match(LBS_REGEX)?.[1];
  const min = unitsMatch ? +unitsMatch - 25 : currentWeight ? currentWeight - 25 : 0;
  const [addHealthMetrics, { isLoading: isLoadingAdd }] = useAddHealthMetricsMutation();
  const [updateHealthMetrics, { isLoading: isLoadingUpdate }] = useUpdateHealthMetricsMutation();
  const [deleteHealthMetric, { isLoading: isLoadingDelete }] = useDeleteHealthMetricMutation();
  const { isMobile } = useWidth();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [value, onUpdate] = useState(
    unitsMatch ? +unitsMatch : currentWeight ? currentWeight : min
  );

  const isUpdateWeight = useQuery().get('action') === DeepLinkAction.WMAddWeight;

  const { control, reset, getValues, handleSubmit } = useForm<FormDataProps>({
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false
  });

  const isLoading = isLoadingAdd || isLoadingUpdate || isLoadingDelete;

  const handleDelete = (cb: () => void) => {
    if (!element) return;

    deleteHealthMetric({
      collectedDate: dayjs(element.collectedDate).format(DateFormat.YYYY_MM_DD),
      metricType: 'weight'
    })
      .unwrap()
      .then(cb)
      .catch(handleRequestCatch);
  };

  const onSubmit = (data: FormDataProps) => {
    const body = {
      collectedDate: dayjs(data.date).format(DateFormat.YYYY_MM_DD),
      metrics: { weight: +data.lbs }
    };

    const performAction = (action: typeof addHealthMetrics | typeof updateHealthMetrics) => {
      action(body)
        .unwrap()
        .then(() => onClose(true))
        .catch(handleRequestCatch);
    };

    if (!element) {
      performAction(addHealthMetrics);
      return;
    }

    const isSameDay = dayjs(element.collectedDate).diff(dayjs(body.collectedDate), 'days') === 0;
    if (isSameDay) {
      performAction(updateHealthMetrics);
    } else {
      handleDelete(() => performAction(updateHealthMetrics));
    }
  };

  const handleClick = () => {
    if (
      dayjs(element?.collectedDate).format(DateFormat.YYYY_MM_DD) ===
      dayjs(getValues().date).format(DateFormat.YYYY_MM_DD)
    ) {
      handleSubmit(onSubmit)();
    } else {
      setShowConfirmPopup(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        date: dayjs(element?.collectedDate).format(DateFormat.MM_DD_YYYY),
        lbs: isMobile || value ? value.toString() : ''
      });
    } else {
      setShowConfirmPopup(false);
    }
  }, [value, isOpen]);

  useEffect(() => {
    onUpdate(unitsMatch ? +unitsMatch : currentWeight ? currentWeight : min);
  }, [unitsMatch, currentWeight]);

  return (
    <Common.Modal
      autoScroll={isMobile}
      close={() => {
        setShowConfirmPopup(false);
        onClose();
      }}
      description={`${
        isUpdateWeight
          ? 'It’s time to check in for the week! Enter your weight below, and be sure to take your injection.'
          : !element
            ? 'Add'
            : 'Edit'
      } ${isUpdateWeight ? '' : isMobile ? 'weight' : ' measurement'}`}
      isOpen={isOpen}
      size="base"
      title="Weight"
    >
      <BasicConfirmation
        confirmButtonText="Confirm"
        isLoading={isLoading}
        isOpen={showConfirmPopup}
        subHeaderText="You are potentially overwriting a previously recorded weight value by changing the date of another weight measurement"
        onClose={() => setShowConfirmPopup(false)}
        onConfirm={() => handleSubmit(onSubmit)()}
      />
      <Loader isVisible={isLoading} />
      <div className="flex flex-col gap-6 p-0.5 md:gap-4 md:pr-4">
        <NumberInput
          control={control}
          dataTestId="weight_lbs_input"
          label={!isMobile && 'Weight'}
          labelClassName="flex items-center"
          labelDirection="row"
          name="lbs"
          postText="lbs"
          requiredErrorMsg="lbs is required"
        />
        <MeasurementDateInput
          control={control}
          dataTestId="weight_date"
          labelDirection={isMobile ? 'col' : 'row'}
          showTodayCheckbox={!element}
        />
        <div
          className={classNames(
            'flex flex-col gap-3 md:flex-row-reverse',
            isMobile ? 'mt-4' : 'mt-2'
          )}
        >
          <Common.Button
            color="blue"
            dataTestId="add_measurement_btn"
            disabled={isLoading}
            size={isMobile ? 'lg' : 'sm'}
            fullWidthOnMobile
            onClick={handleClick}
          >
            {!element || isMobile ? 'Add' : 'Edit'} measurement
          </Common.Button>
          <Common.Button
            color={isMobile ? undefined : 'white-alt'}
            dataTestId="cancel_btn"
            size={isMobile ? 'lg' : 'sm'}
            type="button"
            fullWidthOnMobile
            onClick={() => onClose(false)}
          >
            Cancel
          </Common.Button>
          {!isMobile && element && (
            <Common.Button
              className="mr-auto"
              color="red-alt"
              dataTestId="delete_btn"
              disabled={isLoading}
              preIcon="trash"
              size="sm"
              type="button"
              onClick={() => handleDelete(() => onClose(true))}
            >
              Delete
            </Common.Button>
          )}
        </div>
      </div>
    </Common.Modal>
  );
};

export default WeightEdit;
