import { useNavigate } from 'react-router-dom';

import { useSendOrchestrateFormMutation } from 'services/auth/auth';

import { selectOrchestrate, selectUser } from 'store';
import { setWholeState } from 'store/orchestrate-signup/orchestrateSlice';
import { setExpiredToken, setUserState } from 'store/signup/signupSlice';

import { useAppDispatch, useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';
import { handlePostHogEvent } from 'utils/posthog';

const useSubmitOrchestrateForm = () => {
  const navigate = useNavigate();
  const [sendData, { isLoading }] = useSendOrchestrateFormMutation();
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector(selectUser);
  const orchestrateState = useAppSelector(selectOrchestrate);

  type ObjectType = typeof orchestrateState;
  type MyKeyType = keyof ObjectType;
  type CombinedPayloadType = {
    key: keyof ObjectType;
    value: ObjectType[MyKeyType] | Partial<ObjectType[MyKeyType]>;
  }[];

  const handleProspect = (data: { answer: boolean; question: string }) => {
    send(
      'mif_qa',
      [data],
      () => {
        navigate(PathName.Login);
      },
      'PROSPECT',
      () => {
        navigate(PathName.Login);
      }
    );
  };

  const send = (
    type: keyof ObjectType | 'combined',
    data: ObjectType[MyKeyType] | Partial<ObjectType[MyKeyType]> | CombinedPayloadType,
    cb?: () => void,
    additionalSubmitKey: 'PROSPECT' | 'USER' | '' = '',
    errorCb?: (value?: unknown) => void
  ) => {
    let body = {
      ...orchestrateState,
      ...(!!additionalSubmitKey && { submit: additionalSubmitKey })
    };
    switch (type) {
      case 'combined':
        const parsedData = data as CombinedPayloadType;
        parsedData.forEach((item) => {
          switch (item.key) {
            case 'user':
              body.user = {
                ...orchestrateState.user,
                ...(item.value as Partial<ObjectType[typeof item.key]>)
              };
              break;
            case 'mif_qa':
              body['mif_qa'] = [
                ...orchestrateState.mif_qa,
                ...(item.value as ObjectType[typeof item.key])
              ];
              break;
            case 'mif':
              body['mif'] = {
                ...orchestrateState.mif,
                ...(item.value as ObjectType[typeof item.key])
              };
              break;
            case 'marketing':
              body['marketing'] = {
                ...orchestrateState.marketing,
                ...(item.value as Partial<ObjectType[typeof item.key]>)
              };
              break;
            default:
              break;
          }
        });
        break;
      case 'user':
        const userCastedData = data as Partial<ObjectType[typeof type]>;
        body = { ...body, user: { ...orchestrateState.user, ...userCastedData } };
        break;
      case 'insurances':
        const insurancesCastedData = data as ObjectType[typeof type];
        body = { ...body, insurances: insurancesCastedData };
        break;
      case 'mif_qa':
        const mifQaCastedData = data as ObjectType[typeof type];
        body = { ...body, mif_qa: [...orchestrateState.mif_qa, ...mifQaCastedData] };
        break;
      case 'mif':
        const mifCastedData = data as ObjectType[typeof type];
        body = { ...body, mif: { ...orchestrateState.mif, ...mifCastedData } };
        break;
      case 'payment':
        const paymentCastedData = data as Partial<ObjectType[typeof type]>;
        body = { ...body, payment: { ...orchestrateState.payment, ...paymentCastedData } };
        break;
      case 'marketing':
        const marketingCastedData = data as Partial<ObjectType[typeof type]>;
        body = { ...body, marketing: { ...orchestrateState.marketing, ...marketingCastedData } };
        break;
      case 'appointment':
        const appointmentCastedData = data as Partial<ObjectType[typeof type]>;
        body = {
          ...body,
          appointment: { ...orchestrateState.appointment, ...appointmentCastedData }
        };
        break;
      default:
        break;
    }
    sendData({
      ...body,
      ...(!!accessToken && { accessToken }),
      session: {
        ...body.session,
        metadata: {
          urlQuery: window.location.pathname
        }
      }
    })
      .unwrap()
      .then((res) => {
        // condition for the checkout page - this is the only page where we receive the new tokens other than accessToken
        if ('accessToken' in res.data && 'refreshToken' in res.data) {
          dispatch(setExpiredToken(res.data.expiredToken));
          dispatch(
            setUserState({
              accessToken: res.data.accessToken,
              customerId: String(res.data.chargifyCustomerId),
              refreshToken: res.data.refreshToken,
              subscriptionId: String(res.data.chargifySubscriptionId)
            })
          );
          return cb?.();
        }

        if (body.insurances[0]?.isNeedVerify) {
          const status = res?.data?.insurances[0]?.eligibilityResponse?.status;
          if (status) {
            const message =
              status === 'UNKNOWN'
                ? 'wm-el-notverified'
                : status === 'INACTIVE'
                  ? 'wm-el-notaccepted'
                  : 'wm-el-accepted';
            handlePostHogEvent(message);
          }
        }
        try {
          let newState = { ...res.data };
          if (Object.keys(newState).length === 0) {
            return cb?.();
          }
          if (newState.user.password) {
            newState = { ...newState, user: { ...newState.user, password: '' } };
            delete newState.user.password;
          }
          if (newState.user.hasOwnProperty('encryptedPassword')) {
            newState = { ...newState, user: { ...newState.user, encryptedPassword: '' } };
            delete newState.user.encryptedPassword;
          }
          dispatch(setWholeState(newState));
          cb?.();
        } catch (error) {
          throw new Error('Error in saving progress');
        }
      })
      .catch((e) => {
        if (!!errorCb) {
          errorCb(e);
        }
        handleRequestCatch(e);
      });
  };
  return { isLoading, send, handleProspect };
};

export default useSubmitOrchestrateForm;
