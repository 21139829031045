import { ReactElement } from 'react';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import Agreement from 'pages/SignUp/WM/Agreement';
import AppointmentConfirmation from 'pages/SignUp/WM/AppointmentConfirmation';
import CompleteOnboarding from 'pages/SignUp/WM/CompleteOnboarding';
import Congrats from 'pages/SignUp/WM/Congrats';
import Diabetes from 'pages/SignUp/WM/Diabetes';
import GallBladders from 'pages/SignUp/WM/Gallbladders';
import HealthState from 'pages/SignUp/WM/HealthState';
import HeightAndWeight from 'pages/SignUp/WM/HeightAndWeight';
import InsuranceType from 'pages/SignUp/WM/InsuranceType';
import MarketingLowerPrice from 'pages/SignUp/WM/MarketingLowerPrice';
import MedicalConditions from 'pages/SignUp/WM/MedicalConditions';
import MigrationCheckout from 'pages/SignUp/WM/MigrationCheckout';
import NewTargetWeight from 'pages/SignUp/WM/NewTargetWeight';
import Pancreatitis from 'pages/SignUp/WM/Pancreatitis';
import Pregnancy from 'pages/SignUp/WM/Pregnancy';
import ScheduleAnAppointment from 'pages/SignUp/WM/ScheduleAnAppointment';
import SelectPlan from 'pages/SignUp/WM/SelectPlan';
import ThyroidNodules from 'pages/SignUp/WM/ThyroidNodules';
import WeightLoseWithoutUncertainty from 'pages/SignUp/WM/WeightLoseWithoutUncertainty';
import WeightLossGoal from 'pages/SignUp/WM/WeightLossGoal';
import YouAreInGoodHands from 'pages/SignUp/WM/YouAreInGoodHands';

import { MoveStepFunction } from 'containers/SignUp/signUp.types';
import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import Intro from 'widgets/identityVerification/Intro';
import Insurance from 'widgets/Insurance';
import PreInsurance from 'widgets/PreInsurance';

import useWeightManagement from 'hooks/useWeightManagement';

import { ContentProps, PossibleStepsType } from '../migrateToWM.types';

const renderWithAnimation = (component: JSX.Element, key: string, className?: string) => {
  return (
    <SlideAnimateWrapper
      className={classNames('mx-auto w-full max-md:h-full', className)}
      key={key}
    >
      {component}
    </SlideAnimateWrapper>
  );
};

const Content: React.FC<ContentProps> = ({
  step,
  steps,
  moveToStep: originalMoveToStepFunc,
  loading,
  onSelectInsurance
}) => {
  const moveToStep: MoveStepFunction = (type: 'next' | 'prev' | number, options) => {
    if (type === 'next' || type === 'prev') {
      originalMoveToStepFunc(type, options?.searchParams);
    } else {
      originalMoveToStepFunc(steps[type], options?.searchParams);
    }
  };

  const { isTTPatient } = useWeightManagement();

  const content: { [key in PossibleStepsType[number]]: ReactElement | null } = {
    agreement: renderWithAnimation(
      <Agreement moveToStep={moveToStep} />,
      'agreement',
      'max-w-[500px]'
    ),
    'appointment-confirmation': renderWithAnimation(
      <AppointmentConfirmation moveToStep={moveToStep} selectedFlow="authorized" />,
      'appointment-confirmation',
      'max-w-[500px]'
    ),
    insurance: renderWithAnimation(
      <div>
        <h3 className="wm-signup-title mb-4 md:text-center">Insurance information</h3>
        <Insurance src="migrate-to-wm" onContinue={() => moveToStep('next')} />
      </div>,
      'insurance',
      'max-w-[600px]'
    ),
    'pre-insurance': renderWithAnimation(
      <div className="mx-auto flex size-full max-w-screen-sm flex-col gap-4 md:gap-6">
        <h1 className="wm-signup-title md:text-center">Insurance coverage</h1>
        <PreInsurance
          ppType={isTTPatient ? 'tt' : 'glp-1'}
          showSubheader
          onSelect={onSelectInsurance}
        />
      </div>,
      'pre-insurance',
      'max-w-[500px]'
    ),
    pregnancy: renderWithAnimation(
      <Pregnancy onContinue={() => moveToStep('next')} />,
      'pregnancy',
      'max-w-[500px]'
    ),
    'verify-identity': renderWithAnimation(
      <Intro
        loading={loading}
        onContinue={() => originalMoveToStepFunc({ answer: true, step: 'verify-identity' })}
        onSkip={() => originalMoveToStepFunc({ answer: false, step: 'verify-identity' })}
      />,
      'verify-identity',
      'max-w-[600px]'
    ),
    'wm-checkout': renderWithAnimation(
      <MigrationCheckout onContinue={() => moveToStep('next')} />,
      'wm-checkout',
      'max-w-[800px]'
    ),
    'wm-complete-onboarding': renderWithAnimation(
      <CompleteOnboarding flow="authorized" />,
      'wm-complete-onboarding',
      'max-w-[500px]'
    ),
    'wm-congrats': renderWithAnimation(
      <Congrats moveToStep={moveToStep} selectedFlow={'authorized'} />,
      'wm-congrats',
      'max-w-[500px]'
    ),
    'wm-diabetes-question': renderWithAnimation(
      <Diabetes moveToStep={moveToStep} selectedFlow="authorized" />,
      'wm-diabetes-question',
      'max-w-[500px]'
    ),
    'wm-gallbladder-question': renderWithAnimation(
      <GallBladders flow="authorized" moveToStep={moveToStep} />,
      'wm-gallbladder-question',
      'max-w-[500px]'
    ),
    'wm-height-and-weight': renderWithAnimation(
      <HeightAndWeight moveToStep={moveToStep} selectedFlow="authorized" />,
      'wm-height-and-weight',
      'max-w-[500px]'
    ),
    'wm-insurance': renderWithAnimation(
      <InsuranceType
        selectedFlow="authorized"
        onCancel={() => moveToStep('prev')}
        onComplete={(v) => originalMoveToStepFunc({ answer: v, step: 'wm-insurance' })}
      />,
      'wm-insurance',
      'max-w-[640px]'
    ),
    'lower-price': renderWithAnimation(
      <MarketingLowerPrice moveToStep={moveToStep} />,
      'wm-lower-price',
      'max-w-[1020px]'
    ),
    'wm-medical-conditions': renderWithAnimation(
      <MedicalConditions flow="authorized" moveToStep={moveToStep} />,
      'wm-medical-conditions',
      'max-w-[500px]'
    ),
    'wm-new-target-weight': renderWithAnimation(
      <NewTargetWeight flow="authorized" moveToStep={moveToStep} />,
      'wm-new-target-weight',
      'max-w-[500px]'
    ),
    'wm-pancreatitis-question': renderWithAnimation(
      <Pancreatitis flow="authorized" moveToStep={moveToStep} />,
      'wm-pancreatitis-question',
      'max-w-[500px]'
    ),
    'wm-schedule-appointment': renderWithAnimation(
      <ScheduleAnAppointment moveToStep={moveToStep} selectedFlow={'authorized'} />,
      'wm-schedule-appointment',
      'max-w-[500px]'
    ),
    'wm-select-plan': renderWithAnimation(
      <SelectPlan moveToStep={moveToStep} selectedFlow={'authorized'} />,
      'wm-select-plan',
      'max-w-[704px]'
    ),
    'wm-thyroid-nodules-question': renderWithAnimation(
      <ThyroidNodules moveToStep={moveToStep} />,
      'wm-thyroid-nodules-question',
      'max-w-[500px]'
    ),
    'wm-user-health-state': renderWithAnimation(
      <HealthState moveToStep={moveToStep} />,
      'wm-user-health-state',
      'max-w-[500px]'
    ),
    'wm-weight-lose-aim': renderWithAnimation(
      <WeightLossGoal moveToStep={moveToStep} />,
      'wm-weight-lose-aim',
      'max-w-[500px]'
    ),
    'wm-weight-lose-without-uncertainty': renderWithAnimation(
      <WeightLoseWithoutUncertainty
        selectedFlow="authorized"
        onContinue={() => moveToStep('next')}
      />,
      'wm-weight-lose-without-uncertainty',
      'max-w-[1020px]'
    ),
    'wm-you-are-in-good-hands': renderWithAnimation(
      <YouAreInGoodHands selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'wm-you-are-in-good-hands',
      'max-w-[500px]'
    )
  };

  const contentClassName = classNames('flex flex-col grow w-full mx-auto max-md:h-full px-0.5');

  return (
    <div className={contentClassName}>
      <AnimatePresence mode="wait">{content[step]}</AnimatePresence>
    </div>
  );
};

export default Content;
