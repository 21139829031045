import { useState } from 'react';

import { ReducedSignupStepProps } from 'containers/SignUp/Content/content.types';
import RadioGroup from 'shared/RadioGroup';

import { YES_NO_OPTIONS } from 'constants/formFields';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';

import Heading from '../parts/Heading';

const ThyroidNodules: React.FC<ReducedSignupStepProps> = ({ moveToStep }) => {
  const { send, isLoading } = useSubmitOrchestrateForm();
  const [activeItem, setActiveItem] = useState<boolean>();

  const handleSelect = (v: boolean) => {
    setActiveItem(v);
    send('mif_qa', [{ answer: v, question: 'history_of_medullary_thyroid_cancer' }], () => {
      setActiveItem(undefined);
      moveToStep('next');
    });
  };

  return (
    <div className="flex flex-col gap-6">
      <Heading
        category="Plan"
        title="Do you have a personal or family history of medullary thyroid cancer (thyroid nodules)?"
      />
      <RadioGroup
        isLoading={isLoading}
        items={YES_NO_OPTIONS}
        selectedItem={activeItem}
        onSelect={handleSelect}
      />
    </div>
  );
};

export default ThyroidNodules;
