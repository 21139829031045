import React from 'react';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';

import { DocumentProps } from './document.types';

const Document: React.FC<DocumentProps> = ({
  isUploadedByCurrentUser,
  uploadedBy,
  title,
  date,
  onDownload,
  onDelete,
  loading,
  type = 'doc',
  dataTestId,
  isLoadingDelete,
  isLoadingDownload,
  onClick
}) => {
  const { isMobile } = useWidth();
  const uploadedByVal = isUploadedByCurrentUser ? 'Me' : uploadedBy;
  const showDeleteButton = !!onDelete && isUploadedByCurrentUser && !isMobile;

  const handleDownloadMobile = () => {
    if (isMobile) {
      onDownload();
    } else {
      onClick?.();
    }
  };

  const handleDeleteMobile = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete?.();
  };

  return (
    <div
      className="flex cursor-pointer items-center gap-3 px-2 py-4 md:px-0"
      data-testid={dataTestId}
      onClick={handleDownloadMobile}
    >
      <Common.Icon
        className="text-primary md:size-[42px] md:rounded-lg md:bg-primary-50 md:p-[9px]"
        name="articles"
      />
      <div className="flex flex-1 flex-col">
        <p className="break-all font-semibold text-gray-700">{title}</p>
        {date && (
          <p
            className="mt-0.5 text-mSm font-medium text-gray md:mt-1 md:text-sm"
            data-testid="uploaded_when_and_by"
          >
            {dayjs(date).format(DateFormat.MMM_DD_YYYY)}
            {!!uploadedByVal && ` • ${uploadedByVal}`}
          </p>
        )}
      </div>
      {isMobile && isUploadedByCurrentUser && (
        <>
          <button data-testid="delete_on_mobile" disabled={loading} onClick={handleDeleteMobile}>
            <Common.Icon
              className="flex-none text-gray"
              name={type === 'lab' ? 'arrow-right' : 'dots-horizontal'}
            />
          </button>
        </>
      )}

      {showDeleteButton && (
        <Common.Button
          className="hidden md:flex"
          color="red-alt"
          dataTestId="delete_btn"
          disabled={loading}
          isLoading={isLoadingDelete}
          preIcon="trash"
          size="sm"
          onClick={onDelete}
        >
          Delete
        </Common.Button>
      )}
      <Common.Button
        className="hidden md:flex"
        color="green-alt"
        dataTestId="download_btn"
        isLoading={isLoadingDownload}
        preIcon="download"
        size="sm"
        onClick={onDownload}
      >
        Download
      </Common.Button>
    </div>
  );
};

export default Document;
