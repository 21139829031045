import { useUploadDocumentsMutation } from 'services/documents/documents';

import { selectOrchestrate } from 'store';

import { useAppSelector } from 'hooks';

import { DocumentItem } from 'models/document.types';

export const useUploadDocument = () => {
  const [uploadDocuments, { isLoading }] = useUploadDocumentsMutation();
  const {
    user: { accessToken }
  } = useAppSelector(selectOrchestrate);

  const uploadFile = async (
    files: File[],
    formDataInfo: Record<string, string>
  ): Promise<{ data: DocumentItem; error: null } | { data: null; error: string }> => {
    let data = null;
    let error = null;
    try {
      const images = files?.filter((item) =>
        ['image/png', 'image/jpg', 'image/jpeg', 'image/heic'].includes(item.type)
      );
      if (images.length !== 1) {
        error = 'Please select one image';
        return { data, error };
      }

      const size = images.reduce((a, b) => a + b.size, 0);
      if (size > 10000000) {
        error = 'Image size is larger than 10MB';
        return { data, error };
      }

      let convertedFile = images[0];
      if (convertedFile.type === 'image/heic') {
        const heic2any = (await import('heic2any')).default;
        const imageBlob = await heic2any({
          blob: convertedFile,
          toType: 'image/jpeg'
        });

        // Create a new File object with the converted image
        convertedFile = new File(
          [imageBlob as Blob],
          convertedFile.name.replace('.heic', '.jpeg'),
          {
            type: 'image/jpeg'
          }
        );
      }

      const formData = new FormData();
      formData.append('patientDocumentFile', convertedFile);
      for (const key in formDataInfo) {
        const element = formDataInfo[key];
        formData.append(key, element);
      }
      const res = await uploadDocuments({ body: formData, accessToken }).unwrap();
      data = res.data;
      return { data, error };
    } catch (e: unknown) {
      error =
        (e as Error)?.message ||
        (e as { data: { errorCode: number; message: string }; status: number })?.data?.message ||
        'Something went wrong';
      return { data: null, error };
    }
  };
  return { isLoading, uploadFile };
};
