import { useEffect } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import {
  useGetCreditBalanceQuery,
  useGetCreditCardInformationQuery,
  useLazyPreviewChangeSubscriptionQuery,
  useUpdateDefaultPaymentMethodMutation,
  useUpdatePaymentProfileMutation
} from 'services/myAccount/myAccount';

import { selectLookup, selectOrchestrate } from 'store';
import { ValidCouponCodes } from 'store/signup/signup.types';

import AdvantageItem from 'features/AdvantageItem';
import PaymentMethods from 'features/PaymentMethods';
import { notifySuccess } from 'shared/Toast/Toast';

import { useAppSelector } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { WM_DUMMY_ADVANTAGES } from 'utils/constants';
import { PlanCodes } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { getDataForPayment } from '../Checkout/checkout.settings';
import Heading from '../parts/Heading';
import PaymentSummaryCard from '../parts/PaymentSummaryCard';

const MigrationCheckout: React.FC<{ onContinue: () => void }> = ({ onContinue }) => {
  const { send, isLoading: isSendingOrchestrate } = useSubmitOrchestrateForm();
  const { data: balance, isFetching: isGettingCreditBalance } = useGetCreditBalanceQuery();
  const { data: creditCardData, isFetching, refetch } = useGetCreditCardInformationQuery();
  const [updateDefaultPaymentMethod, { isLoading: isUpdatingDefaultPaymentMethod }] =
    useUpdateDefaultPaymentMethodMutation();
  useUpdatePaymentProfileMutation();

  const {
    payment: { product_price_point_handle, coupon_codes, product_handle }
  } = useAppSelector(selectOrchestrate);

  const [getPreviewChangeSubscription] = useLazyPreviewChangeSubscriptionQuery();

  const { membershipPlans } = useAppSelector(selectLookup);

  const WMPLan = membershipPlans.find((p) => p.planCode === PlanCodes.WeightManagementMembership);
  const WMPricePoints = WMPLan?.pricePoints;
  const selectedPP = WMPricePoints?.find(
    (pp) => pp.planPricePointId === product_price_point_handle
  );

  const data = selectedPP
    ? getDataForPayment(selectedPP, coupon_codes[0] as ValidCouponCodes)
    : null;

  const handleFavoritePaymentMethod = (id: string, cb?: () => void) => {
    if (id) {
      updateDefaultPaymentMethod({ id })
        .unwrap()
        .then((r) => {
          refetch()
            .unwrap()
            .then(() => {
              notifySuccess(r.message ?? 'Updated default payment method');
              cb?.();
            });
        })
        .catch(handleRequestCatch);
    }
  };

  const handleUpdateMyAccount = () => {
    send(
      'payment',
      { coupon_codes, product_price_point_handle, product_handle },
      onContinue,
      'USER'
    );
  };

  const isDisabled = isUpdatingDefaultPaymentMethod || isFetching;
  const creditBalance = balance?.data.creditBalance;

  useEffect(() => {
    !!WMPLan &&
      getPreviewChangeSubscription({
        planId: WMPLan._id,
        planPricePointId: product_price_point_handle
      });
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <Heading category="Sign up" title="Confirm and pay" />
      <div className="flex gap-6 max-md:flex-col md:gap-11">
        <div className="flex flex-col gap-8 md:grid md:grid-cols-2">
          {!!data && (
            <div className="max-md:self-stretch">
              <PaymentSummaryCard data={data} />
            </div>
          )}

          <div className="">
            <div className="flex flex-col gap-2">
              <h2 className="font-bold text-primary-700">
                LifeMD GLP-1 Weight Management ${selectedPP?.paymentInterval.qty}-Month Program:
              </h2>
              {WM_DUMMY_ADVANTAGES.map((el) => (
                <AdvantageItem advantage={el} key={el} showCustomIcon />
              ))}
            </div>
          </div>
          <div className="col-span-2 md:mx-auto md:w-2/3">
            <PaymentMethods
              creditBalance={creditBalance}
              isLoading={isGettingCreditBalance}
              paymentMethods={creditCardData?.data ?? []}
              onAddMethod={refetch}
              onUpdateFavoritePaymentMethod={handleFavoritePaymentMethod}
            />
          </div>
        </div>
      </div>
      <Common.Button
        className="mt-auto md:mx-auto md:mt-4"
        color="blue"
        dataTestId="confirm_btn"
        disabled={isDisabled}
        isLoading={isSendingOrchestrate}
        fullWidthOnMobile
        onClick={handleUpdateMyAccount}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default MigrationCheckout;
