import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce, useTitle, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';

import { useUpdateHealthMetricsMutation } from 'services/healthMetrics/healthMetrics';
import {
  HealthMetrics as HealthMetricsProps,
  RequireOnlyOne
} from 'services/healthMetrics/healthMetrics.types';
import { useGetHealthProfileQuery } from 'services/healthProfile/healthProfile';

import { selectHealthProfile, selectUser } from 'store';
import { setHealthProfile } from 'store/healthProfile/healthProfileSlice';
import {
  AllergyItemProps,
  ChronicDiseasesItemProps,
  HealthConditionsItemProps,
  MedicationItemProps,
  MifProps,
  WomenHealthProps
} from 'store/mif/mif.types';

import MyChartTitle from 'features/MyChartTitle';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import Allergies from 'widgets/mif/Allergies';
import ChronicDiseases from 'widgets/mif/ChronicDiseases';
import HealthConditions from 'widgets/mif/HealthConditions';
import Medications from 'widgets/mif/Medications';
import SocialHistory from 'widgets/mif/SocialHistory';
import WomensHealth from 'widgets/mif/WomensHealth';
import HeaderText from 'widgets/myChart/HeaderText';
import MyChartHealthMetrics from 'widgets/myChart/HealthMetrics';
import MyChartMedicalIntakeForm from 'widgets/myChart/HealthProfile/MedicalIntakeForm';

import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import useWeightManagement from 'hooks/useWeightManagement';
import useWidth from 'hooks/useWidth';
import { FeatureFlag, HealthProfileNavItem, PathName, SexAtBirth } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';
import { AT_LEAST_ONE_LETTER_REGEXP } from 'utils/regExp';

import { LinkProps } from './healthProfile.types';

const HealthProfile: React.FC = () => {
  useTitle('LifeMD - Health profile');
  const [socialHistoryData, setSocialHistoryData] = useState<MifProps['socialHistory']>([]);
  const [allergyListData, setAllergyListData] = useState<(AllergyItemProps & { id: string })[]>([]);
  const [medicationsData, setMedicationsData] = useState<
    (MedicationItemProps & { id: string; isPrescription: boolean })[]
  >([]);
  const [healthConditionsData, setHealthConditionsData] = useState<HealthConditionsItemProps[]>([]);
  const [chronicDiseasesData, setChronicDiseasesData] = useState<ChronicDiseasesItemProps[]>([]);
  const [womenHealthData, setWomenHealthData] = useState<WomenHealthProps>({
    approxStartDate: '',
    name: '0',
    startDate: ''
  });
  const { isWeightManagement } = useWeightManagement();
  const { isMobile } = useWidth();
  const { isLoading } = useGetHealthProfileQuery();
  const [updateHealthMetrics, { isLoading: updateHealthMetricsLoading }] =
    useUpdateHealthMetricsMutation();
  const [displayAlert, toggleDisplayAlert] = useToggle(true);
  const isEnabledHealthMetricsFeature = useFlag(FeatureFlag.HealthMetrics);
  const isMedicalIntakeFormFeature = useFlag(FeatureFlag.MedicalIntakeForm);

  const { medicalIntake } = useAppSelector(selectHealthProfile);
  const { sexAtBirth } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [links, setLinks] = useState<LinkProps[]>([
    ...((isMedicalIntakeFormFeature
      ? [{ icon: 'list-view', label: HealthProfileNavItem.MedicalIntakeForm }]
      : []) as LinkProps[]),
    ...((isEnabledHealthMetricsFeature && isWeightManagement
      ? [{ icon: 'reports', label: HealthProfileNavItem.HealthMetrics }]
      : []) as LinkProps[]),
    { icon: 'heartbeat', label: HealthProfileNavItem.HealthConditions },
    { icon: 'lungs', label: HealthProfileNavItem.ChronicDiseases },
    ...((sexAtBirth === SexAtBirth.Female
      ? [
          {
            count: 0,
            icon: 'female',
            label: HealthProfileNavItem.WomenHealth
          }
        ]
      : []) as LinkProps[]),
    { icon: 'martini', label: HealthProfileNavItem.SocialHistory },
    { count: 0, icon: 'prescription-outline', label: HealthProfileNavItem.Medications },
    { count: 0, icon: 'allergy', label: HealthProfileNavItem.Allergies }
  ]);
  const navigate = useNavigate();
  const tab = useQuery().get('tab') ?? (isMobile ? null : links[0].label);

  const navigateTo = (el: LinkProps) => {
    navigate({
      pathname: PathName.HealthProfile,
      search: `tab=${el.label}`
    });
  };

  const handleUpdateHealthProfile = (metrics: RequireOnlyOne<HealthMetricsProps>) => {
    updateHealthMetrics({
      metrics
    })
      .unwrap()
      .then(() => {
        let sentItems = Cookies.get('mifSentItems');
        const keyToUpdate = Object.keys(metrics)[0];
        if (!sentItems?.includes(keyToUpdate)) {
          sentItems = sentItems ? sentItems + keyToUpdate : keyToUpdate;
          Cookies.set('mifSentItems', sentItems, { expires: 7 });
        }
        dispatch(
          setHealthProfile({
            medicalIntake: {
              ...medicalIntake,
              ...metrics
            }
          })
        );
      })
      .catch(handleRequestCatch);
  };

  const handleUpdatedMedicalIntake = () => {
    setSocialHistoryData(medicalIntake.socialHistory);
    if (
      !medicationsData.length &&
      (medicalIntake.medications.prescription.length ||
        medicalIntake.medications.supplements.length)
    ) {
      setMedicationsData(
        medicalIntake.medications.prescription
          .map((el) => ({
            ...el,
            id: nanoid(),
            isPrescription: true
          }))
          .concat(
            medicalIntake.medications.supplements.map((el) => ({
              ...el,
              id: nanoid(),
              isPrescription: false
            }))
          )
      );
    }
    if (!allergyListData.length && medicalIntake.allergyList.length) {
      setAllergyListData(medicalIntake.allergyList.map((el) => ({ ...el, id: nanoid() })));
    }
    setWomenHealthData(medicalIntake.womenHealth);
    setHealthConditionsData(medicalIntake.healthConditions);
    setChronicDiseasesData(medicalIntake.chronicDiseases);
    setLinks(
      links.map((el) => {
        switch (el.label) {
          case HealthProfileNavItem.Allergies:
            return { ...el, count: medicalIntake.allergyList.length };
          case HealthProfileNavItem.Medications:
            return {
              ...el,
              count:
                medicalIntake.medications.supplements.length +
                medicalIntake.medications.prescription.length
            };
          default:
            return el;
        }
      })
    );
  };

  const handleUpdatedSocialHistoryData = () => {
    if (JSON.stringify(socialHistoryData) !== JSON.stringify(medicalIntake.socialHistory)) {
      handleUpdateHealthProfile({ socialHistory: socialHistoryData });
    }
  };

  const handleUpdatedWomensHealthData = () => {
    if (JSON.stringify(womenHealthData) !== JSON.stringify(medicalIntake.womenHealth)) {
      handleUpdateHealthProfile({ womenHealth: womenHealthData });
    }
  };

  const handleUpdateAllergyList = () => {
    const mappedAllergyList = allergyListData.map(({ id, ...restItem }) => restItem);
    const isAllergyListChanged =
      JSON.stringify(mappedAllergyList) !== JSON.stringify(medicalIntake.allergyList);
    const isInvalidName = mappedAllergyList.some(
      (el) => el.name.trim().length < 3 || !el.name.match(AT_LEAST_ONE_LETTER_REGEXP)
    );

    if (isAllergyListChanged && !isInvalidName) {
      handleUpdateHealthProfile({ allergyList: mappedAllergyList });
    }
  };

  const handleUpdatePrescriptionData = () => {
    const filterAndMapMedications = (isPrescription: boolean) =>
      medicationsData
        .filter((el) => el.isPrescription === isPrescription)
        .map(({ id, isPrescription, ...restItem }) => restItem);

    const medications = {
      prescription: filterAndMapMedications(true),
      supplements: filterAndMapMedications(false)
    };

    const isMedicationsChanged =
      JSON.stringify(medications) !== JSON.stringify(medicalIntake.medications);
    const isInvalidName = medicationsData.some(
      (el) => el.name.trim().length < 3 || !el.name.match(AT_LEAST_ONE_LETTER_REGEXP)
    );

    if (isMedicationsChanged && !isInvalidName) {
      handleUpdateHealthProfile({ medications });
    }
  };

  const handleUpdatedHealthConditionsData = () => {
    if (JSON.stringify(healthConditionsData) !== JSON.stringify(medicalIntake.healthConditions)) {
      handleUpdateHealthProfile({ healthConditions: healthConditionsData });
    }
  };

  const handleUpdateChronicDiseases = () => {
    if (JSON.stringify(chronicDiseasesData) !== JSON.stringify(medicalIntake.chronicDiseases)) {
      handleUpdateHealthProfile({ chronicDiseases: chronicDiseasesData });
    }
  };

  useEffect(handleUpdatedMedicalIntake, [medicalIntake]);

  useDebounce(handleUpdatedSocialHistoryData, 1500, [socialHistoryData]);

  useDebounce(handleUpdatedWomensHealthData, 1500, [womenHealthData]);

  useDebounce(handleUpdatedHealthConditionsData, 1500, [healthConditionsData]);

  useDebounce(handleUpdateAllergyList, 1500, [allergyListData]);

  useDebounce(handleUpdatePrescriptionData, 1500, [medicationsData]);

  useDebounce(handleUpdateChronicDiseases, 1500, [chronicDiseasesData]);

  const tabContent: { [key in HealthProfileNavItem]: ReactElement } = {
    [HealthProfileNavItem.MedicalIntakeForm]: <MyChartMedicalIntakeForm />,
    [HealthProfileNavItem.HealthMetrics]: <MyChartHealthMetrics />,
    [HealthProfileNavItem.HealthConditions]: (
      <>
        <HeaderText
          description="Have you had any of the following health conditions?"
          title="Health conditions"
        />
        <HealthConditions
          healthConditions={healthConditionsData}
          loading={updateHealthMetricsLoading}
          onUpdate={setHealthConditionsData}
        />
      </>
    ),
    [HealthProfileNavItem.ChronicDiseases]: (
      <>
        <HeaderText
          description="Have you had any of the following medical conditions?"
          title="Chronic diseases"
        />
        <ChronicDiseases
          chronicDiseases={chronicDiseasesData}
          loading={updateHealthMetricsLoading}
          onUpdate={setChronicDiseasesData}
        />
      </>
    ),
    [HealthProfileNavItem.SocialHistory]: (
      <>
        <HeaderText
          description="Have you used any of the following? Your information will only be used for medical purposes."
          title="Social history"
        />
        <SocialHistory
          loading={updateHealthMetricsLoading}
          values={socialHistoryData}
          onUpdate={setSocialHistoryData}
        />
      </>
    ),
    [HealthProfileNavItem.Medications]: (
      <>
        <HeaderText
          description="Do you have any medications or supplements that you are currently taking?"
          title="Medications"
        />
        <Medications
          loading={updateHealthMetricsLoading}
          medications={medicationsData}
          onUpdate={setMedicationsData}
        />
      </>
    ),
    [HealthProfileNavItem.Allergies]: (
      <>
        <HeaderText description="Please list all allergies." title="Allergies" />
        <Allergies
          allergyList={allergyListData}
          loading={updateHealthMetricsLoading}
          onUpdate={setAllergyListData}
        />
      </>
    ),
    [HealthProfileNavItem.WomenHealth]: (
      <>
        <HeaderText description="Which of the following applies to you?" title="Women's health" />
        <WomensHealth
          onUpdate={setWomenHealthData}
          {...womenHealthData}
          loading={updateHealthMetricsLoading}
        />
      </>
    )
  };
  return (
    <FadeWrapper className="md:p-8">
      <Loader isVisible={isLoading || updateHealthMetricsLoading} />
      <MyChartTitle icon="tag-person" text="Health profile" />
      {displayAlert && (
        <Common.Alert type="info" colorableBackground onClose={toggleDisplayAlert}>
          Add any information about yourself to help your provider care for you.
        </Common.Alert>
      )}
      <div className="mt-4 flex gap-8">
        {(!isMobile || (!tab && isMobile)) && (
          <nav className="w-full md:w-[260px]" data-testid="nav_menu">
            {links.map((el) =>
              isMobile ? (
                <button
                  className="flex w-full items-center gap-2 py-4 text-mBase font-bold text-gray"
                  data-testid="nav_link"
                  key={el.label}
                  onClick={() => navigateTo(el)}
                >
                  <Common.Icon className="text-gray-700" name={el.icon} />
                  <span className="flex-1 text-left text-gray-700">{el.label}</span>
                  {!!el.count && (
                    <span className="flex h-4 min-w-[16px] items-center justify-center rounded-full bg-gray-400 px-1 text-2xs font-semibold text-white">
                      {el.count}
                    </span>
                  )}
                  <Common.Icon name="arrow-right-small" />
                </button>
              ) : (
                <Common.MenuVertical
                  active={el.label === tab}
                  icon={el.icon}
                  key={el.label}
                  label={el.label}
                  size="lg"
                  unread={el.count}
                  onClick={() => navigateTo(el)}
                />
              )
            )}
          </nav>
        )}
        {tab && (
          <div
            className="mr-auto flex w-full flex-col gap-2 md:max-w-[480px]"
            data-testid="hp_page_content"
          >
            {tabContent[tab as HealthProfileNavItem] || null}
          </div>
        )}
      </div>
    </FadeWrapper>
  );
};

export default HealthProfile;
