import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import { LINK_TO_FAQ } from 'constants/externalLinks';
import useWidth from 'hooks/useWidth';
import { RED_FLAGS } from 'utils/constants';
import { isQueueFlow } from 'utils/helpers';

import { RedFlagsTypes } from './redFlags.types';

import CalendarSvg from 'assets/icons/ambulance.svg?react';

const RedFlags: React.FC<RedFlagsTypes> = ({ onSelect, onSkip, selectedFlow }) => {
  const { isMobile } = useWidth();
  const isQueue = selectedFlow && isQueueFlow(selectedFlow);

  return (
    <div
      className={classNames(
        'rounded-2xl bg-white p-4',
        selectedFlow ? 'max-md:border md:px-12 md:shadow' : 'md:p-0'
      )}
    >
      <CalendarSvg className="mx-auto text-primary-100 md:mt-4 md:size-[170px]" />
      <h2
        className="mt-6 text-mLg font-bold text-primary-700 md:mt-4 md:text-xl"
        data-testid="heading"
      >
        LifeMD virtual appointments are not intended for medical emergencies or for certain health
        conditions.
      </h2>
      <p className="mt-6 font-medium md:mt-4">
        If you or a loved one are experiencing any of the following medical issues or any other
        critical or life-threatening situation, call 911 or your local emergency service right away:
      </p>
      <ul
        className="ml-4 mt-6 flex list-disc flex-col flex-wrap gap-x-4 font-bold md:mx-4 md:ml-6 md:mt-4 md:max-h-[250px]"
        data-testid="red_flags_list"
      >
        {RED_FLAGS.map((item) => (
          <li className="mb-0.5 md:mb-1 md:w-[240px]" key={nanoid()}>
            {item}
          </li>
        ))}
      </ul>
      <div className="mt-4">
        <Common.Alert type="info" colorableBackground>
          LifeMD does not provide prescriptions or refills for controlled substances such as
          benzodiazepines, narcotics or amphetamines. Read more in our&nbsp;
          <a className="underline" href={LINK_TO_FAQ} target="_blank">
            FAQ
          </a>
          .
        </Common.Alert>
      </div>
      <div
        className={classNames(
          'flex flex-col items-center justify-center gap-x-4 gap-y-3 max-md:mt-6 md:mx-auto md:mb-4 md:mt-10',
          {
            'md:max-w-[190px]': selectedFlow,
            'mt-12 md:max-w-[388px] md:flex-row-reverse': !selectedFlow
          }
        )}
      >
        <Common.Button
          className={isQueue || !selectedFlow ? undefined : '!w-full'}
          color="blue"
          data-testid="agree_or_and_continue_btn"
          dataTestId="agree_or_and_continue_btn"
          postIcon={isQueue ? undefined : 'arrow-right'}
          fullWidthOnMobile
          onClick={onSelect}
        >
          {!isMobile && isQueue ? 'Continue' : 'Agree and continue'}
        </Common.Button>
        {onSkip && (
          <Common.Button
            color={isQueue ? 'blue' : 'white-alt'}
            dataTestId="cancel_btn"
            style={isQueue ? 'text-only' : undefined}
            fullWidthOnMobile
            onClick={onSkip}
          >
            Cancel appointment
          </Common.Button>
        )}
      </div>
    </div>
  );
};

export default RedFlags;
