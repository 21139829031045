import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { Props } from './radioGroup.types';

const RadioGroup = <T extends string | number | boolean>({
  isLoading,
  items,
  onSelect,
  selectedItem,
  wrapperClassName = ''
}: Props<T>) => {
  return (
    <div className={classNames('flex flex-col gap-2', wrapperClassName)}>
      {items.map((item) => (
        <div
          // removed progress-wrapper class for now as client asked for it
          className={classNames(item.value === selectedItem && isLoading ? 'progress-wrapper' : '')}
          key={typeof item.label === 'string' ? item.label : String(item.value)}
        >
          <Common.RichRadioButton
            checked={item.value === selectedItem}
            disabled={isLoading}
            label={item.label}
            name={String(item.value)}
            onChange={() => onSelect(item.value)}
          />
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
