import { FieldValues, useController } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import { useGetStatesQuery } from 'services/lookup/lookup';

import { selectStates } from 'store';

import { useAppSelector } from 'hooks';

const StateSelect = <TFormValues extends FieldValues>({
  control,
  name,
  dataTestId,
  label = '',
  size,
  hideSuccessState = true
}: TFormValues) => {
  useGetStatesQuery();
  const states = useAppSelector(selectStates);

  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name,
    rules: {
      required: {
        message: 'State is required',
        value: true
      }
    }
  });

  const options = states.map((state) => ({
    label: state.label,
    value: state.label
  }));

  return (
    <Common.SelectAlt
      autoComplete="address-level1"
      {...field}
      dataTestId={dataTestId}
      error={fieldState.error}
      hideSuccessState={hideSuccessState}
      label={label}
      options={options}
      placeholder="Select..."
      size={size}
    />
  );
};

export default StateSelect;
