import { useNavigate, useParams } from 'react-router-dom';

import {
  useDeletePaymentMethodMutation,
  useUpdatePaymentProfileMutation
} from 'services/myAccount/myAccount';

import PaymentMethodForm from 'features/PaymentMethodForm/PaymentMethodForm';
import { notifySuccess } from 'shared/Toast/Toast';
import { buildBodyForPaymentUpdate } from 'widgets/myAccount/BillingDetails/billingDetails.settings';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';

import { useQuery } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

const PaymentMethod = () => {
  const { action } = useParams();
  const query = useQuery();
  const isPayPalConnected = query.get('pp-status');
  const paymentProfileId = query.get('ppid');
  const [updatePaymentProfile, { isLoading: isLoadingUpdate }] = useUpdatePaymentProfileMutation();
  const [deletePaymentMethod, { isLoading: isDeletingPaymentMethod }] =
    useDeletePaymentMethodMutation();

  const navigate = useNavigate();
  const onFormSubmit = (data: PaymentFormFields | string) => {
    const method = action === 'edit' ? 'PUT' : 'POST';
    updatePaymentProfile({
      ...buildBodyForPaymentUpdate(data, method),
      paymentMethod: typeof data === 'string' ? 'paypal_account' : 'credit_card',
      ...(paymentProfileId && { paymentProfileId })
    })
      .unwrap()
      .then((res) => {
        notifySuccess(res.message);
        navigate(PathName.BillingDetails);
      })
      .catch(handleRequestCatch);
  };

  const handleDeletePaymentMethod = () => {
    if (paymentProfileId) {
      deletePaymentMethod({ id: paymentProfileId })
        .unwrap()
        .then(() => navigate(PathName.BillingDetails));
    }
  };

  return (
    <div className="mx-auto mt-8 max-w-[500px] md:mt-20">
      <PaymentMethodForm
        action={action as 'add' | 'edit'}
        isLoading={isLoadingUpdate || isDeletingPaymentMethod}
        isPayPalConnected={isPayPalConnected === '1'}
        onCancel={() => navigate(-1)}
        onConfirm={onFormSubmit}
        onDelete={handleDeletePaymentMethod}
      />
    </div>
  );
};

export default PaymentMethod;
