import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { PricePoint } from 'models/plans.types';

export const getDynamicPPContent = (
  pricePoint: PricePoint
): {
  couponCode?: string;
  discountTag: JSX.Element | null;
  subheaderText: string;
  totalValue: string;
} => {
  switch (pricePoint.paymentInterval.qty) {
    case 1:
      return {
        discountTag: null,
        subheaderText: 'Billed monthly',
        totalValue: pricePoint.totalCost
      };
    case 3:
      return {
        couponCode: 'GLP250OFF',
        discountTag: <Common.ColorTag color="secondary" text="Save 25%" />,
        subheaderText: `Save $${(Number(pricePoint.totalCost) * parseFloat('25%')) / 100} Today`,
        totalValue: `${(Number(pricePoint.totalCost) * parseFloat('75%')) / 100}`
      };
    case 6:
    case 12:
      return {
        couponCode: 'GLP150OFF',
        discountTag: <Common.ColorTag color="secondary" text="Save 50%" />,
        subheaderText: `Save $${(Number(pricePoint.totalCost) * parseFloat('50%')) / 100} Today`,
        totalValue: `${(Number(pricePoint.totalCost) * parseFloat('50%')) / 100}`
      };
    default:
      return {
        discountTag: null,
        subheaderText: 'Billed monthly',
        totalValue: pricePoint.totalCost
      };
  }
};

const PlanCardItem: React.FC<{
  estimatedWL?: string | number;
  isSelected: boolean;
  pricePoint: PricePoint;
}> = ({ isSelected, pricePoint, estimatedWL }) => {
  const wrapperClassName = classNames(
    'p-4 rounded-2xl border flex flex-col gap-[10px] transition-all max-md:min-h-[102px]',
    isSelected ? 'border-secondary border-2 outline-2 bg-secondary-50' : 'bg-white border-gray-200'
  );

  const itemClassName =
    'flex items-start gap-1 text-mSm leading-3 text-primary-700 md:text-sm items-center';

  const { discountTag, totalValue, subheaderText } = getDynamicPPContent(pricePoint);

  return (
    <div className={wrapperClassName}>
      <div className="flex w-full flex-col gap-1">
        <div className="flex justify-between text-primary-700">
          <div className="flex min-w-fit items-center gap-2 font-bold md:flex-col md:items-start">
            <h3 className="text-base md:text-lg">
              {pricePoint.paymentInterval.qty} Month GLP-1 Program
            </h3>
            {discountTag}
          </div>
          <span className="text-base font-bold">
            {Number(pricePoint.totalCost) > Number(totalValue) && (
              <span className="mr-1 font-medium text-gray line-through">
                ${pricePoint.totalCost}
              </span>
            )}
            ${totalValue}
          </span>
        </div>
        {pricePoint.paymentInterval.qty === 12 && (
          <Common.ColorTag color="violet" size="sm" text="Includes free scale" />
        )}
        <span className="block text-left text-sm text-primary">{subheaderText}</span>
        {/* <span className="text-left text-m2xs text-gray md:text-xs">
          Cost of medication not included.
        </span> */}
      </div>
      {!!estimatedWL && (
        <div className={itemClassName}>
          <Common.Icon className="w-[18px]" name="scale-outline" />
          <p className="font-bold">Estimated weight loss:</p>
          <span className="ml-1">{estimatedWL} lbs</span>
        </div>
      )}
    </div>
  );
};

export default PlanCardItem;
