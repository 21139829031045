import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';

import { useSortableData } from 'hooks/useSortableTable';
import useWidth from 'hooks/useWidth';

import { TableProps } from './table.types';

const Table: React.FC<TableProps> = ({
  onClickRow,
  data,
  boldCols = ['date', 'type'],
  headers,
  tableClassName,
  tableRowClassName,
  hideActionsHeader = false,
  hideActions = false,
  customCol,
  handleSort,
  dataTestId
}) => {
  const { isMobile } = useWidth();
  const { requestSort } = useSortableData();

  const tableClassNames = classNames('overflow-auto', tableClassName);
  const tableHeaderClassName = classNames(
    'sticky top-0 flex items-center bg-gray-100 text-mSm font-medium md:bg-gray-50 md:px-3 md:text-sm md:font-bold min-w-fit',
    !!headers?.length ? 'px-3' : 'px-4'
  );
  const tableBodyClassName = 'max-h-[335px]';
  const tableRowClassNames = classNames(
    'flex items-center bg-white px-3 py-4 text-mSm font-medium md:text-sm min-w-fit border-b',
    { 'cursor-pointer': !!onClickRow },
    tableRowClassName
  );
  const tableColumnClassName = classNames(
    'flex items-center gap-1 md:px-3 flex-1 min-w-[80px] md:w-[25%]',
    {
      'flex-1 min-w-[120px] md:w-[25%]': !headers,
      'justify-center': isMobile && !!headers?.length && headers.length > 3
    }
  );

  if (!headers) {
    headers = data
      .reduce(
        (arr: string[], el) => arr.concat(...Object.keys(el).filter((item) => !arr.includes(item))),
        []
      )
      .map((el) => ({ id: el, size: '120px' }));
  }

  return data.length ? (
    <FadeWrapper className={tableClassNames}>
      <header className={tableHeaderClassName}>
        {headers.map((el) => (
          <button
            className={classNames(tableColumnClassName, 'py-4')}
            key={nanoid()}
            style={{ minWidth: el?.size ?? '120px' }}
            onClick={() => {
              const sortOrder = requestSort(el.id);
              handleSort?.({ sortField: el.id, sortOrder });
            }}
          >
            <span className="font-bold first-letter:uppercase">{el.label || el.id}</span>
            <Common.Icon className="size-3 text-primary-600" name="sort" />
          </button>
        ))}
        {!!onClickRow && !hideActions && (
          <button
            className={classNames(tableColumnClassName, 'w-full py-4', {
              hidden: hideActionsHeader
            })}
          >
            <span className="mx-auto font-bold">Actions</span>
          </button>
        )}
      </header>
      <div className={tableBodyClassName}>
        {data.map((el) => (
          <div
            className={tableRowClassNames}
            data-testid={dataTestId}
            key={nanoid()}
            onClick={() => onClickRow?.(el)}
          >
            {headers?.map((key) => (
              <span
                className={classNames(tableColumnClassName, {
                  '!justify-start pl-3': isMobile && !!headers?.length && headers.length > 3,
                  'font-bold': boldCols.includes(key.id)
                })}
                key={nanoid()}
                style={{ minWidth: key?.size ?? '120px' }}
              >
                {customCol?.({ content: el[key.id], name: key.id }) || el[key.id]}
              </span>
            ))}
            {!!onClickRow && !hideActions && (
              <span className={classNames(tableColumnClassName, 'w-full')}>
                <Common.Icon className="mx-auto" name="pencil" />
              </span>
            )}
          </div>
        ))}
      </div>
    </FadeWrapper>
  ) : null;
};

export default Table;
