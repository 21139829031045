import React, { PropsWithChildren } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

const Collapse: React.FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <div className="flex flex-col gap-4 border-b py-5">
      <button className="flex w-full items-center justify-between" onClick={toggleIsOpen}>
        <span className="font-semibold">{title}</span>
        <Common.Icon name={isOpen ? 'arrow-down' : 'arrow-right'} />
      </button>
      {isOpen && children}
    </div>
  );
};

export default Collapse;
