import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import {
  useAddHealthMetricsMutation,
  useDeleteHealthMetricMutation,
  useUpdateHealthMetricsMutation
} from 'services/healthMetrics/healthMetrics';

import MeasurementDateInput from 'shared/form/MeasurementDateInput';
import NumberInput from 'shared/form/NumberInput';

import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import BasicConfirmation from '../BasicConfirmation';

import { CholesterolEditFormValues, CholesterolEditModalProps } from './cholesterolEdit.types';

const CholesterolEdit: React.FC<CholesterolEditModalProps> = ({ isOpen, onClose, element }) => {
  const [addHealthMetrics, { isLoading: isLoadingAdd }] = useAddHealthMetricsMutation();
  const [updateHealthMetrics, { isLoading: isLoadingUpdate }] = useUpdateHealthMetricsMutation();
  const [deleteHealthMetric, { isLoading: isLoadingDelete }] = useDeleteHealthMetricMutation();
  const { isMobile } = useWidth();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const labelDirection = isMobile ? 'col' : 'row';
  const { handleSubmit, control, reset, getValues } = useForm<CholesterolEditFormValues>({
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false
  });

  const isLoading = isLoadingAdd || isLoadingUpdate;

  const handleDelete = (cb: () => void) => {
    if (!element) return;

    deleteHealthMetric({
      collectedDate: dayjs(element.collectedDate).format(DateFormat.YYYY_MM_DD),
      metricType: 'cholesterol'
    })
      .unwrap()
      .then(cb)
      .catch(handleRequestCatch);
  };

  const onSubmit = (data: CholesterolEditFormValues) => {
    const body = {
      collectedDate: dayjs(data.date).format(DateFormat.YYYY_MM_DD),
      metrics: {
        cholesterol: {
          HDL: +data.HDL,
          LDL: +data.LDL,
          nonHDL: +data.nonHDL,
          total: +data.total
        }
      }
    };

    const performAction = (action: typeof addHealthMetrics | typeof updateHealthMetrics) => {
      action(body)
        .unwrap()
        .then(() => onClose(true))
        .catch(handleRequestCatch);
    };

    if (!element) {
      performAction(addHealthMetrics);
      return;
    }

    const isSameDay = dayjs(element.collectedDate).diff(dayjs(body.collectedDate), 'days') === 0;
    if (isSameDay) {
      performAction(updateHealthMetrics);
    } else {
      handleDelete(() => performAction(updateHealthMetrics));
    }
  };

  const handleClick = () => {
    if (
      dayjs(element?.collectedDate).format(DateFormat.YYYY_MM_DD) ===
      dayjs(getValues().date).format(DateFormat.YYYY_MM_DD)
    ) {
      handleSubmit(onSubmit)();
    } else {
      setShowConfirmPopup(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        HDL: element?.['metrics.cholesterol.HDL'] || '',
        LDL: element?.['metrics.cholesterol.LDL'] || '',
        date: dayjs(element?.collectedDate).format(DateFormat.MM_DD_YYYY),
        nonHDL: element?.['metrics.cholesterol.nonHDL'] || '',
        total: element?.['metrics.cholesterol.total'] || ''
      });
    } else {
      setShowConfirmPopup(false);
    }
  }, [isOpen]);

  return (
    <Common.Modal
      autoScroll={isMobile}
      close={onClose}
      description={`${element ? 'Edit' : 'Add'} ${isMobile ? 'cholesterol' : ' measurement'}`}
      isOpen={isOpen}
      size="base"
      title="Cholesterol"
      zIndex={30}
    >
      <BasicConfirmation
        confirmButtonText="Confirm"
        isLoading={isLoading}
        isOpen={showConfirmPopup}
        subHeaderText="You are potentially overwriting a previously recorded value by changing the date of another weight measurement"
        onClose={() => setShowConfirmPopup(false)}
        onConfirm={() => handleSubmit(onSubmit)()}
      />
      <div className="flex flex-col gap-6 p-0.5 md:pr-4">
        <NumberInput
          control={control}
          dataTestId="cholesterol_total_input"
          label="Total cholesterol"
          labelClassName={isMobile ? '' : '!font-bold'}
          labelDirection={labelDirection}
          name="total"
          postText="mg/dl"
          requiredErrorMsg="Total cholesterol is required"
        />
        <div className="flex gap-6 md:flex-col md:gap-4">
          <NumberInput
            control={control}
            dataTestId="hdl_input"
            label="HDL"
            labelDirection={labelDirection}
            name="HDL"
            postText="mg/dl"
            requiredErrorMsg="HDL is required"
          />
          <NumberInput
            control={control}
            dataTestId="non_hdl_input"
            label="Non-HDL"
            labelDirection={labelDirection}
            name="nonHDL"
            postText="mg/dl"
            requiredErrorMsg="Non-HDL is required"
          />
        </div>
        <NumberInput
          control={control}
          dataTestId="ldl_input"
          label="LDL"
          labelDirection={labelDirection}
          name="LDL"
          postText="mg/dl"
          requiredErrorMsg="LDL is required"
        />
        <MeasurementDateInput
          control={control}
          dataTestId="cholesterol_date"
          labelDirection={isMobile ? 'col' : 'row'}
          showTodayCheckbox={!element}
        />
        <div
          className={classNames(
            'flex flex-col gap-3 md:flex-row-reverse',
            isMobile ? 'mt-4' : 'mt-2'
          )}
        >
          <Common.Button
            color="blue"
            dataTestId="add_measurement_btn"
            isLoading={isLoading}
            size={isMobile ? 'lg' : 'sm'}
            fullWidthOnMobile
            onClick={handleClick}
          >
            {!element || isMobile ? 'Add' : 'Edit'} measurement
          </Common.Button>
          <Common.Button
            color={isMobile ? undefined : 'white-alt'}
            dataTestId="cancel_btn"
            size={isMobile ? 'lg' : 'sm'}
            type="button"
            fullWidthOnMobile
            onClick={() => onClose(false)}
          >
            Cancel
          </Common.Button>
          {element && !isMobile && (
            <Common.Button
              className="mr-auto"
              color="red-alt"
              dataTestId="delete_btn"
              isLoading={isLoadingDelete}
              preIcon="trash"
              size="sm"
              type="button"
              onClick={() => handleDelete(() => onClose(true))}
            >
              Delete
            </Common.Button>
          )}
        </div>
      </div>
    </Common.Modal>
  );
};

export default CholesterolEdit;
