import { useState } from 'react';

import RadioGroup from 'shared/RadioGroup';

import Heading from '../../parts/Heading';

const ITEMS = [
  {
    label: 'My diet and activity need a lot of work',
    value: 'my_diet_and_activity_need_a_lot_of_work'
  },
  { label: 'I have some healthy habits', value: 'i_have_some_healthy_habits' },
  { label: 'I mostly eat well and stay active', value: 'i_mostly_eat_well_and_stay_active' }
];

const HealthStatePicker: React.FC<{
  loading: boolean;
  onSelect: (v: string, cb?: () => void) => void;
}> = ({ onSelect, loading }) => {
  const [activeItem, setActiveItem] = useState('');

  const handleSelect = (v: string) => {
    if (v) {
      setActiveItem(v);
      onSelect(v, () => {
        setActiveItem('');
      });
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <Heading category="Eligibility" title="Which best describes you?" />
      <RadioGroup
        isLoading={loading}
        items={ITEMS}
        selectedItem={activeItem}
        onSelect={handleSelect}
      />
    </div>
  );
};

export default HealthStatePicker;
