export const downloadFile = async (accessToken: string, id: string) => {
  try {
    return await fetch(`${import.meta.env.VITE_BASE_URL}/documents/${id}/download`, {
      headers: {
        'API-KEY': import.meta.env.VITE_API_KEY || '',
        authorization: accessToken
      }
    })
      .then((r) => {
        if (r.status !== 200) {
          throw Error('Error, try again');
        }
        return r.blob();
      })
      .catch((e) => {
        throw Error(e.message);
      });
  } catch (error) {
    throw error;
  }
};
