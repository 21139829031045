import { useGetMembershipPlansQuery } from 'services/lookup/lookup';

import { selectLookup } from 'store';

import Loader from 'shared/Loader';
import Header from 'widgets/Header';

import { useAppSelector } from 'hooks';
import { useMigrateToWM } from 'hooks/useMigrateToWM';

import Content from './Content';

const MigrateToWM = () => {
  const { membershipPlans } = useAppSelector(selectLookup);
  const { steps, currentStep, moveToStep, loading, handleSelectInsurance } = useMigrateToWM();
  useGetMembershipPlansQuery(undefined, { skip: membershipPlans.length > 0 });

  return loading ? (
    <Loader isVisible />
  ) : (
    <div className="relative flex grow flex-col p-4">
      <Header
        flowLength={steps.length}
        step={steps.indexOf(currentStep)}
        onClickPrev={() => moveToStep('prev')}
      />
      <div className="grow">
        <Content
          loading={loading}
          moveToStep={moveToStep}
          step={currentStep}
          steps={steps}
          onSelectInsurance={handleSelectInsurance}
        />
      </div>
    </div>
  );
};

export default MigrateToWM;
