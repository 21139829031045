import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';

import PrescriptionProgress from 'features/PrescriptionProgress';
import { getStatusData } from 'widgets/wm/PrescriptionStatus/prescriptionStatus.settings';

import { PRESCRIPTION_TRACKING_URL } from 'constants/externalLinks';
import useWeightManagement from 'hooks/useWeightManagement';
import useWidth from 'hooks/useWidth';
import { DateFormat, FeatureFlag, ShippingStatuses, TaskStatuses } from 'utils/enums';
import { checkIfPrescriptionIsGLP, isAdminPrescription } from 'utils/helpers';

import { handleClickInstructions, handleDatePhrase } from './prescription.settings';
import { PrescriptionProps } from './prescription.types';

const Prescription: React.FC<PrescriptionProps> = ({
  prescription,
  createRenewalIsLoading,
  createSurveyIsLoading,
  onCreateSurvey,
  onCreateRenewal
}) => {
  const { isWeightManagement } = useWeightManagement();
  const { isMobile } = useWidth();
  const isEnabledGLP = useFlag(FeatureFlag.GLPPrescription);
  const format = isMobile ? DateFormat.MMM_DD : DateFormat.MMM_DD_YYYY;

  const title = isAdminPrescription(prescription)
    ? prescription.drug + ` (${prescription.drugDosage})`
    : prescription.medication.name;

  const titleClassName = classNames(
    'text-gray-700',
    checkIfPrescriptionIsGLP(prescription) && isEnabledGLP ? 'max-w-[250px]' : 'max-w-[300px]'
  );

  return (
    <div className="flex justify-between gap-3 py-4 max-md:flex-col md:items-center">
      <div className="flex gap-3">
        <div className="flex h-[64px] w-[80px] items-center justify-center rounded-2xl bg-primary-50 px-4">
          {isAdminPrescription(prescription) ? (
            <Common.Logo className="text-primary" name={prescription.brand} />
          ) : (
            <Common.Icon className="text-primary" name="prescription" />
          )}
        </div>
        <div className="flex flex-1 flex-col gap-0.5 md:flex-row md:items-center md:justify-between md:gap-2">
          <div>
            <p className={titleClassName} title={title}>
              {title}
            </p>
            {!isAdminPrescription(prescription) && prescription.directions && (
              <p className={titleClassName}>{`(${prescription.directions})`}</p>
            )}
            {!isAdminPrescription(prescription) && (
              <p className="text-mSm text-gray md:mt-1 md:text-sm">
                {handleDatePhrase(prescription, format)}
              </p>
            )}
            {!isAdminPrescription(prescription) && prescription?.auth_refills > 0 && (
              <p className="text-mSm text-gray md:mt-1 md:text-sm">
                {`${prescription.auth_refills} ${
                  prescription.auth_refills > 1 ? 'refills' : 'refill'
                } available`}
              </p>
            )}
          </div>
        </div>
      </div>
      {!isAdminPrescription(prescription) &&
        isEnabledGLP &&
        checkIfPrescriptionIsGLP(prescription) && (
          <div className="flex w-full grow flex-col p-4 max-md:mx-auto max-md:mb-7 max-md:mt-3 md:max-w-[340px] md:items-center">
            <PrescriptionProgress phases={getStatusData(prescription.trackingInfo)} />
          </div>
        )}
      <div className="flex gap-2">
        <div className="flex w-full flex-none flex-col items-end justify-center gap-2 self-stretch md:w-[160px]">
          {isEnabledGLP &&
            !isAdminPrescription(prescription) &&
            prescription.trackingInfo?.shippingStatus === ShippingStatuses.Delivered &&
            !prescription.survey?.checkInReceived &&
            !prescription.isRenewalCreated &&
            isWeightManagement && (
              <Common.Button
                color="blue"
                dataTestId="mark_as_received_btn"
                disabled={createSurveyIsLoading}
                size={isMobile ? 'md' : 'sm'}
                fullWidthOnMobile
                onClick={onCreateSurvey}
              >
                Mark as received
              </Common.Button>
            )}
          {!isAdminPrescription(prescription) && prescription.trackingInfo?.trackingNumber && (
            <Common.Button
              color={isMobile ? 'white' : 'white-alt'}
              dataTestId="view_tracking_btn"
              disabled={createSurveyIsLoading}
              size={isMobile ? 'md' : 'sm'}
              fullWidthOnMobile
              onClick={() =>
                window.open(
                  PRESCRIPTION_TRACKING_URL + prescription.trackingInfo?.trackingNumber,
                  '_blank'
                )
              }
            >
              View tracking
            </Common.Button>
          )}
          {!isAdminPrescription(prescription) && prescription.isCompound && (
            <Common.Button
              color={isMobile ? 'white' : 'white-alt'}
              dataTestId="instructions_btn"
              size={isMobile ? 'md' : 'sm'}
              fullWidthOnMobile
              onClick={handleClickInstructions}
            >
              Instructions
            </Common.Button>
          )}
          {isAdminPrescription(prescription) && prescription.orderUrl && (
            <Common.Button
              color={isMobile ? 'white' : 'white-alt'}
              size={isMobile ? 'md' : 'sm'}
              onClick={() => window.open(prescription.orderUrl)}
            >
              Manage prescription
            </Common.Button>
          )}
          {!isAdminPrescription(prescription) &&
            prescription.isRenewalCreated &&
            prescription.renewalTask?.status !== TaskStatuses.COMPLETED &&
            !prescription.renewalSurvey?.canceledAt &&
            prescription.renewalSurvey?.completedAt && (
              <Common.Button
                className="cursor-default text-green"
                preIcon="check"
                size={isMobile ? 'md' : 'sm'}
                fullWidthOnMobile
              >
                Renewal requested
              </Common.Button>
            )}
          {!isAdminPrescription(prescription) && prescription.isRenewalRequestAllowed && (
            <Common.Button
              color={isMobile ? 'white' : 'white-alt'}
              dataTestId="request_renewal_btn"
              disabled={createRenewalIsLoading}
              isLoading={createRenewalIsLoading}
              size={isMobile ? 'md' : 'sm'}
              fullWidthOnMobile
              onClick={onCreateRenewal}
            >
              Request renewal
            </Common.Button>
          )}
        </div>
        {/*<ExportPDF prescription={prescription} onPrint={printPDF} onSave={()=>onDownloadPDF(title)} />*/}
      </div>
    </div>
  );
};

export default Prescription;
