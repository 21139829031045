// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useRef } from 'react';

import { notifyError } from 'shared/Toast/Toast';

import { ChargifyFormProps } from './chargifyForm.types';

const ChargifyForm: React.FC<ChargifyFormProps> = ({ onHandlePaypalToken }) => {
  const chargifyForm = useRef();
  const chargify = useRef(new window.Chargify());
  const handleSubmit = () => {
    try {
      chargify.current.token(chargifyForm.current, onHandlePaypalToken, notifyError);
    } catch (error) {
      notifyError(error.message);
    }
  };

  useEffect(() => {
    chargify.current.load(
      {
        publicKey: import.meta.env.VITE_CHARGIFY_PUBLIC_KEY,
        selector: '#chargify-form',
        selectorForPayPalButton: '#pay-pal',
        serverHost: import.meta.env.VITE_CHARGIFY_SERVER,
        threeDSecure: false,
        // in case if we'll pass invalid cards, will uncomment
        // threeDSecure: import.meta.env.VITE_ENV === 'production',
        type: 'pay_pal'
      },
      {
        onPayPalAuthorized: handleSubmit,
        // this function is called on internal error related to PayPal
        onPayPalError: notifyError,
        onThreeDsConfigError: function (error) {
          notifyError(error.message || error);
        }
      }
    );
    return () => {
      chargify.current.unload();
    };
  }, [chargify]);

  return (
    <form ref={chargifyForm} onSubmit={handleSubmit}>
      <div id="pay-pal"></div>
    </form>
  );
};

export default ChargifyForm;
