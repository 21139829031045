import { useLottie } from 'lottie-react';

import illustration from 'assets/animations/wm-onboarding/phone-animated-doctor.json';

const options = {
  animationData: illustration,
  loop: 1,
  style: { width: 240 }
};

const PossibleWeightLoss: React.FC<{ possibleWL: string | number }> = ({ possibleWL }) => {
  const { View } = useLottie(options);

  return (
    <div className="flex h-full flex-col place-items-center gap-2 md:gap-6">
      {View}
      <h2 className="wm-signup-title text-center">
        We use modern science + medicine to craft your weight loss plan.
      </h2>
      <div className="flex flex-col gap-2 text-center text-mBase text-secondary-600">
        <span className="font-semibold">Your weight loss potential:</span>
        <span className="text-m2xl font-bold md:text-2xl">{possibleWL} Pounds</span>
      </div>
    </div>
  );
};

export default PossibleWeightLoss;
