import { useLottie } from 'lottie-react';

import { FlowTypes } from 'utils/enums';

import illustration from 'assets/animations/wm-onboarding/document-shield.json';

const options = {
  animationData: illustration,
  loop: 1,
  style: { width: 240 }
};

const Advantage: React.FC<{ selectedFlow: FlowTypes | 'authorized' }> = ({ selectedFlow }) => {
  const { View } = useLottie(options);

  return (
    <div className="flex h-full flex-col place-items-center justify-start gap-6">
      <div className="-mt-6">{View}</div>
      <h2 className="wm-signup-title text-center">Weight loss, without the uncertainty.</h2>
      <p className="text-center text-mBase text-secondary">
        {selectedFlow === FlowTypes.TripleTherapy
          ? 'We’re committed to providing transparency throughout your entire journey and are here for any questions you may have.'
          : 'The LifeMD Weight Management Program provides a tailored approach along with ongoing support that can help you reach your goals for good.'}
      </p>
    </div>
  );
};

export default Advantage;
