import { TouchEvent, useRef, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import PharmacyCard from 'widgets/Pharmacy/PharmacyList/PharmacyCard';

import { distanceByCoords } from 'utils/helpers';

import { PharmacyListProps } from './pharmacyList.types';

const PharmacyList: React.FC<PharmacyListProps> = ({
  handleInputChange,
  pharmaciesList,
  togglePharmacy,
  typeOnPause,
  isOpen,
  userPharmacy,
  userLocation,
  isFetching,
  searchValue,
  selectedPharmacyId
}) => {
  const computedPharmacies = userPharmacy
    ? [userPharmacy, ...pharmaciesList.filter((p) => p.pharmacyId !== userPharmacy.pharmacyId)]
    : pharmaciesList;

  const [isOpenList, setOpenList] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchStart(e.targetTouches[0].clientY);
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    const touch = touchStart - e.targetTouches[0].clientY;
    if (
      childRef.current &&
      childRef.current.contains(e.target as Node) &&
      childRef.current.scrollTop > 0
    ) {
      return;
    }
    setTouchEnd(touch);
  };
  const handleTouchEnd = () => {
    setOpenList(touchEnd > -50);
    setTouchStart(0);
  };

  return (
    <div className="pointer-events-none z-20 size-full flex-none max-md:absolute md:w-[320px]">
      <div className="pointer-events-auto relative z-10 m-4">
        <Common.SearchInput
          className="rounded-full shadow"
          disabled={isFetching}
          placeholder="Search by name, address, ZIP..."
          value={searchValue}
          onChange={handleInputChange}
        />
      </div>
      <div
        className={classNames(
          'pointer-events-auto flex size-full flex-col bg-white duration-300 ease-in-out max-md:absolute max-md:shadow md:h-[430px] md:max-h-full md:rounded-xl',
          {
            'max-md:hidden': isOpen
          },
          isOpenList ? 'top-0 max-md:pt-[28px]' : 'top-[calc(100%_-_235px)] max-md:rounded-t-3xl'
        )}
        ref={parentRef}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
      >
        <div className="mx-auto mb-8 mt-4 h-1.5 w-16 flex-none rounded-full bg-gray-200 md:hidden" />
        <div
          className={classNames('h-full divide-y md:overflow-auto', {
            'overflow-auto': isOpenList
          })}
          ref={childRef}
        >
          {pharmaciesList.length
            ? computedPharmacies.map((pharmacy) => (
                <PharmacyCard
                  distance={
                    userLocation && pharmacy.latitude && pharmacy.longitude
                      ? distanceByCoords(
                          pharmacy.latitude,
                          pharmacy.longitude,
                          userLocation.lat,
                          userLocation.lng
                        )
                      : undefined
                  }
                  isSelected={selectedPharmacyId === pharmacy.pharmacyId}
                  isUserPharmacy={userPharmacy?.pharmacyId === pharmacy.pharmacyId}
                  key={pharmacy.pharmacyId}
                  pharmacy={pharmacy}
                  onSelect={togglePharmacy}
                />
              ))
            : !isFetching &&
              typeOnPause && (
                <span className="mt-[52px] block p-2 text-center text-gray md:mt-auto">
                  No results...
                </span>
              )}
        </div>
      </div>
    </div>
  );
};

export default PharmacyList;
