import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import {
  AddInsuranceReqProps,
  AddInsuranceResProps,
  BenefitInsuranceStatusResProps,
  DeleteInsuranceReqProps,
  DeleteInsuranceResProps,
  GetInsurancesResProps,
  UpdateInsuranceReqProps
} from './insurance.types';

export const insuranceApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    addInsurance: build.mutation<AddInsuranceResProps, AddInsuranceReqProps>({
      query: ({ accessToken, ...body }) => ({
        body,
        ...(accessToken && {
          headers: {
            Authorization: accessToken
          }
        }),
        method: 'POST',
        url: '/insurances'
      })
    }),
    deleteInsurance: build.mutation<DeleteInsuranceResProps, DeleteInsuranceReqProps>({
      query: ({ id }) => ({
        method: 'DELETE',
        url: `/insurances/${id}`
      })
    }),
    getBenefitInsuranceStatus: build.query<BenefitInsuranceStatusResProps, void>({
      query: () => ({
        url: '/insurances/benefits-check'
      })
    }),
    getInsurances: build.query<GetInsurancesResProps, void>({
      query: () => ({
        url: '/insurances'
      })
    }),
    updateInsurance: build.mutation<DeleteInsuranceResProps, UpdateInsuranceReqProps>({
      query: ({ _id, ...body }) => ({
        body,
        method: 'PATCH',
        url: `/insurances/${_id}`
      })
    })
  }),
  reducerPath: 'insuranceApi',
  tagTypes: ['Insurance']
});

export const {
  useGetBenefitInsuranceStatusQuery,
  useAddInsuranceMutation,
  useGetInsurancesQuery,
  useLazyGetInsurancesQuery,
  useDeleteInsuranceMutation,
  useUpdateInsuranceMutation
} = insuranceApi;
