import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppointmentItem, AppointmentsState } from './appointments.types';

const initialState: AppointmentsState = {
  appointmentsList: [],
  currentAppointment: undefined
};

const appointmentsSlice = createSlice({
  initialState,
  name: 'appointments',
  reducers: {
    setAppointments: (state, action: PayloadAction<AppointmentItem[]>) => {
      state.appointmentsList = action.payload;
    },
    setCurrentAppointment: (state, action: PayloadAction<AppointmentItem>) => {
      state.currentAppointment = action.payload;
    }
  }
});

export const { setAppointments, setCurrentAppointment } = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
