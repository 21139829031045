import { ResponseProps } from 'services/services.types';

import { Gender, ReviewProblemType } from 'utils/enums';

import { AppointmentTime } from 'models/appointment.types';

export type GetProviderReqProps = {
  providerId: string;
  shouldUpdateState?: boolean;
};

export type GetProviderResProps = ResponseProps<GetProviderDataProps>;

export interface GetProviderDataProps {
  aboutDoctor: string;
  availableReview: boolean;
  displayName: string;
  dob: string;
  email: string;
  experience: string[];
  firstName: string;
  gender: Gender;
  lastName: string;
  npi: string;
  profileImage?: string;
  rating: number;
  reviews: ProviderReviewItem[];
  shortBio: string;
  specialtyAreas: GetProviderSpecialtyAreasItemProps[];
  userId: string;
}

export interface ProviderReviewItem {
  _id: string;
  createdAt: string;
  doctorId: string;
  patientId: string;
  patientName: string;
  profileImage?: string;
  rating: number;
  reviewComment: string;
  updatedAt: string;
}

export interface GetProviderSpecialtyAreasItemProps {
  icon: string;
  name: string;
}

export type GetReviewReqProps = {
  limit?: number;
  pageNo?: number;
  providerId: string;
};

export type GetReviewResProps = ResponseProps<GetReviewDataItemProps[], { totalCount: number }>;

export interface GetReviewDataItemProps {
  _id: string;
  createdAt: string;
  doctorId: string;
  patientId: string;
  patientName: string;
  profileImage?: string;
  rating: number;
  reviewComment: string;
  updatedAt: string;
}

export enum ReviewPlatform {
  Desktop = 'desktop',
  Mobile = 'mobile'
}

export interface AddReviewReqProps {
  doctorId: string;
  platform?: ReviewPlatform;
  problemType?: ReviewProblemType;
  rating: number;
  reviewComment?: string;
}

export type AddReviewResProps = ResponseProps<AddReviewDataProps>;

export interface AddReviewDataProps {
  doctorId: string;
  rating: number;
  reviewComment: string;
}

export interface GetAvailableTimesReqProps {
  accessToken?: string;
  appointmentTypeId?: string;
  date: string | Date;
  doctorId: string;
  isSignUp?: boolean;
  isWeightManagementAppointment?: boolean;
  timezone?: string;
}

export type GetAvailableTimesResProps = ResponseProps<GetAvailableTimesDataProps>;

export interface GetAvailableTimesDataProps {
  slots: GetAvailableTimesSlotsItemProps[];
}

export type GetAvailableTimesSlotsItemProps = AppointmentTime;

export interface GetAvailableDatesReqProps {
  accessToken?: string;
  appointmentTypeId?: string;
  doctorId: string;
  endDate: string;
  isWeightManagementAppointment?: boolean;
  planPricePointId?: string;
  startDate: string;
  timezone?: string;
}

export type GetAvailableDatesResProps = ResponseProps<GetAvailableDatesDataProps[]>;

export interface GetAvailableDatesDataProps {
  count: number;
  date: string;
}

export interface GetCombinedAvailabilityReqProps {
  appointmentTypeId: string;
  date: string;
  excludeMyProvider?: boolean;
  isReschedule?: boolean;
  timezone?: string;
}

export interface CombinedTimeSlots extends GetAvailableTimesSlotsItemProps {
  displayName: string;
  doctorId: string;
}
export interface ExtendedSlots {
  slots: CombinedTimeSlots[];
}
export type GetCombinedAvailabilityResProps = ResponseProps<ExtendedSlots>;
