import { createApi } from '@reduxjs/toolkit/query/react';

import { setUser } from 'store/user/userSlice';

import { baseQueryWithReauth } from 'utils/services';

import {
  AddProfileImageReqProps,
  AddProfileImageResProps,
  GetBillingHistoryReqProps,
  GetBillingHistoryResProps,
  GetCreditBalanceReqProps,
  GetCreditBalanceResProps,
  GetCreditCardInformationReqProps,
  GetCreditCardInformationResProps,
  GetMyAccountReqProps,
  GetMyAccountResProps,
  GetPlanRecommendationResProps,
  MarkInitialLoginReqProps,
  MarkInitialLoginResProps,
  PreviewChangeSubscriptionReqProps,
  PreviewChangeSubscriptionResProps,
  SendRecommendationDecisionReqProps,
  UpdateMyAccountReqProps,
  UpdateMyAccountResProps,
  UpdatePaymentProfileReqProps,
  UpdatePaymentProfileResProps
} from './myAccount.types';

export const myAccountApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    addProfileImage: build.mutation<AddProfileImageResProps, AddProfileImageReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: 'my-account/profile-image'
      })
    }),
    deletePaymentMethod: build.mutation<
      UpdateMyAccountResProps,
      { id: string; isInsuranceSubscription?: boolean }
    >({
      query: ({ id, isInsuranceSubscription }) => ({
        method: 'DELETE',
        url: `my-account/payment-profile/${id}${isInsuranceSubscription ? '?isInsuranceSubscription=true' : ''}`
      })
    }),
    getBillingHistory: build.query<GetBillingHistoryResProps, GetBillingHistoryReqProps>({
      keepUnusedDataFor: 0,
      query: (params) => ({
        params,
        url: 'my-account/billing-history'
      })
    }),
    getCreditBalance: build.query<GetCreditBalanceResProps, GetCreditBalanceReqProps>({
      keepUnusedDataFor: 0,
      query: () => 'my-account/account-balances'
    }),
    getCreditCardInformation: build.query<
      GetCreditCardInformationResProps,
      GetCreditCardInformationReqProps
    >({
      query: (params) => ({
        ...(!!params && { params }),
        url: 'my-account/credit-card-information'
      })
    }),
    getMyAccount: build.query<GetMyAccountResProps, GetMyAccountReqProps>({
      keepUnusedDataFor: 0,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setUser(data.data));
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(error);
        }
      },
      query: () => 'my-account'
    }),
    getPlanRecommendation: build.query<GetPlanRecommendationResProps, void>({
      query: () => '/my-account/plan-recommendation'
    }),
    markInitialLogin: build.mutation<MarkInitialLoginResProps, MarkInitialLoginReqProps>({
      query: () => ({
        method: 'PATCH',
        url: 'my-account/mark-initial-login'
      })
    }),
    previewChangeSubscription: build.query<
      PreviewChangeSubscriptionResProps,
      PreviewChangeSubscriptionReqProps
    >({
      query: (body) => ({
        body,
        method: 'PATCH',
        url: 'my-account/preview-change-subscription'
      })
    }),
    sendRecommendationDecision: build.mutation<unknown, SendRecommendationDecisionReqProps>({
      query: (body) => ({
        body,
        method: 'PATCH',
        url: '/my-account/plan-recommendation'
      })
    }),
    updateDefaultPaymentMethod: build.mutation<
      UpdateMyAccountResProps,
      { id: string; isInsuranceSubscription?: boolean }
    >({
      query: ({ id, isInsuranceSubscription }) => ({
        method: 'PUT',
        url: `my-account/payment-profile/${id}/set-default${isInsuranceSubscription ? '?isInsuranceSubscription=true' : ''}`
      })
    }),
    updateMyAccount: build.mutation<UpdateMyAccountResProps, UpdateMyAccountReqProps>({
      query: (body) => ({
        body,
        method: 'PATCH',
        url: 'my-account'
      })
    }),
    updatePaymentProfile: build.mutation<
      UpdatePaymentProfileResProps,
      UpdatePaymentProfileReqProps
    >({
      query: ({ method, isInsuranceSubscription, ...body }) => ({
        body,
        method,
        url: `my-account/payment-profile${isInsuranceSubscription ? '?isInsuranceSubscription=true' : ''}`
      })
    })
  }),
  reducerPath: 'myAccountApi',
  tagTypes: ['MyAccount']
});

export const {
  useGetMyAccountQuery,
  useLazyGetMyAccountQuery,
  useGetBillingHistoryQuery,
  useUpdateMyAccountMutation,
  useLazyPreviewChangeSubscriptionQuery,
  useAddProfileImageMutation,
  useGetCreditCardInformationQuery,
  useLazyGetCreditCardInformationQuery,
  useUpdatePaymentProfileMutation,
  useMarkInitialLoginMutation,
  useGetCreditBalanceQuery,
  useDeletePaymentMethodMutation,
  useUpdateDefaultPaymentMethodMutation,
  useGetPlanRecommendationQuery,
  useSendRecommendationDecisionMutation
} = myAccountApi;
