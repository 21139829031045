import { useState } from 'react';

import RadioGroup from 'shared/RadioGroup';

import Heading from '../../parts/Heading';

const ITEMS = [
  { label: 'Lose 1-20 lbs for good', value: 'lose_1_20_lbs' },
  { label: 'Lose 21-50 lbs for good', value: 'lose_21_50_lbs' },
  { label: 'Lose over 50 lbs for good', value: 'lose_over_50_lbs' },
  { label: 'Maintain weight and get fit', value: 'maintain_weight_and_get_fit' },
  { label: "I'm not sure yet", value: 'im_not_sure_yet' }
];

const WeightLossGoalPicker: React.FC<{
  loading: boolean;
  onSelect: (v: string, cb?: () => void) => void;
}> = ({ onSelect, loading }) => {
  const [activeItem, setActiveItem] = useState('');

  const handleSelect = (v: string) => {
    if (v) {
      setActiveItem(v);
      onSelect(v, () => {
        setActiveItem('');
      });
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <Heading category="Eligibility" title="What is your weight loss goal?" />
      <RadioGroup
        isLoading={loading}
        items={ITEMS}
        selectedItem={activeItem}
        onSelect={handleSelect}
      />
    </div>
  );
};

export default WeightLossGoalPicker;
